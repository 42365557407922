import React from "react";
import { callApi } from "../../utils/api";
import { toast } from "react-toastify";

interface User {
  id: number;
  name: string;
}

interface ChangePasswordState {
  newPassword: string;
  confirmPassword: string;
  passwordMatch: boolean;
  allUser: User[];
  emp_id: number;
}

class Changepassword extends React.Component<{}, ChangePasswordState> {
  constructor(props: {}) {
    super(props);
    this.state = {
      newPassword: "",
      confirmPassword: "",
      passwordMatch: true,
      allUser: [],
      emp_id: 0,
    };
  }

  componentDidMount() {
    this.getUser();
    const userDetails = sessionStorage.getItem("userdetails");
    let userId = userDetails ? JSON.parse(userDetails).id : null;
    if (userDetails) {
      const parsedUserDetails = JSON.parse(userDetails);
      userId = parseInt(parsedUserDetails.id);
      this.setState({ emp_id: userId });
    }
  }

  getUser() {
    callApi("GET", "user_get.php")
      .then((res) => res.data)
      .then((response) => {
        this.setState({ allUser: response.data });
      })
      .catch((err) => {});
  }

  handleNewPasswordChange = (event) => {
    this.setState(
      { newPassword: event.target.value },
      this.checkPasswordsMatch
    );
  };

  handleConfirmPasswordChange = (event) => {
    this.setState(
      { confirmPassword: event.target.value },
      this.checkPasswordsMatch
    );
  };

  checkPasswordsMatch = () => {
    const { newPassword, confirmPassword } = this.state;
    const passwordMatch =
      newPassword === confirmPassword &&
      newPassword !== "" &&
      confirmPassword !== "";
    this.setState({ passwordMatch });
    const userDetails = sessionStorage.getItem("userdetails");
    const existingPassword = userDetails
      ? JSON.parse(userDetails).password
      : null;
    if (newPassword === existingPassword) {
      toast.error("New password must be different from the existing password", {
        autoClose: 3000,
      });
      return;
    }
  };

  handleSubmit = (event) => {
    event.preventDefault();

    let req = {
      emp_id: this.state.emp_id,
      password: this.state.newPassword,
    };
    callApi("POST", "password_update.php", req)
      .then((res) => res.data)
      .then((response) => {
        if (response.status == true) {
          toast.success("Password Changed successfully!", { autoClose: 3000 });
          this.setState({ newPassword: "", confirmPassword: "" });
        } else {
          toast.error("Not Added", { autoClose: 3000 });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  render() {
    const { passwordMatch } = this.state;
    return (
      <div className="pcoded-main-container">
        <div className="pcoded-wrapper">
          <div className="pcoded-content">
            <div className="pcoded-inner-content">
              <div className="main-body">
                <div className="page-wrapper">
                  <div className="row pr-3 pl-3">
                    <div className="col-sm-12">
                      <div className="card card-wrapper">
                        <div
                          className="card-header"
                          style={{ padding: "45px 40px 30px 40px" }}
                        >
                          <h4>Change Password</h4>
                          <form onSubmit={this.handleSubmit}>
                            <div
                              style={{
                                fontSize: "12px",
                                color: "gray",
                                marginTop: "5px",
                              }}
                            >
                              <p>Password must contain:</p>
                              <p>- At least one uppercase letter</p>
                              <p>- At least one lowercase letter</p>
                              <p>- At least one special character</p>
                              <p>- At least one number</p>
                              <p>- Be at least 8 characters long</p>
                            </div>
                            <div className="col-md-6">
                              <div
                                className="form-group"
                                style={{ display: "grid" }}
                              >
                                <label htmlFor="password">
                                  New Password{" "}
                                  <span style={{ color: "red" }}>*</span>
                                </label>

                                <input
                                  type="text"
                                  name="password"
                                  id="password"
                                  value={this.state.newPassword}
                                  onChange={this.handleNewPasswordChange}
                                  maxLength={15}
                                  required
                                  aria-required="true"
                                  pattern="^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*]).{8,}$"
                                  title="Password must contain at least one uppercase letter, one lowercase letter,one special character, one number, and be at least 8 characters long"
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div
                                className="form-group"
                                style={{ display: "grid" }}
                              >
                                <label htmlFor="confirmpassword">
                                  Confirm Password
                                </label>

                                <input
                                  type="text"
                                  name="confirmpassword"
                                  id="confirmpassword"
                                  value={this.state.confirmPassword}
                                  onChange={this.handleConfirmPasswordChange}
                                  maxLength={15}
                                  required
                                />
                                {!passwordMatch && (
                                  <p style={{ color: "red", marginTop: "5px" }}>
                                    Passwords do not match!
                                  </p>
                                )}
                              </div>
                            </div>
                            <div className="col-md-12 text-center mt-4">
                              <div className="row">
                                <div className="col-md-6 text-right">
                                  <button
                                    type="submit"
                                    className="btn btn-primary"
                                    style={{
                                      borderRadius: "10px",
                                      backgroundColor: "green",
                                      color: "#fff",
                                      borderColor: "green",
                                      width: "94px",
                                      height: "47px",
                                    }}
                                  >
                                    Submit
                                  </button>
                                </div>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Changepassword;
