import React from "react";
import { callApi } from "../../utils/api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendar } from "@fortawesome/free-solid-svg-icons";
import DatePicker from "react-datepicker";
import moment from "moment";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

interface User {
  id: string;
  name: string;
}
interface Leave {
  id: number;
  leave_type: string;
}

interface Applyleavestate {
  toDateInputValue: string;
  fromDateInputValue: string;
  allLeave: Leave[];
  reason: string;
  selectedLeaveType: number;
  allUser: User[];
  user_id: number;
  name: string;
}

class Applyleave extends React.Component {
  state: Applyleavestate = {
    allLeave: [],
    fromDateInputValue: "",
    toDateInputValue: "",
    reason: "",
    selectedLeaveType: 0,
    allUser: [],
    user_id: 0,
    name: "",
  };
  componentDidMount() {
    this.getLeavetype();
    this.getUser();
    const userDetails = sessionStorage.getItem("userdetails");
    console.log(userDetails);
    const userName = userDetails ? JSON.parse(userDetails)["name"] : "";
    let userId = userDetails ? JSON.parse(userDetails).id : null;
    this.setState({ user_id: userId, name: userName });
  }
  getLeavetype() {
    callApi("GET", "leavetypes_get.php")
      .then((res) => res.data)
      .then((response) => {
        this.setState({ allLeave: response.data });
        console.log("leave types", this.state.allLeave);
      })
      .catch((err) => {});
  }
  getUser() {
    callApi("GET", "user_get.php")
      .then((res) => res.data)
      .then((response) => {
        this.setState({ allUser: response.data });
      })
      .catch((err) => {});
  }

  handleFromDateChange = (date: Date) => {
    this.setState({ startDate: date }, () => {
      this.updateFromDateInputValue(date);
    });
  };

  updateFromDateInputValue = (date: Date) => {
    const formattedDate = moment(date).format("DD/MM/yyyy");
    this.setState({ fromDateInputValue: formattedDate });
  };

  handleToDateChange = (date: Date) => {
    this.setState({ endDate: date }, () => {
      this.updateToDateInputValue(date);
    });
  };
  updateToDateInputValue = (date: Date) => {
    const formattedDate = moment(date).format("DD/MM/yyyy");
    this.setState({ toDateInputValue: formattedDate });
  };

  handleLeaveTypeChange = (event) => {
    const selectedLeaveType = event.target.value;
    this.setState({ selectedLeaveType });
    console.log("Selected Leave Type", selectedLeaveType);
  };

  handleReasonChange = (event) => {
    const reason = event.target.value;
    this.setState({ reason });
  };

  handlesubmit = (event) => {
    event.preventDefault();
    const currentDate = moment().format("DD/MM/yyyy");
    const {
      fromDateInputValue,
      toDateInputValue,
      reason,
      selectedLeaveType,
      user_id,
      name,
    } = this.state;
    const req = {
      user_id: user_id,
      name: name,
      fromDate: fromDateInputValue,
      toDate: toDateInputValue,
      reason: reason,
      leaveType: selectedLeaveType,
      date: currentDate,
    };
    callApi("POST", "leave_insert.php", req)
      .then((res) => res.data)
      .then((response) => {
        console.log(response);
        if (response.success === true) {
          toast.success("Leave Request Submitted successfully!", {
            autoClose: 2000,
          });
          this.setState({
            fromDateInputValue: "",
            toDateInputValue: "",
            reason: "",
            selectedLeaveType: "",
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  render() {
    const { allLeave } = this.state;
    return (
      <div className="pcoded-main-container">
        <div className="pcoded-wrapper">
          <div className="pcoded-content">
            <div className="pcoded-inner-content">
              <div className="main-body">
                <div className="page-wrapper">
                  <div className="row pr-3 pl-3">
                    <div className="col-sm-12">
                      <div className="card card-wrapper">
                        <div
                          className="card-header"
                          style={{ padding: "45px 40px 30px 40px" }}
                        >
                          <h4>Apply Leave</h4>
                        </div>
                        <div
                          className="form-group d-flex align-items-center mt-6"
                          style={{ marginTop: "20px" }}
                        >
                          <label htmlFor="selectleave" className="mr-2">
                            Select leave :
                          </label>
                          <select
                            style={{
                              height: "40px",
                              width: "200px",
                              marginLeft: "5px",
                            }}
                            onChange={this.handleLeaveTypeChange}
                            value={this.state.selectedLeaveType}
                          >
                            <option value="">Select Leave</option>
                            {allLeave.map((leave) => (
                              <option key={leave.id} value={leave.id}>
                                {leave.leave_type}
                              </option>
                            ))}
                          </select>
                          <label htmlFor="fromDate" className="mr-9 ml-2">
                            From Date:
                          </label>
                          <FontAwesomeIcon
                            icon={faCalendar}
                            style={{
                              height: "20px",
                              width: "30px",
                            }}
                          />
                          <DatePicker
                            className="date-wrapper"
                            onChange={this.handleFromDateChange}
                            placeholderText={
                              this.state.fromDateInputValue || "DD/MM/YYYY"
                            }
                            dateFormat="dd/MM/yyyy"
                          />
                          <label htmlFor="toDate" className="mr-9 ml-2">
                            To Date:
                          </label>
                          <FontAwesomeIcon
                            icon={faCalendar}
                            style={{
                              height: "20px",
                              width: "30px",
                            }}
                          />
                          <DatePicker
                            className="date-wrapper"
                            onChange={this.handleToDateChange}
                            placeholderText={
                              this.state.toDateInputValue || "DD/MM/YYYY"
                            }
                            dateFormat="dd/MM/yyyy"
                          />
                          <label htmlFor="reason" className="mr-9 ml-2">
                            Reason :
                          </label>
                          <textarea
                            id="reason"
                            name="reason"
                            maxLength={150}
                            style={{
                              width: "20%",
                              height: "50px",
                              padding: "5px",
                            }}
                            onChange={this.handleReasonChange}
                            value={this.state.reason}
                          />
                        </div>
                        <div className="d-flex justify-content-end">
                          <button
                            type="submit"
                            className="btn btn-primary"
                            style={{
                              marginTop: "5%",
                              marginLeft: "500px",
                              height: "45px",
                              width: "95px",
                              fontSize: "14px",
                            }}
                            onClick={this.handlesubmit}
                          >
                            Submit
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Applyleave;
