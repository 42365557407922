import * as React from "react";
import { Link } from "react-router-dom";
import Table from "../user/Table";
import { callApi } from "../../utils/api";
import { Dropdown } from "react-bootstrap";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

interface AllProps {
  history: any;
}
class ManageDropdown extends React.Component<AllProps> {
  state = {
    modalInputValue: "",
    allStatus: [],
    task_status: "",
    id: 0,
    status: 0,
  };
  componentDidMount() {
    this.getStatus();
  }

  getStatus() {
    callApi("GET", "dropdown_get.php",)
      .then(res => res.data)
      .then(response => {
        this.setState({ allStatus: response.data })
        console.log("status", this.state.allStatus)
      })
      .catch(err => {
        console.log(err)
      });
  }

  updateStatus = () => {
    let req = {
      id: this.state.id,
      task_status: this.state.task_status
    }
    callApi("POST", "dropdown_update.php", req)
    .then(res => res.data)
    .then(response => {
      this.getStatus();
      if (response.success == true) {
        toast.success(response.message,{ autoClose: 3000 });
      } else {
        toast.error(response.message, { autoClose: 3000 });
      }
    })
    .catch(err => {
      console.log(err)
    });
  }

  deleteStatus = () => {
    let req = {
      id: this.state.id,
      status: 0
    }
    callApi("POST", "dropdown_delete.php", req)
    .then(res => res.data)
    .then(response => {
      this.getStatus();
      if (response.success == true) {
        toast.success(response.message,{ autoClose: 3000 });
      } else {
        toast.error(response.message, { autoClose: 3000 });
      }
    })
    .catch(err => {
      console.log(err)
    });
  }

  handleModalInputChange = (event) => {
    this.setState({
      task_status: event.target.value,
    });
  };

  handleEditClick = (statusId, userStatus) => {
    this.setState({
      id: statusId,
      task_status: userStatus,
    });
    console.log("Edit clicked for role with ID:", statusId);
  };

  handleDeleteClick = (statusId, userStatus) => {
    this.state.id = statusId;
    this.state.status = userStatus;
    console.log("Delete clicked for role with ID:", statusId);
    console.log("Role status:", userStatus);
  };

  handleSubmit = () => {
    let req = {
      task_status: this.state.task_status
    }
    callApi("POST", "dropdown_insert.php", req)
    .then(res => res.data)
    .then(response => {
      this.getStatus();
      if (response.success == true) {
        toast.success(response.message,{ autoClose: 3000 });
      } else {
        toast.error(response.message, { autoClose: 3000 });
      }
    })
    .catch(err => {
      console.log(err)
    });
  };
  
  render() {
    const data = this.state.allStatus;
    const columns = [
      {
        Header: "S.No",
        accessor: "id",
        sort: true,
        Cell: ({ row }) => {
          return <span>{row.index + 1}</span>;
        },
      },
      {
        Header: "Top Status",
        accessor: "task_status",
        sort: true,
      },
      
      {
        Header: "Action",
        accessor: "action",
        sort: false,
        Cell: ({ row }) => (
          <Dropdown>
            <Dropdown.Toggle variant="primary" id="action-dropdown" style={{backgroundColor: "green",borderColor: "green"}}>
              Actions
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item
                as={Link}
                onClick={() => this.handleEditClick(row.original.id, row.original.task_status)}
                to={`/user/edit/${row.original.id}`}
                className="dropdown-item"
                data-toggle="modal"
                data-target="#edit-user"
              >
                Edit
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => this.handleDeleteClick(row.original.id, row.original.status)}
                className="dropdown-item text-danger"
                data-toggle="modal"
                data-target="#delete-user"
              >
                Delete
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        ),
      },
    ];
    
    const sizePerPageList = [
      {
        text: "5",
        value: 5,
      },
      {
        text: "10",
        value: 10,
      },
      {
        text: "25",
        value: 25,
      },
      {
        text: "All",
        value: data.length,
      },
    ];
    
    return (
      <><section className="pcoded-main-container">
        <div className="pcoded-wrapper">
          <div className="pcoded-content">
            <div className="pcoded-inner-content">
              <div className="main-body">
                <div className="page-wrapper">
                  <div className="row">
                    <div className="col-sm-12">
                      <div className="card">
                      <div className="card-header" style={{ display: "flex", justifyContent: "space-between", alignItems: "center", padding: "17px" }}>
                          <h5>Manage Dropdown</h5>
                          <div>
                            <Link to="/dashboard" className="btn btn-secondary">
                              Go Back
                            </Link>
                          </div>
                        </div>
                        <div className="card-body">
                          <div className="dt-responsive table-responsive">
                            <Link to={"/Dropdown/add/0"} className="btn btn-primary m-b-20" data-toggle="modal"
                              data-target="#Role-modal" style={{ borderRadius: "10px", backgroundColor: "green", color: "#fff",borderColor: "green",width: "143px" }}>
                              Add Dropdown
                            </Link>
                            <Table
                              columns={columns}
                              data={data}
                              pageSize={25}
                              sizePerPageList={sizePerPageList}
                              isSortable={true}
                              pagination={true}
                              isSearchable={true} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="modal" id="Role-modal">
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-body">
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
                <h4>Manage Dropdown</h4>
                <div className="model-xl" style={{ height: '200px', position: 'relative', overflow: 'auto' }}>
                  <div className='card-body'>
                  <div className="form-group">
                    <label htmlFor="task_status"></label>
                    <input type="text" id="task_status" name="task_status"
                     className="form-control" 
                     style={{marginTop: "47px"}} 
                     onChange={this.handleModalInputChange}/>
                    </div>
                  </div>
                </div>
                <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-primary"
                  data-dismiss="modal"
                  style={{ float: "right", marginBottom: "20px",backgroundColor: "green",borderColor: "green" }}
                > close</button>
                <button
                  type="button"
                  className="btn btn-primary"
                  data-dismiss="modal"
                  onClick={this.handleSubmit}
                  style={{ float: "right", marginBottom: "20px",borderColor: "cadetblue" }}
                > Submit</button>
              </div>
            </div>

          </div>

        </div>
        </div>
        <div className="modal fade"
          id="edit-user"
          role="dialog"
          aria-hidden="true">
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-body">
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
                <h4>Manage Dropdown</h4>
                <div className="model-xl" style={{ height: '200px', position: 'relative', overflow: 'auto' }}>
                  <div className='card-body'>
                  <div className="form-group">
                    <label htmlFor="task_status"></label>
                    <input type="text" id="task_status" name="task_status"
                     className="form-control" 
                     style={{marginTop: "47px"}} 
                     onChange={this.handleModalInputChange}
                     value={this.state.task_status}/>
                    </div>
                  </div>
                </div>
                <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-primary"
                  data-dismiss="modal"
                  style={{ float: "right", marginBottom: "20px",backgroundColor: "green",borderColor: "green" }}
                > close</button>
                <button
                  type="button"
                  className="btn btn-primary"
                  data-dismiss="modal"
                  onClick={this.updateStatus}
                  style={{ float: "right", marginBottom: "20px",borderColor: "cadetblue" }}
                > Submit</button>
              </div>
            </div>

          </div>

        </div>
        </div>
        <div
          className="modal fade"
          id="delete-user"
          role="dialog"
          aria-hidden="true"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Delete User</h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <h6>Are you sure you want to Delete this user?</h6>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                  style={{backgroundColor: "green", borderColor:"green"}}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="btn btn-primary"
                  data-dismiss="modal"
                  onClick={this.deleteStatus}
                  style={{backgroundColor: "cadetblue",borderColor:"cadetblue"}}
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
        </div>
        </>
             );
  }
}

export default ManageDropdown;
