import * as React from "react";
import { Link } from "react-router-dom";
import Table from "../user/Table";
import { Dropdown } from "react-bootstrap";
import { callApi } from "../../utils/api";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Select from "react-select";
import moment from "moment";

interface AllProps {
  history: any;
}

interface Member {
  id: number;
  name: string;
}

class ManageProject extends React.Component<AllProps> {
  state = {
    allProjects: [] as any[],
    allroles: [],
    allUser: [] as { id: number; name: string }[],
    projectName: "",
    clientName: "",
    Role: "",
    id: 0,
    status: 0,
    nextReviewDate: null,
    ReleaseDate: null,
    owner: "",
    members: "",
    user_id: null,
    selecteduser: "",
    username: "",
    selectedMembers: [],
    onholdcounts: 0,
    closedcount: 0,
  };
  componentDidMount() {
    this.getProjects();
    this.getUser();
    const userDetails = sessionStorage.getItem("userdetails");
    let user_id = userDetails ? JSON.parse(userDetails).id : null;
    this.setState({ user_id: user_id });
  }

  getUser() {
    // Make an API request to fetch all user names
    callApi("GET", "user_get.php")
      .then((res) => res.data)
      .then((response) => {
        const userArray = response.data.map((user) => ({
          id: user.id,
          name: user.name,
        }));

        this.setState({ allUser: userArray });
      })
      .catch((err) => {
        console.log(err);
      });
  }
  handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      Role: event.target.value,
    });
  };

  handleSubmit = () => {
    if (!this.validateForm()) {
      return;
    }

    let req = {
      projectName: this.state.projectName,
      clientName: this.state.clientName,
      owner: this.state.selecteduser,
      members: this.state.selectedMembers,
    };

    callApi("POST", "project_insert.php", req)
      .then((res) => res.data)
      .then((response) => {
        if (response.success === true) {
          this.getProjects();
          toast.success(response.message, { autoClose: 3000 });
          this.setState({
            projectName: "",
            clientName: "",
            owner: 0,
            members: 0,
          });
        } else {
          toast.error(response.message, { autoClose: 3000 });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  updateProject = () => {
    let req = {
      id: this.state.id,
      Role: this.state.Role,
    };
    callApi("POST", "", req)
      .then((res) => res.data)
      .then((response) => {
        if (response.success == true) {
          this.getProjects();
          toast.success(response.message, { autoClose: 3000 });
        } else {
          toast.error(response.message, { autoClose: 3000 });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  deleteRole = () => {
    let req = {
      id: this.state.id,
      status: 0,
    };
    callApi("POST", "", req)
      .then((res) => res.data)
      .then((response) => {
        if (response.success == true) {
          toast.success(response.message, { autoClose: 3000 });
        } else {
          toast.error(response.message, { autoClose: 3000 });
        }

        this.getProjects();
      })
      .catch((err) => {
        console.log(err);
      });
  };
  getProjects() {
    callApi("GET", "project_get.php")
      .then((res) => res.data)
      .then((response) => {
        const filteredProjects = response.data.filter(
          (project) =>
            project.status !== "On Hold-Clarification" &&
            project.status !== "On Hold-Client Review" &&
            project.status !== "On Hold-Payment" &&
            project.status !== "Closed"
        );
        this.setState({ allProjects: filteredProjects });
        const onHoldcount = response.data.filter(
          (project) =>
            project.status === "On Hold-Clarification" ||
            project.status === "On Hold-Client Review" ||
            project.status === "On Hold-Payment"
        );
        this.setState({ onholdcounts: onHoldcount.length });
        const closed = response.data.filter(
          (project) => project.status === "Closed"
        );
        this.setState({ closedcount: closed.length });
      })
      .catch((err) => {});
  }
  handleUserChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    this.setState({
      selecteduser: event.target.value,
    });
  };
  handleMembersChange = (selectedOptions: any) => {
    if (selectedOptions) {
      const selectedValues = selectedOptions.map((option: any) => option.value);

      this.setState({ selectedMembers: selectedValues });
    }
  };

  validateForm = () => {
    const { projectName, clientName, selectedMembers } = this.state;

    if (!projectName || !clientName) {
      return false;
    }

    return true;
  };

  render() {
    const data = this.state.allProjects;
    const userDetails = sessionStorage.getItem("userdetails");
    const userDesignation = userDetails
      ? JSON.parse(userDetails).designation
      : null;
    const columns = [
      {
        Header: "S No",
        accessor: "serialNumber",
        Cell: ({ row }) => row.index + 1,
        sort: true,
      },
      {
        Header: "Project ID",
        accessor: "id",
        Cell: ({ value }) => (value ? value : "-"),
        sort: true,
      },

      {
        Header: "Project Name",
        accessor: "projectname",
        Cell: ({ value }) => (value ? value : "-"),
        sort: true,
      },
      {
        Header: "Project Owner",
        accessor: "productowner",
        Cell: ({ value }) => (value ? value : "-"),
        sort: true,
      },
      {
        Header: "Project Members",
        accessor: "members",
        Cell: ({ value }) => (value ? value : "-"),
        sort: true,
      },
      {
        Header: "Next Review Date",
        accessor: "next_review_date",
        Cell: ({ value }) =>
          moment(value).isValid() ? moment(value).format("DD/MM/YYYY") : "-",
        sort: true,
      },
      {
        Header: "Release Date",
        accessor: "release_date",
        Cell: ({ value }) =>
          moment(value).isValid() ? moment(value).format("DD/MM/YYYY") : "-",
        sort: true,
      },
      {
        Header: "Status",
        accessor: "status",
        Cell: ({ value }) => (value ? value : "-"),
        sort: true,
      },

      {
        Header: "Action",
        accessor: "action",
        sort: false,
        Cell: ({ row }) =>
          userDesignation === "1" || userDesignation === "2" ? (
            <Link
              to={`/projects/project-page/${row.original.id}`}
              className="btn btn-primary"
              style={{
                backgroundColor: "green",
                borderColor: "green",
                textDecoration: "none",
              }}
            >
              View Project
            </Link>
          ) : (
            <Link
              to={`/projects/project-page/${row.original.id}`}
              className="btn btn-primary"
              style={{
                backgroundColor: "green",
                borderColor: "green",
                textDecoration: "none",
              }}
            >
              View Task
            </Link>
          ),
      },
    ];

    const sizePerPageList = [
      {
        text: "5",
        value: 5,
      },
      {
        text: "10",
        value: 10,
      },
      {
        text: "25",
        value: 25,
      },
      {
        text: "All",
        value: data.length,
      },
    ];

    const membersWithoutOwner = this.state.allUser.filter(
      (member) => member?.name !== this.state.selecteduser
    );

    return (
      <>
        <section className="pcoded-main-container">
          <div className="pcoded-wrapper">
            <div className="pcoded-content">
              <div className="pcoded-inner-content">
                <div className="main-body">
                  <div className="page-wrapper">
                    <div className="row">
                      <div className="col-sm-12">
                        <div className="card">
                          <div
                            className="card-header"
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                              padding: "17px",
                            }}
                          >
                            <h5>Manage Projects</h5>
                            <div>
                              <Link
                                to="/dashboard"
                                className="btn btn-secondary"
                              >
                                Go Back
                              </Link>
                            </div>
                          </div>
                          <div className="card-body">
                            <div className="dt-responsive table-responsive">
                              {(userDesignation === "1" ||
                                userDesignation === "2") && (
                                <>
                                  <Link
                                    to={"/Role/add/0"}
                                    className="btn btn-primary m-b-20"
                                    data-toggle="modal"
                                    data-target="#Role-modal"
                                    style={{
                                      borderRadius: "10px",
                                      backgroundColor: "green",
                                      color: "#fff",
                                      borderColor: "green",
                                    }}
                                  >
                                    Add Project
                                  </Link>
                                  <Link
                                    to={"/onhold"}
                                    className="btn btn-primary m-b-20"
                                    style={{
                                      float: "inline-end",
                                      borderRadius: "10px",
                                      backgroundColor: "green",
                                      color: "#fff",
                                      borderColor: "green",
                                    }}
                                  >
                                    On Hold: {this.state.onholdcounts}
                                  </Link>
                                  <Link
                                    to={"/closed-project"}
                                    className="btn btn-primary m-b-20"
                                    style={{
                                      float: "inline-end",
                                      borderRadius: "10px",
                                      backgroundColor: "green",
                                      color: "#fff",
                                      borderColor: "green",
                                    }}
                                  >
                                    Closed: {this.state.closedcount}
                                  </Link>
                                </>
                              )}
                              <Table
                                columns={columns}
                                data={data}
                                pageSize={50}
                                sizePerPageList={sizePerPageList}
                                isSortable={true}
                                pagination={true}
                                isSearchable={true}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        //Add Project
        <div className="modal" id="Role-modal">
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-body" style={{ height: "600px" }}>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
                <h4>Add Project</h4>
                <div
                  className="model-xl"
                  style={{
                    height: "460px",
                    position: "relative",
                    overflow: "auto",
                  }}
                >
                  <div className="card-body">
                    <div className="form-group">
                      <label htmlFor="projectName">
                        Project Name<span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        type="text"
                        id="projectName"
                        name="projectName"
                        className="form-control"
                        value={this.state.projectName}
                        onChange={(e) =>
                          this.setState({ projectName: e.target.value })
                        }
                        maxLength={50}
                        autoComplete="off"
                        required
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="clientName">
                        Client Name<span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        type="text"
                        id="clientName"
                        name="clientName"
                        className="form-control"
                        value={this.state.clientName}
                        onChange={(e) =>
                          this.setState({ clientName: e.target.value })
                        }
                        maxLength={50}
                        autoComplete="off"
                        required
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="selectuser">
                        Select Product Owner:
                        <span style={{ color: "red" }}>*</span>
                      </label>
                      <select
                        id="selectuser"
                        name="selectuser"
                        style={{
                          height: "40px",
                          border: "1px solid #ccc",
                          width: "100%",
                          borderRadius: "5px",
                        }}
                        onChange={(e) => this.handleUserChange(e)}
                        value={this.state.selecteduser}
                      >
                        <option value="">Select Owner</option>
                        {this.state.allUser.map((user) => (
                          <option key={user.id} value={user.name}>
                            {user.name}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="form-group">
                      <label htmlFor="selectedMembers">Select Members:</label>
                      <Select
                        isMulti
                        id="selectedMembers"
                        name="selectedMembers"
                        options={membersWithoutOwner.map((member) => ({
                          value: member.name,
                          label: member.name,
                        }))}
                        onChange={this.handleMembersChange}
                      />
                    </div>
                  </div>
                </div>
                <div className="modal-footer">
                  {" "}
                  {/* Add a modal footer */}
                  <button
                    type="button"
                    className="btn btn-primary"
                    data-dismiss="modal"
                    // onClick={this.disableModalHandler}
                    style={{
                      float: "right",
                      marginBottom: "20px",
                      backgroundColor: "green",
                      borderColor: "green",
                    }}
                  >
                    {" "}
                    close
                  </button>
                  <button
                    type="button"
                    className="btn btn-primary"
                    data-dismiss="modal"
                    onClick={this.handleSubmit}
                    // onClick={this.disableModalHandler}
                    style={{
                      float: "right",
                      marginBottom: "20px",
                      borderColor: "cadetblue",
                    }}
                    disabled={!this.validateForm()}
                  >
                    {" "}
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="modal" id="edit-user" role="dialog" aria-hidden="true">
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-body">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
                <h4>Manage Projects</h4>
                <div
                  className="model-xl"
                  style={{
                    height: "200px",
                    position: "relative",
                    overflow: "auto",
                  }}
                >
                  <div className="card-body">
                    <div className="form-group">
                      <label htmlFor="Role"></label>
                      <input
                        type="text"
                        id="Role"
                        name="Role"
                        className="form-control"
                        style={{ marginTop: "47px" }}
                        value={this.state.Role}
                        onChange={this.handleInputChange}
                      />
                    </div>
                  </div>
                </div>
                <div className="modal-footer">
                  {" "}
                  {/* Add a modal footer */}
                  <button
                    type="button"
                    className="btn btn-primary"
                    data-dismiss="modal"
                    // onClick={this.disableModalHandler}
                    style={{
                      float: "right",
                      marginBottom: "20px",
                      backgroundColor: "green",
                      borderColor: "green",
                    }}
                  >
                    {" "}
                    close
                  </button>
                  <button
                    type="button"
                    className="btn btn-primary"
                    data-dismiss="modal"
                    onClick={this.updateProject}
                    // onClick={this.disableModalHandler}
                    style={{
                      float: "right",
                      marginBottom: "20px",
                      borderColor: "cadetblue",
                    }}
                  >
                    {" "}
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="modal fade"
          id="delete-user"
          role="dialog"
          aria-hidden="true"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Delete Project</h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <h6>Are you sure you want to Delete this Project?</h6>
                <div className="form-group"></div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                  style={{ backgroundColor: "green", borderColor: "green" }}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="btn btn-primary"
                  data-dismiss="modal"
                  onClick={this.deleteRole}
                  style={{
                    backgroundColor: "cadetblue",
                    borderColor: "cadetblue",
                  }}
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default ManageProject;
