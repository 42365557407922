import React from "react";
import { callApi } from "../../utils/api";

interface Leave {
  id: number;
  leave_type: string;
}

interface leaveapprovestate {
  toDateInputValue: string;
  fromDateInputValue: string;
  allLeave: Leave[];
  reason: string;
}

class Leaveapprove extends React.Component {
  state: leaveapprovestate = {
    allLeave: [],
    fromDateInputValue: "",
    toDateInputValue: "",
    reason: "",
  };
  componentDidMount() {
    this.getLeavetype();
  }
  getLeavetype() {
    callApi("GET", "leavetypes_get.php")
      .then((res) => res.data)
      .then((response) => {
        this.setState({ allLeave: response.data });
        console.log("leave types", this.state.allLeave);
      })
      .catch((err) => {});
  }

  handleLeaveTypeChange = (event) => {
    const selectedLeaveTypeId = event.target.value;
    console.log("Selected Leave Type ID:", selectedLeaveTypeId);
  };
  render() {
    const { allLeave } = this.state;
    let columns = [
      {
        Header: "Date",
        accessor: "date",
        sort: true,
      },
      {
        Header: "Name",
        accessor: "name",
        sort: true,
      },
      {
        Header: "leave_type",
        accessor: "leave_type",
        sort: true,
      },
      {
        Header: "Reason",
        accessor: "reason",
        sort: true,
      },
    ];

    return (
      <div className="pcoded-main-container">
        <div className="pcoded-wrapper">
          <div className="pcoded-content">
            <div className="pcoded-inner-content">
              <div className="main-body">
                <div className="page-wrapper">
                  <div className="row pr-3 pl-3">
                    <div className="col-sm-12">
                      <div className="card card-wrapper">
                        <div
                          className="card-header"
                          style={{ padding: "45px 40px 30px 40px" }}
                        >
                          <h4>Leave Approval</h4>
                        </div>
                        <div
                          className="form-group d-flex align-items-center mt-6"
                          style={{ marginTop: "20px" }}
                        >
                          <label htmlFor="selectleave" className="mr-2">
                            Select leave :
                          </label>
                          <select
                            style={{
                              height: "40px",
                              width: "200px",
                              marginLeft: "5px",
                            }}
                            onChange={this.handleLeaveTypeChange}
                          >
                            <option value="">Select Leave</option>
                            {allLeave.map((leave) => (
                              <option key={leave.id} value={leave.id}>
                                {leave.leave_type}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Leaveapprove;
