import * as React from "react";
import "../user/AddUser.css";
import { callApi } from "../../utils/api";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";

interface AllProps {
  history: any;
  match: any;
}

interface DesignationOption {
  id: number;
  role: string;
}

class AddUser extends React.Component<AllProps> {
  state = {
    employeeid: "",
    username: "",
    email: "",
    password: "",
    confrimpassword: "",
    designationOptions: [] as DesignationOption[],
    designation: "",
    phonenumber: "",
    dateofbirth: "",
    singleUser: [],
    reportingto: "",
    allUser: [] as { id: number; name: string }[],
    selectedReportingTo: "",
    passwordMatch: true,
    phoneNumberError: "",
    emailError: "",
    usernameError: "",
  };

  componentDidMount(): void {
    console.log("userid", this.props.match.params.id);
    this.getRole();
    const empId = this.props.match.params.id;
    this.setState({ employeeid: empId });
    this.getSigleUser(empId);
    this.getAllUser();
  }
  getSigleUser(empId) {
    let req = {
      id: empId,
    };
    callApi("POST", "user_single_get.php", req)
      .then((res) => res.data)
      .then((response) => {
        this.setState({ singleUser: response.data });
      })
      .catch((err) => {});
  }
  getRole() {
    callApi("GET", "role_get.php")
      .then((res) => res.data)
      .then((response) => {
        this.setState({ designationOptions: response.data });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  handleGoBack = () => {
    window.history.back();
  };
  getAllUser() {
    // Make an API request to fetch all user names
    callApi("GET", "user_get.php")
      .then((res) => res.data)
      .then((response) => {
        const userArray = response.data.map((user) => ({
          id: user.id,
          name: user.name,
        }));

        this.setState({ allUser: userArray });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  handleInputChange = (event) => {
    const { name, value } = event.target;
    if (name === "username") {
      if (!/^[a-zA-Z\s]*$/.test(value)) {
        this.setState({
          username: value,
          usernameError: "Username must contain letters and spaces only",
        });
      } else {
        this.setState({ username: value, usernameError: "" });
      }
    }

    if (name === "email") {
      const endsWithValidDomain =
        value.toLowerCase().endsWith(".com") ||
        value.toLowerCase().endsWith(".in");

      if (!endsWithValidDomain) {
        this.setState({
          email: value,
          emailError: "Email must end with .com or .on",
        });
      } else {
        this.setState({ email: value, emailError: "" });
      }
    }

    if (name === "phonenumber") {
      if (value.length !== 10) {
        this.setState({ phoneNumberError: "Phone number must be 10 digits" });
      } else {
        this.setState({ phoneNumberError: "" });
      }
    }
    if (name === "reportingto") {
      const selectedUser = this.state.allUser.find(
        (user) => user.id === parseInt(value, 10)
      );
      if (selectedUser) {
        const selectedUserId = selectedUser.id;
        console.log(selectedUserId);
      }
    }

    if (name === "confrimpassword") {
      const { password } = this.state;
      if (password !== value) {
        this.setState({ passwordMatch: false });
      } else {
        this.setState({ passwordMatch: true });
      }
    }

    this.setState({ [name]: value });
  };

  handleDateChange = (date) => {
    const formattedDate = moment(date).format("DD-MM-YYYY");
    this.setState({ dateofbirth: formattedDate });
  };

  handleSubmit = (event) => {
    event.preventDefault();
    const userDetails = sessionStorage.getItem("userdetails");
    const userid = userDetails ? JSON.parse(userDetails)["id"] : "";
    const { password, confrimpassword } = this.state;
    if (password !== confrimpassword) {
      this.setState({ passwordMatch: false });
      return;
    }
    this.setState({ passwordMatch: true });
    let req = {
      emp_id: this.state.employeeid,
      username: this.state.username,
      email: this.state.email,
      password: this.state.password,
      designation: this.state.designation,
      ph_number: this.state.phonenumber,
      dob: this.state.dateofbirth,
      reporting_to: this.state.reportingto,
      created_by: userid,
    };
    callApi("POST", "user_register.php", req)
      .then((res) => res.data)
      .then((response) => {
        if (response.success == true) {
          this.props.history.goBack();
          toast.success(response.message, { autoClose: 3000 });
        } else {
          toast.error(response.message, { autoClose: 3000 });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  render() {
    const {
      employeeid,
      username,
      email,
      emailError,
      password,
      confrimpassword,
      designationOptions,
      phonenumber,
      phoneNumberError,
      dateofbirth,
      passwordMatch,
    } = this.state;
    // const designationOptions = [
    //   "Manager",
    //   "Developer",
    //   "Designer",
    //   "HR",
    //   "Marketing",
    //   // Add more designations as needed
    // ];
    return (
      <div className="pcoded-main-container">
        <div className="pcoded-wrapper">
          <div className="pcoded-content">
            <div className="pcoded-inner-content">
              <div className="main-body">
                <div className="page-wrapper">
                  <div className="row">
                    <div className="col-sm-12">
                      <div className="card">
                        <div
                          className="card-header"
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            padding: "17px",
                          }}
                        >
                          <h5>Add User</h5>
                          <div>
                            <Link to="/user" className="btn btn-secondary">
                              Go Back
                            </Link>
                          </div>
                        </div>
                        <div
                          className="card-body"
                          style={{ backgroundColor: "#f2f2f2" }}
                        >
                          <form className="row" onSubmit={this.handleSubmit}>
                            <div className="col-md-6">
                              <div
                                className="form-group"
                                style={{ display: "grid" }}
                              >
                                <label htmlFor="employeeid">
                                  Employee Id{" "}
                                  <span style={{ color: "red" }}>*</span>
                                </label>
                                <input
                                  type="text"
                                  name="employeeid"
                                  id="employeeid"
                                  value={employeeid}
                                  maxLength={10}
                                  onChange={this.handleInputChange}
                                  pattern="^(?=.*[A-Z]).*$"
                                  title="Empid must contain uppercase letter and number"
                                  required
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div
                                className="form-group"
                                style={{ display: "grid" }}
                              >
                                <label htmlFor="username">Username</label>
                                <input
                                  type="text"
                                  name="username"
                                  id="username"
                                  value={this.state.username}
                                  onChange={this.handleInputChange}
                                />
                                {this.state.usernameError && (
                                  <p style={{ color: "red", marginTop: "5px" }}>
                                    {this.state.usernameError}
                                  </p>
                                )}
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div
                                className="form-group"
                                style={{ display: "grid" }}
                              >
                                <label htmlFor="email">
                                  Email <span style={{ color: "red" }}>*</span>
                                </label>
                                <input
                                  type="text"
                                  name="email"
                                  id="email"
                                  value={this.state.email}
                                  onChange={this.handleInputChange}
                                  required
                                />
                                {this.state.emailError && (
                                  <p style={{ color: "red", marginTop: "5px" }}>
                                    {this.state.emailError}
                                  </p>
                                )}
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div
                                className="form-group"
                                style={{ display: "grid" }}
                              >
                                <label htmlFor="password">
                                  Password{" "}
                                  <span style={{ color: "red" }}>*</span>
                                </label>

                                <input
                                  type="text"
                                  name="password"
                                  id="password"
                                  value={this.state.password}
                                  onChange={this.handleInputChange}
                                  required
                                  aria-required="true"
                                  pattern="^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*]).{8,}$"
                                  title="Password must contain at least one uppercase letter, one lowercase letter,one special character, one number, and be at least 8 characters long"
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div
                                className="form-group"
                                style={{ display: "grid" }}
                              >
                                <label htmlFor="confrimpassword">
                                  Confrim Password
                                </label>

                                <input
                                  type="text"
                                  name="confrimpassword"
                                  id="confrimpassword"
                                  value={this.state.confrimpassword}
                                  onChange={this.handleInputChange}
                                  maxLength={15}
                                  required
                                />
                                {!passwordMatch && (
                                  <p style={{ color: "red", marginTop: "5px" }}>
                                    Passwords do not match!
                                  </p>
                                )}
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label htmlFor="designation">
                                  Designation{" "}
                                  <span style={{ color: "red" }}>*</span>
                                </label>
                                <select
                                  name="designation"
                                  id="designation"
                                  value={this.state.designation}
                                  onChange={this.handleInputChange}
                                  className="form-control"
                                  required
                                >
                                  <option value="">Select Designation</option>
                                  {designationOptions.map((designation) => (
                                    <option
                                      key={designation.id}
                                      value={designation.id}
                                    >
                                      {designation.role}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div
                                className="form-group"
                                style={{ display: "grid" }}
                              >
                                <label htmlFor="phonenumber">
                                  Phone Number
                                </label>
                                <input
                                  type="text"
                                  name="phonenumber"
                                  id="phonenumber"
                                  minLength={10}
                                  maxLength={10}
                                  pattern="[0-9]*"
                                  value={this.state.phonenumber}
                                  onChange={this.handleInputChange}
                                />
                                {phoneNumberError && (
                                  <p style={{ color: "red", marginTop: "5px" }}>
                                    {phoneNumberError}
                                  </p>
                                )}
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div
                                className="form-group"
                                style={{ display: "grid" }}
                              >
                                <label htmlFor="dateofbirth">DOB</label>
                                <DatePicker
                                  dateFormat="MM/dd/yyyy"
                                  id="dateofbirth"
                                  name="dateofbirth"
                                  className="form-control"
                                  placeholderText="Select DOB"
                                  showYearDropdown
                                  showMonthDropdown
                                  scrollableYearDropdown
                                  yearDropdownItemNumber={25}
                                  value={this.state.dateofbirth}
                                  onChange={this.handleDateChange}
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label htmlFor="reportingto">
                                  Reporting To
                                </label>
                                <select
                                  name="reportingto"
                                  id="reportingto"
                                  value={this.state.reportingto}
                                  onChange={this.handleInputChange}
                                  className="form-control"
                                >
                                  <option value="">Select Reporting To</option>
                                  {this.state.allUser.map((user) => (
                                    <option key={user.id} value={user.id}>
                                      {user.name}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            </div>

                            <div className="col-md-12 text-center mt-4">
                              <div className="row">
                                <div className="col-md-6 text-right">
                                  <button
                                    type="submit"
                                    className="btn btn-primary"
                                    style={{
                                      borderRadius: "10px",
                                      backgroundColor: "green",
                                      color: "#fff",
                                      borderColor: "green",
                                      width: "94px",
                                      height: "47px",
                                    }}
                                  >
                                    Submit
                                  </button>
                                </div>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AddUser;
