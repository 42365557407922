import * as React from "react";
import { Link } from "react-router-dom";
import Table from "../user/Table";
import { callApi } from "../../utils/api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendar,
  faPaperclip,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
interface AllProps {
  history: any;
}

interface Task {
  task_date: string;
  submission_time: string;
  task: string;
  task_status: string;
  comments: string;
  image: string;
  git_id?: string;
  url: string;
  ro_review: string;
}

interface User {
  id: number;
  name: string;
}

interface Reportingofficerscreen2State {
  role_id: number;
  isCalendarVisible: boolean;
  startDate: Date;
  endDate: Date;
  searchQuery: string;
  activeTab: string;
  activeTabContent: string;
  allTasks: Task[];
  emp_id: number;
  isAttachmentPopupOpen: boolean;
  selectedAttachmentUrl: string;
  fromDate: Date;
  fromDateInputValue: string;
  toDateString: string;
  toDateInputValue: string;
  allUser: User[];
  selectedDeveloperId: number;
}

class Report extends React.Component<AllProps> {
  state: Reportingofficerscreen2State = {
    isCalendarVisible: false,
    role_id: 0,
    startDate: new Date(),
    endDate: new Date(),
    searchQuery: "",
    activeTab: "Daily",
    activeTabContent: "Developer",
    allTasks: [],
    emp_id: 0,
    isAttachmentPopupOpen: false,
    selectedAttachmentUrl: "",
    fromDate: new Date(),
    fromDateInputValue: "",
    toDateString: moment(new Date()).format("DD/MM/yyyy"),
    toDateInputValue: "",
    allUser: [],
    selectedDeveloperId: 0,
  };

  componentDidMount() {
    this.getUser();
    const userDetails = sessionStorage.getItem("userdetails");
    let userId = userDetails ? JSON.parse(userDetails).id : null;
    let role_id = userDetails ? JSON.parse(userDetails).designation : null;
    if (userDetails) {
      const parsedUserDetails = JSON.parse(userDetails);
      userId = parseInt(parsedUserDetails.id);
      role_id = parseInt(parsedUserDetails.designation);
      this.setState({ emp_id: userId, role_id: role_id }, () => {
        this.getRoTasks();
      });
    }
  }

  getUser() {
    callApi("GET", "user_get.php")
      .then((res) => res.data)
      .then((response) => {
        this.setState({ allUser: response.data });
      })
      .catch((err) => {});
  }

  handleAttachmentClick = (attachmentUrl: string) => {
    this.setState({
      isAttachmentPopupOpen: true,
      selectedAttachmentUrl: attachmentUrl,
    });
  };

  handleActiveTabChange = (tab: string) => {
    this.setState({ activeTab: "Daily", activeTabContent: tab });
  };

  handleTabChange = (tab: string) => {
    console.log(tab);
    if (tab === "Monthly") {
      this.setState({
        activeTab: tab,
        isCalendarVisible: true,
        allTasks: [],
        fromDateInputValue: "",
        toDateInputValue: "",
      });
    } else if (tab === "Weekly") {
      this.setState({ activeTab: tab, isCalendarVisible: false });
      this.getTasksForWeek();
    } else {
      this.setState({ activeTab: tab, isCalendarVisible: false });
      this.getRoTasks();
    }
  };

  handleBoxClick = () => {
    this.setState({ isCalendarVisible: true });
  };

  handleFromDateChange = (date: Date) => {
    this.setState({ startDate: date }, () => {
      this.updateFromDateInputValue(date);
      this.updateToDateMinDate(date);
    });
  };
  updateToDateMinDate = (date: Date) => {
    this.setState({ endDate: date });
  };

  updateFromDateInputValue = (date: Date) => {
    const formattedDate = moment(date).format("DD/MM/yyyy");
    this.setState({ fromDateInputValue: formattedDate });
  };

  handleToDateChange = (date: Date) => {
    this.setState({ endDate: date }, () => {
      this.updateToDateInputValue(date);
      this.getTasksForMonth(this.state.startDate, this.state.endDate);
    });
  };

  handleDeveloperSelect = (developerId: number) => {
    this.setState({ selectedDeveloperId: developerId });
  };

  updateToDateInputValue = (date: Date) => {
    const formattedDate = moment(date).format("DD/MM/yyyy");
    this.setState({ toDateInputValue: formattedDate });
  };
  getRoTasks = () => {
    const date = new Date();
    const formattedDate = moment(date).format("DD-MM-yyyy");
    let req = {
      emp_id: this.state.emp_id,
      date: formattedDate,
    };
    callApi("POST", "task_get.php", req)
      .then((res) => res.data)
      .then((response) => {
        this.setState({ allTasks: response.data });
        console.log("status", this.state.allTasks);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  getTasksForWeek = () => {
    const today = new Date();
    const from = new Date(today);
    from.setDate(from.getDate() - today.getDay());
    const formattedFrom = moment(from).format("DD-MM-yyyy");
    const formattedTo = moment(today).format("DD-MM-yyyy");
    const req = {
      emp_id: this.state.emp_id,
      date: formattedFrom,
      todate: formattedTo,
    };
    callApi("POST", "task_get.php", req)
      .then((res) => res.data)
      .then((response) => {
        this.setState({ allTasks: response.data });
        console.log("status", this.state.allTasks);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  getTasksForMonth = (fromDate: Date, toDate: Date) => {
    const formattedFromDate = moment(fromDate).format("DD-MM-yyyy");
    const formattedToDate = moment(toDate).format("DD-MM-yyyy");
    const req = {
      emp_id: this.state.emp_id,
      date: formattedFromDate,
      todate: formattedToDate,
    };
    callApi("POST", "task_get.php", req)
      .then((res) => res.data)
      .then((response) => {
        this.setState({ allTasks: response.data });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  render() {
    const data = this.state.allTasks;
    const columns = [
      {
        Header: "Date",
        accessor: "task_date",
        sort: true,
      },
      {
        Header: "Time",
        accessor: "submission_time",
        sort: true,
      },
      {
        Header: "Task",
        accessor: "task",
        sort: true,
      },
      {
        Header: "Status",
        accessor: "task_status",
        sort: true,
      },
      {
        Header: "Comments",
        accessor: "comments",
        sort: false,
      },
      {
        Header: "Attachments",
        accessor: "image",
        sort: false,
        Cell: ({ value, row }) => {
          return value && value !== "http://eod.zerame.com/api/photo/" ? (
            <div>
              <FontAwesomeIcon
                icon={faPaperclip}
                onClick={() => this.handleAttachmentClick(row.original.image)}
                data-toggle="modal"
                data-target="#edit-user"
                style={{ cursor: "pointer" }}
              />
            </div>
          ) : (
            ""
          );
        },
      },
      {
        Header: "Review",
        accessor: "ro_review",
        sort: false,
      },
    ];
    if (this.state.role_id === 3) {
      columns.splice(4, 0, {
        Header: "GIT ID",
        accessor: "git_id",
        sort: true,
      });
    } else if (this.state.role_id === 4) {
      columns.splice(4, 0, {
        Header: "URL",
        accessor: "url",
        sort: true,
      });
    }

    const sizePerPageList = [
      {
        text: "5",
        value: 5,
      },
      {
        text: "10",
        value: 10,
      },
      {
        text: "25",
        value: 25,
      },
      {
        text: "All",
        value: data.length,
      },
    ];

    const { isCalendarVisible, activeTab, activeTabContent } = this.state;
    const userDetails = sessionStorage.getItem("userdetails");
    const userName = userDetails ? JSON.parse(userDetails)["name"] : "";

    return (
      <section className="pcoded-main-container">
        <div className="pcoded-wrapper">
          <div className="pcoded-content">
            <div className="pcoded-inner-content">
              <div className="main-body">
                <div className="page-wrapper">
                  <div className="row">
                    <div className="col-sm-12">
                      <div className="card card-wrapper">
                        <div
                          className="card-header"
                          style={{ padding: "45px 40px 30px 40px" }}
                        >
                          <h2 style={{ marginLeft: "30px" }}>Hi {userName}</h2>
                          <ul className="nav nav-underline">
                            <li className="nav-item">
                              <a
                                className={`nav-link ${
                                  activeTabContent === "Developer"
                                    ? "active"
                                    : ""
                                }`}
                                style={{
                                  fontSize: "22px",
                                  paddingLeft: "0px",
                                  marginLeft: "30px",
                                  color:
                                    activeTabContent === "Developer"
                                      ? "blue"
                                      : "black",
                                }}
                                onClick={() =>
                                  this.handleActiveTabChange("Developer")
                                }
                                href="#"
                              >
                                Your Report
                              </a>
                            </li>
                          </ul>
                          <div>
                            {activeTabContent === "Developer" && (
                              <div>
                                <div
                                  className="row"
                                  style={{
                                    marginTop: "4px",
                                    marginLeft: "20px",
                                  }}
                                >
                                  <ul className="nav nav-underline">
                                    <li className="nav-item">
                                      <a
                                        className={`nav-link ${
                                          activeTab === "Daily" ? "active" : ""
                                        }`}
                                        onClick={() =>
                                          this.handleTabChange("Daily")
                                        }
                                        style={{
                                          fontSize: "16px",
                                          paddingLeft: "14px",
                                          color:
                                            activeTab === "Daily"
                                              ? "green"
                                              : "black",
                                        }}
                                        aria-current="page"
                                        href="#"
                                      >
                                        Daily
                                      </a>
                                    </li>
                                    <li className="nav-item">
                                      <a
                                        className={`nav-link ${
                                          activeTab === "Weekly" ? "active" : ""
                                        }`}
                                        onClick={() =>
                                          this.handleTabChange("Weekly")
                                        }
                                        style={{
                                          fontSize: "16px",
                                          color:
                                            activeTab === "Weekly"
                                              ? "green"
                                              : "black",
                                        }}
                                        href="#"
                                      >
                                        Weekly
                                      </a>
                                    </li>
                                    <li className="nav-item">
                                      <a
                                        className={`nav-link ${
                                          activeTab === "Monthly"
                                            ? "active"
                                            : ""
                                        }`}
                                        style={{
                                          fontSize: "16px",
                                          color:
                                            activeTab === "Monthly"
                                              ? "green"
                                              : "black",
                                          paddingLeft: "14px",
                                        }}
                                        onClick={() =>
                                          this.handleTabChange("Monthly")
                                        }
                                        href="#"
                                      >
                                        Monthly
                                      </a>
                                    </li>
                                  </ul>
                                </div>
                                <div>
                                  {isCalendarVisible &&
                                    activeTab === "Monthly" && (
                                      <div
                                        style={{
                                          display: "flex",
                                          cursor: "pointer",
                                          marginTop: "10px",
                                          marginLeft: "20px",
                                        }}
                                        onClick={this.handleBoxClick}
                                      >
                                        <div
                                          style={{
                                            display: "flex",
                                            alignItems: "center",
                                            border: "1px solid grey",
                                            height: "40px",
                                            width: "132px",
                                          }}
                                        >
                                          <FontAwesomeIcon
                                            icon={faCalendar}
                                            style={{
                                              marginRight: "5px",
                                              height: "20px",
                                              width: "30px",
                                              marginLeft: "10px",
                                            }}
                                          />
                                          <DatePicker
                                            className="date-wrapper"
                                            onChange={this.handleFromDateChange}
                                            placeholderText={
                                              this.state.fromDateInputValue ||
                                              "DD/MM/YYYY"
                                            }
                                            dateFormat="dd/MM/yyyy"
                                          />
                                        </div>
                                        <div
                                          style={{
                                            display: "flex",
                                            alignItems: "center",
                                            border: "1px solid grey",
                                            height: "40px",
                                            width: "132px",
                                            marginLeft: "20px",
                                          }}
                                        >
                                          <FontAwesomeIcon
                                            icon={faCalendar}
                                            style={{
                                              marginRight: "5px",
                                              height: "20px",
                                              width: "30px",
                                              marginLeft: "10px",
                                            }}
                                          />
                                          <DatePicker
                                            className="date-wrapper"
                                            onChange={this.handleToDateChange}
                                            placeholderText={
                                              this.state.toDateInputValue ||
                                              "DD/MM/YYYY"
                                            }
                                            dateFormat="dd/MM/yyyy"
                                            minDate={this.state.startDate}
                                          />
                                        </div>
                                      </div>
                                    )}
                                </div>
                                <div className="card-body">
                                  <div className="dt-responsive table-responsive">
                                    <Table
                                      columns={columns}
                                      data={data}
                                      pageSize={25}
                                      sizePerPageList={sizePerPageList}
                                      isSortable={true}
                                      pagination={true}
                                      isSearchable={true}
                                    />
                                  </div>
                                </div>
                                <div
                                  className="modal"
                                  id="edit-user"
                                  role="dialog"
                                  aria-hidden="true"
                                >
                                  <div className="modal-dialog modal-xl">
                                    <div className="modal-content">
                                      <div
                                        className="modal-body"
                                        style={{ height: "700px" }}
                                      >
                                        <button
                                          type="button"
                                          className="close"
                                          data-dismiss="modal"
                                          aria-label="Close"
                                        >
                                          <span aria-hidden="true">
                                            &times;
                                          </span>
                                        </button>
                                        <h4>Attachment</h4>
                                        <div
                                          className="model-xl"
                                          style={{
                                            height: "200px",
                                            position: "relative",
                                          }}
                                        >
                                          <div className="card-body">
                                            <div className="form-group">
                                              <img
                                                src={
                                                  this.state
                                                    .selectedAttachmentUrl
                                                }
                                                alt="Attachment url"
                                                style={{
                                                  height: "500",
                                                  width: "1000px",
                                                }}
                                              />
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Report;
