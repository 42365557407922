import * as React from "react";
import Loader from "react-loader-spinner";
import { callApi } from "../../utils/api";
import { History } from "history";
import "../login/login_one.css";
import "../login/login.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEye,
  faEyeSlash,
  faArrowRight,
  faLongArrowAltRight,
  faFastForward,
  faForward,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import logoone from "../../assets/images/zerame ico1.png";
import logo from "../../assets/logo.png";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "bootstrap/dist/css/bootstrap.min.css";

interface LoginState {
  email: string;
  password: string;
  emailError: string;
  passwordError: string;
  showPassword: boolean;
  isloading: boolean;
}
class Login extends React.Component<{ history: History }, LoginState> {
  constructor(props: { history: History }) {
    super(props);
    this.state = {
      email: "",
      password: "",
      emailError: "",
      passwordError: "",
      showPassword: false,
      isloading: true,
    };
  }
  handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    this.setState((prevState: LoginState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    this.login();
  };
  login = () => {
    const { email, password } = this.state;
    let valid = true;

    // Validation checks
    if (email.trim() === "") {
      this.setState({ emailError: "Employee ID is required" });
      valid = false;
    }
    if (password.trim() === "") {
      this.setState({ passwordError: "Password is required" });
      valid = false;
    }

    if (valid) {
      const loginInput = {
        emp_id: email,
        password: password,
      };
      callApi("POST", "login.php", loginInput)
        .then((res) => res.data)
        .then((response) => {
          if (response.statusCode == 200) {
            sessionStorage.setItem("user", response.responseJson[0]);
            sessionStorage.setItem(
              "userdetails",
              JSON.stringify(response.responseJson[0])
            );
            this.props.history.push("/dashboard");
            console.log("userdetails", response.responseJson[0]);
            toast.success("Login successful!", { autoClose: 3000 });
          } else {
            toast.error("Incorrect Employee ID or Password!", {
              autoClose: 3000,
            });
          }
        })
        .catch((err) => {
          console.log(err);
          toast.error("Incorrect Username or Password!", { autoClose: 3000 });
          this.setState({ isloading: false });
        });
    }
  };

  togglePasswordVisibility = () => {
    this.setState((prevState: LoginState) => ({
      ...prevState,
      showPassword: !prevState.showPassword,
    }));
  };
  setStatus = (userid1) => {
    const user: any = sessionStorage.getItem("user");
    const user_name = user ? JSON.parse(user)["userName"] : "";
    const role = user ? JSON.parse(user)["teamType"] : "";
    const role_type = user ? JSON.parse(user)["roleType"] : "";
    let dateTime = new Date();
    let curentTime = dateTime.toLocaleTimeString();
    console.log("221", curentTime);
    let obj = {
      user_id: userid1,
      status: 1,
      file_no: "Still not taken any file",
      in_time: curentTime,
      reason: "Just now LoggedIn",
      user_name: user_name,
      team_type: role,
      role_type: role_type,
      working_process: "Just now Logged in",
      bank_name: "Just now Logged in",
    };
    callApi("POST", "live_track_insert.php", obj)
      .then((res) => res.data)
      .then((response) => {
        console.log("53", response);
      });
  };
  onchange = (e: any) => {
    const { name, value } = e.target;
    this.setState((prevState: LoginState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  render() {
    const { email, password, emailError, passwordError, showPassword } =
      this.state;
    return (
      <div className="first-div p-4 background-radial-gradient overflow-hidden">
        <div className="">
          <div
            className={`col lg=6 md=6 text-center bg-blue p-4 background-trans self-center`}
          >
            <img alt="Logo" className="img-fluid" width="300px" src={logoone} />
            <h1>Zerame Infotech</h1>
            <p style={{ fontSize: "1.3em" }}>
              Innovate, Integrate, Elevate: Your Business Empowered.
            </p>
          </div>
          <div className="col-xs-6 md-6 self-center">
            <div className="CardBody d-flex justify-content-center align-items-center">
              <form className="form-align">
                <div className="row">
                  <div className="col-md-4 ">
                    <div style={{ width: "100%" }}>
                      <input
                        placeholder="Emp Id"
                        type="email"
                        className="form-control"
                        name="email"
                        maxLength={10}
                        value={email}
                        onChange={this.handleChange}
                        onFocus={() => this.setState({ emailError: "" })}
                      />
                    </div>

                    <div className="text-danger">{emailError}</div>
                  </div>
                  <div className="col-md-4">
                    <div style={{ position: "relative", width: "100%" }}>
                      <input
                        placeholder="Password"
                        type={showPassword ? "text" : "password"}
                        name="password"
                        className="form-control"
                        maxLength={15}
                        value={password}
                        onChange={this.handleChange}
                        onFocus={() => this.setState({ passwordError: "" })}
                      />
                      <span
                        className="eye-icon"
                        onClick={this.togglePasswordVisibility}
                        style={{
                          position: "absolute",
                          right: "10px",
                          top: "50%",
                          transform: "translateY(-50%)",
                          cursor: "pointer",
                        }}
                      >
                        <FontAwesomeIcon
                          icon={showPassword ? faEyeSlash : faEye}
                        />
                      </span>
                    </div>
                    <div className="text-danger">{passwordError}</div>
                  </div>
                  <div className="coll">
                    <button
                      className="btn-login"
                      type="submit"
                      onClick={this.handleClick}
                      style={{ marginTop: "7px" }}
                    >
                      <FontAwesomeIcon
                        icon={faChevronRight}
                        style={{ fontSize: "1.5em" }}
                      />
                      <FontAwesomeIcon
                        icon={faChevronRight}
                        style={{ fontSize: "1.5em" }}
                      />
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Login;
