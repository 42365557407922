import * as React from "react";
import "./Dashboard.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendar, faUpload } from "@fortawesome/free-solid-svg-icons";
import ReactFileReader from "react-file-reader";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { callApi } from "../../utils/api";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Adminscreen1 from "./Admin/Adminscreen1";
import HRScreen from "./Hr/Hrscreen";
import Modal from "react-modal";
import Hrscreen from "./Hr/Hrscreen";
declare var $: any;

interface AllProps {
  match: any;
  history: any;
  router: any;
  route: any;
}
export interface tasklist {
  task: string;
  task_status: string;
  git_id: string;
  image: string;
  comments: string;
  url: string;
}

interface DashboardState {
  startDate: Date | null;
  formattedStartDate: string;
  additionalFields: {
    task: number;
    task_status: string;
    git_id: string;
    image: string;
    comments: string;
    url: string;
  }[];
  selectedFileName: string | null;
  selectedAdditionalFileName: string | null;
  user_id: number;
  task: string;
  task_status: number;
  git_id: string;
  image: string;
  comments: string;
  task_date: string;
  role_id: number;
  allStatus: {
    id: number;
    task_status: string;
  }[];
  allTasks: {
    task: number;
    task_status: string;
    git_id: string;
    image: string;
    comments: string;
    url: string;
  }[];
  isButtonDisabled: boolean;
  isFormSubmitted: boolean;
  url: string;
  isImageModalOpen: boolean;
  selectedAdditionalImageIndex: number;
}

class Dashboard extends React.Component<AllProps, DashboardState> {
  state: DashboardState = {
    startDate: new Date(),
    formattedStartDate: moment(new Date()).format("DD-MM-yyyy"),
    additionalFields: [
      {
        task: 0,
        task_status: "",
        git_id: "",
        image: "",
        comments: "",
        url: "",
      },
    ],
    selectedFileName: null,
    selectedAdditionalFileName: null,
    user_id: 0,
    task: "",
    task_status: 0,
    git_id: "",
    image: "",
    comments: "",
    task_date: "",
    role_id: 0,
    allStatus: [],
    allTasks: [],
    isButtonDisabled: false,
    isFormSubmitted: false,
    url: "",
    isImageModalOpen: false,
    selectedAdditionalImageIndex: -1,
  };

  componentDidMount() {
    const userDetails = sessionStorage.getItem("userdetails");
    let userId = userDetails ? JSON.parse(userDetails).id : null;
    if (userDetails) {
      const parsedUserDetails = JSON.parse(userDetails);
      userId = parseInt(parsedUserDetails.id);
      const designationId = parseInt(parsedUserDetails.designation);
      this.setState({
        user_id: userId,
        role_id: designationId,
      });
      this.getsaveTasks(userId);
    }
    if (userId !== undefined) {
      this.getStatus();
    }
  }

  handleReportButtonClick = () => {
    if (this.state.role_id == 2) {
      this.props.history.push("/Report-officer");
    } else {
      this.props.history.push("/Report");
    }
  };

  handleChangeStartDate = () => {
    const date = new Date();
    const formattedDate = moment(date).format("DD-MM-yyyy");
    this.setState({
      formattedStartDate: formattedDate,
    });
  };

  handleFileChange = (files) => {
    if (files && files.fileList.length > 0) {
      const file = files.fileList[0];
      const reader = new FileReader();
      reader.onload = (event) => {
        const base64Image = event.target?.result;
        console.log("image", base64Image);
        if (typeof base64Image === "string") {
          this.setState({
            selectedFileName: file.name,
            image: base64Image,
          });
        }
      };
      reader.readAsDataURL(file);
    }
  };

  handleAdditionalFileChange = (files, index) => {
    const allowedFileTypes = [".jpg", ".jpeg", ".png"];

    if (files && files.fileList.length > 0) {
      const file = files.fileList[0];
      const fileType = file.name.split(".").pop().toLowerCase(); // Get the file extension

      if (!allowedFileTypes.includes(`.${fileType}`)) {
        toast.error("Only JPG, JPEG, and PNG file types are allowed.", {
          autoClose: 2000,
        });
        return;
      }

      const reader = new FileReader();
      reader.onload = (event) => {
        const base64Image = event.target?.result;
        console.log("image", base64Image);
        if (typeof base64Image === "string") {
          const updatedFields = [...this.state.additionalFields];
          console.log("updatedFields", updatedFields);
          updatedFields[index].image = base64Image;
          this.setState({
            selectedAdditionalFileName: file.name,
            additionalFields: updatedFields,
            selectedAdditionalImageIndex: index,
          });

          // Resetting values based on role conditions
        }
      };
      reader.readAsDataURL(file);
    }
  };

  openAdditionalFieldImagePopup = (index) => {
    this.setState({
      selectedAdditionalImageIndex: index,
    });
  };
  extractFilename = (fullPath) => {
    const parts = fullPath.split("\\");
    return parts[parts.length - 1];
  };

  handleAddButtonClick = () => {
    const newField = {
      task: 0,
      task_status: "",
      git_id: "",
      image: "",
      comments: "",
      url: "",
    };
    this.setState((prevState) => ({
      additionalFields: [...prevState.additionalFields, newField],
    }));
  };

  getsaveTasks = (userid) => {
    let req = {
      emp_id: userid,
      status: "1",
    };
    callApi("POST", "task_save_get.php", req)
      .then((res) => res.data)
      .then((response) => {
        this.setState({ allTasks: response.data });
        console.log("data0", this.state.allTasks);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  removeTask = (index) => {
    this.setState((prevState) => ({
      additionalFields: prevState.additionalFields.filter(
        (field, i) => i !== index
      ),
    }));
  };
  saveTask = () => {
    const { additionalFields } = this.state;
    const submissionTime = new Date().toLocaleTimeString();

    const isAnyTaskInvalid = additionalFields.some(
      (field) => !field.task || !field.task_status
    );

    if (isAnyTaskInvalid) {
      this.setState({ isFormSubmitted: true });
      return;
    }

    const tasksToSubmit = [
      ...this.state.additionalFields.map((task) => ({
        ...task,
      })),
    ];
    const req = {
      data: tasksToSubmit.map((task) => ({
        task: task.task,
        task_status: task.task_status,
        git_id: this.state.role_id === 3 ? task.git_id : "",
        url: this.state.role_id === 4 ? task.url : "",
        comments: task.comments,
        image: task.image || "",
      })),
      user_id: this.state.user_id,
      comments: this.state.comments,
      task_date: this.state.formattedStartDate,
      role_id: this.state.role_id,
      submission_time: submissionTime,
      status: "1",
    };
    callApi("POST", "task_insert.php", req)
      .then((res) => res.data)
      .then((response) => {
        if (response.statue == true) {
          this.setState({ isButtonDisabled: false });
          toast.success("Task Saved successfully!", { autoClose: 2000 });
          this.getsaveTasks(this.state.user_id);
          this.setState({
            task: "",
            additionalFields: [
              {
                task: 0,
                task_status: "",
                git_id: "",
                image: "",
                comments: "",
                url: "",
              },
            ],
            comments: "",
            task_date: "",
            task_status: 0,
            selectedFileName: "",
            git_id: "",
            isFormSubmitted: false,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  addTask = () => {
    const { additionalFields } = this.state;
    const submissionTime = new Date().toLocaleTimeString();

    const isAnyTaskInvalid = additionalFields.some(
      (field) => !field.task || !field.task_status
    );

    if (isAnyTaskInvalid) {
      this.setState({ isFormSubmitted: true });
      return;
    }
    this.setState({ isButtonDisabled: true });

    const tasksToSubmit = [
      ...this.state.additionalFields.map((task) => ({
        ...task,
      })),
    ];
    const req = {
      data: tasksToSubmit.map((task) => ({
        task: task.task,
        task_status: task.task_status,
        git_id: this.state.role_id === 3 ? task.git_id : "",
        url: this.state.role_id === 4 ? task.url : "",
        comments: task.comments,
        image: task.image || "",
      })),
      user_id: this.state.user_id,
      comments: this.state.comments,
      task_date: this.state.formattedStartDate,
      role_id: this.state.role_id,
      submission_time: submissionTime,
      status: "0",
    };
    callApi("POST", "task_update.php", req)
      .then((res) => res.data)
      .then((response) => {
        if (response.status == true) {
          this.setState({ isButtonDisabled: false });
          toast.success("Task Inserted successfully!", { autoClose: 2000 });
          this.getsaveTasks(this.state.user_id);
          this.setState({
            task: "",
            additionalFields: [
              {
                task: 0,
                task_status: "",
                git_id: "",
                image: "",
                comments: "",
                url: "",
              },
            ],
            comments: "",
            task_date: "",
            task_status: 0,
            selectedFileName: "",
            git_id: "",
            isFormSubmitted: false,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  updateTaskField = (index: number, fieldName: string, value: any) => {
    const updatedFields = [...this.state.additionalFields];
    updatedFields[index][fieldName] = value;
    this.setState({ additionalFields: updatedFields });
  };

  getStatus() {
    callApi("GET", "dropdown_get.php")
      .then((res) => res.data)
      .then((response) => {
        this.setState({ allStatus: response.data });
        console.log("status", this.state.allStatus);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  render() {
    const { selectedFileName } = this.state;
    const userDetails = sessionStorage.getItem("userdetails");
    const userDesignation = userDetails
      ? JSON.parse(userDetails).designation
      : null;
    const userName = userDetails ? JSON.parse(userDetails)["name"] : "";
    const { isButtonDisabled } = this.state;
    let content;
    return (
      <>
        {this.state.role_id != 1 && this.state.role_id != 6 ? (
          <div className="pcoded-main-container">
            <div className="pcoded-wrapper">
              <div className="pcoded-content">
                <div className="pcoded-inner-content">
                  <div className="main-body">
                    <div className="page-wrapper">
                      <div className="row pr-3 pl-3">
                        <div className="col-sm-12">
                          <div className="card card-wrapper">
                            <div
                              className="card-header"
                              style={{ padding: "45px 40px 30px 40px" }}
                            >
                              <h4>Hi {userName}</h4>
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  marginTop: "20px",
                                  border: "1px solid grey",
                                  height: "40px",
                                  width: "132px",
                                }}
                              >
                                <FontAwesomeIcon
                                  icon={faCalendar}
                                  style={{
                                    marginRight: "5px",
                                    height: "20px",
                                    width: "30px",
                                    marginLeft: "10px",
                                  }}
                                />
                                <DatePicker
                                  className="date-wrapper"
                                  selected={this.state.startDate}
                                  readOnly
                                  placeholderText="DD/MM/YYYY"
                                  dateFormat="dd/MM/yyyy"
                                />
                              </div>
                              {this.state.allTasks.length > 0 && (
                                <div
                                  className="form-group"
                                  style={{ marginTop: "20px" }}
                                >
                                  <h3>Saved Tasks</h3>
                                  <table className="table table-striped">
                                    <thead>
                                      <tr>
                                        <th>SNo</th>
                                        <th>Task</th>
                                        <th>Status</th>
                                        <th>Comments</th>
                                        {this.state.role_id === 3 && (
                                          <th>Git ID</th>
                                        )}
                                        {this.state.role_id === 4 && (
                                          <th>URL</th>
                                        )}
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {this.state.allTasks.map(
                                        (task, index) => (
                                          <tr key={index}>
                                            <td>{index + 1}</td>
                                            <td>{task.task}</td>
                                            <td>{task.task_status}</td>
                                            <td>{task.comments}</td>
                                            {this.state.role_id === 3 && (
                                              <td>{task.git_id}</td>
                                            )}
                                            {this.state.role_id === 4 && (
                                              <td>{task.url}</td>
                                            )}
                                          </tr>
                                        )
                                      )}
                                    </tbody>
                                  </table>
                                </div>
                              )}
                              <button
                                type="button"
                                className="btn btn-primary"
                                onClick={this.handleReportButtonClick}
                                style={{
                                  float: "right",
                                  backgroundColor: "green",
                                  borderColor: "green",
                                }}
                              >
                                Report
                              </button>
                              <h2
                                className="file-status-header"
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  textAlign: "center",
                                }}
                              >
                                Task
                              </h2>

                              {this.state.additionalFields.map(
                                (field: any, index) => (
                                  <div className="row" key={index}>
                                    <div className="form-group">
                                      <label
                                        htmlFor={`label4-${index}`}
                                      ></label>
                                      <textarea
                                        id={`label4-${index}`}
                                        className={`form-control ${
                                          this.state.isFormSubmitted &&
                                          (!field.task || field.task.length < 3) // Updated validation condition
                                            ? "is-invalid"
                                            : ""
                                        }`}
                                        style={{
                                          width: "300px",
                                          marginRight: "15px",
                                          marginLeft: "15px",
                                          height: "45px",
                                          borderColor:
                                            this.state.isFormSubmitted &&
                                            (!field.task ||
                                              field.task.length < 3)
                                              ? "red"
                                              : "",
                                        }}
                                        placeholder="Enter task"
                                        minLength={3}
                                        value={
                                          field.task !== 0 ? field.task : ""
                                        }
                                        onChange={(e) =>
                                          this.updateTaskField(
                                            index,
                                            "task",
                                            e.target.value
                                          )
                                        }
                                        required
                                      />
                                      {this.state.isFormSubmitted &&
                                        (!field.task ||
                                          field.task.length < 3) && (
                                          <div
                                            className="invalid-feedback"
                                            style={{
                                              textAlign: "center",
                                              fontSize: "15px",
                                              fontWeight: "bolder",
                                            }}
                                          >
                                            Task should be at least 3 characters
                                            long
                                          </div>
                                        )}
                                    </div>
                                    <div className="form-group">
                                      <label
                                        htmlFor={`label8-${index}`}
                                      ></label>
                                      <select
                                        id={`label8-${index}`}
                                        className={`form-control ${
                                          this.state.isFormSubmitted &&
                                          field.task_status === ""
                                            ? "is-invalid"
                                            : ""
                                        }`}
                                        style={{
                                          width: "211px",
                                          marginLeft: "5px",
                                          marginRight: "15px",
                                          borderColor:
                                            this.state.isFormSubmitted &&
                                            !field.task_status
                                              ? "red"
                                              : "",
                                        }}
                                        value={field.task_status}
                                        onChange={(e) =>
                                          this.updateTaskField(
                                            index,
                                            "task_status",
                                            parseInt(e.target.value)
                                          )
                                        }
                                        required
                                      >
                                        <option value="">Select status</option>
                                        {this.state.allStatus.map((status) => (
                                          <option
                                            key={status.id}
                                            value={status.id}
                                          >
                                            {status.task_status}
                                          </option>
                                        ))}
                                      </select>
                                      {this.state.isFormSubmitted &&
                                        !field.task_status && (
                                          <div
                                            className="invalid-feedback"
                                            style={{
                                              textAlign: "center",
                                              fontSize: "15px",
                                              fontWeight: "bolder",
                                            }}
                                          >
                                            Status is required
                                          </div>
                                        )}
                                    </div>
                                    <div className="form-group">
                                      <label
                                        htmlFor={`labelComments-${index}`}
                                      ></label>
                                      <textarea
                                        id={`labelComments-${index}`}
                                        className="form-control"
                                        style={{
                                          width: "300px",
                                          marginRight: "15px",
                                          marginLeft: "10px",
                                          height: "45px",
                                        }}
                                        placeholder="Comments"
                                        value={field.comments}
                                        onChange={(e) =>
                                          this.updateTaskField(
                                            index,
                                            "comments",
                                            e.target.value
                                          )
                                        }
                                      />
                                    </div>
                                    <div className="form-group">
                                      <label
                                        htmlFor={`label9-${index}`}
                                      ></label>
                                      {userDesignation == 3 && (
                                        <input
                                          type="text"
                                          id={`label9-${index}`}
                                          className="form-control"
                                          style={{
                                            marginLeft: "5px",
                                            height: "45px",
                                            width: "180px",
                                          }}
                                          placeholder="Git ID"
                                          value={field.git_id}
                                          onChange={(e) =>
                                            this.updateTaskField(
                                              index,
                                              "git_id",
                                              e.target.value
                                            )
                                          }
                                        />
                                      )}
                                      {this.state.role_id === 4 && (
                                        <input
                                          type="text"
                                          id={`label9-${index}`}
                                          className="form-control"
                                          style={{
                                            marginLeft: "5px",
                                            height: "45px",
                                            width: "180px",
                                          }}
                                          placeholder="URL"
                                          value={field.url}
                                          onChange={(e) =>
                                            this.updateTaskField(
                                              index,
                                              "url",
                                              e.target.value
                                            )
                                          }
                                        />
                                      )}
                                    </div>
                                    <div
                                      className="form-group"
                                      style={{
                                        marginLeft: "25px",
                                        marginTop: "16px",
                                      }}
                                    >
                                      <ReactFileReader
                                        fileTypes={[".jpg", ".jpeg", ".png"]}
                                        base64={true}
                                        multipleFiles={false}
                                        handleFiles={(files) =>
                                          this.handleAdditionalFileChange(
                                            files,
                                            index
                                          )
                                        }
                                      >
                                        <FontAwesomeIcon icon={faUpload} />
                                      </ReactFileReader>
                                    </div>
                                    {field.image && (
                                      <div>
                                        <img
                                          src={field.image}
                                          alt={`task ${index} image`}
                                          style={{
                                            width: "75px",
                                            height: "50px",
                                          }}
                                          // Open the popup for the clicked image
                                          onClick={() =>
                                            this.openAdditionalFieldImagePopup(
                                              index
                                            )
                                          }
                                        />
                                      </div>
                                    )}
                                    {this.state.selectedAdditionalImageIndex !==
                                      -1 && (
                                      <Modal
                                        isOpen={
                                          this.state
                                            .selectedAdditionalImageIndex !== -1
                                        }
                                        onRequestClose={() =>
                                          this.setState({
                                            selectedAdditionalImageIndex: -1,
                                          })
                                        }
                                        contentLabel="Image Modal"
                                        style={{
                                          content: {
                                            position: "absolute",
                                            top: "50%",
                                            left: "50%",
                                            transform: "translate(-50%, -50%)",
                                            width: "1000px",
                                            height: "500px",
                                          },
                                        }}
                                      >
                                        <button
                                          onClick={() =>
                                            this.setState({
                                              selectedAdditionalImageIndex: -1,
                                            })
                                          }
                                          style={{
                                            position: "absolute",
                                            top: "10px",
                                            right: "10px",
                                            fontSize: "20px",
                                            cursor: "pointer",
                                            border: "none",
                                            background: "transparent",
                                            outline: "none",
                                          }}
                                        >
                                          X
                                        </button>
                                        {this.state.additionalFields[
                                          this.state
                                            .selectedAdditionalImageIndex
                                        ].image && (
                                          <img
                                            src={
                                              this.state.additionalFields[
                                                this.state
                                                  .selectedAdditionalImageIndex
                                              ].image
                                            }
                                            alt="Preview"
                                            style={{
                                              width: "900px",
                                              height: "450px",
                                            }}
                                          />
                                        )}
                                      </Modal>
                                    )}
                                    {index != 0 && (
                                      <>
                                        <div className="text-right">
                                          <button
                                            type="button"
                                            style={{
                                              marginLeft: "14px",
                                              height: "45px",
                                              width: "45px",
                                              marginTop: "7px",
                                            }}
                                            onClick={() =>
                                              this.removeTask(index)
                                            }
                                          >
                                            <i
                                              style={{ color: "red" }}
                                              className="feather icon-minus-circle"
                                            />
                                          </button>
                                        </div>
                                      </>
                                    )}
                                    {index ===
                                      this.state.additionalFields.length -
                                        1 && (
                                      <div className="text-right">
                                        <button
                                          type="button"
                                          className="btn btn-primary btn-sm button"
                                          style={{
                                            marginLeft: "12px",
                                            height: "45px",
                                            width: "45px",
                                            fontSize: "24px",
                                          }}
                                          onClick={this.handleAddButtonClick}
                                        >
                                          +
                                        </button>
                                      </div>
                                    )}
                                  </div>
                                )
                              )}

                              <div
                                className="row"
                                style={{ marginTop: "40px" }}
                              >
                                <div className="">
                                  <button
                                    type="button"
                                    className="btn btn-primary btn-sm button"
                                    style={{
                                      marginLeft: "400px",
                                      height: "45px",
                                      width: "65px",
                                      fontSize: "14px",
                                    }}
                                    onClick={this.saveTask}
                                  >
                                    Save
                                  </button>
                                  <button
                                    type="button"
                                    className="btn btn-primary btn-sm button"
                                    disabled={isButtonDisabled}
                                    style={{
                                      height: "45px",
                                      width: "95px",
                                      fontSize: "14px",
                                      backgroundColor: "green",
                                      border: "none",
                                    }}
                                    onClick={this.addTask}
                                  >
                                    {isButtonDisabled
                                      ? "Submitting..."
                                      : "Submit"}
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : this.state.role_id === 1 ? (
          <Adminscreen1 history={undefined} />
        ) : (
          <Hrscreen />
        )}
      </>
    );
  }
}

export default Dashboard;
