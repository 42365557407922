import * as React from "react";
import "./AdminScreen.css";
import { callApi } from "../../../utils/api";
import "react-flexy-table/dist/index.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendar,
  faFilter,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import * as XLSX from "xlsx";
interface AllProps {
  history: any;
}

interface User {
  id: number;
  name: string;
}

interface AllState {
  selectedOption: string;
  isCalendarVisible: boolean;
  startDate: Date;
  endDate: Date;
  filterQuery: string;
  searchQuery: string;
  activeTab: string;
  activeTabContent: boolean;
  allTaskStatus: {
    task_date: Date;
    name: string;
    task: string;
    task_status: string;
    git_id: string;
    url: string;
    comments: string;
    no_review: string;
    review_by: string;
  }[];
  allTaskStatusadmin: {}[];
  allUser: User[];
  totalUser: User[];
  selectedDeveloperId: number;
  formattedFromDate: string;
  formattedToDate: string;
  fromDateInputValue: string;
  toDateInputValue: string;
  selectedAttachmentUrl: string;
  isAttachmentModalOpen: boolean;
  allRole: any[];
  selectedRole: Number;
  selectedRoleName: string;
}
export class ExcelService extends React.Component {
  static exportToExcel(data, fileName) {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(data);
    const workbook: XLSX.WorkBook = {
      Sheets: { data: worksheet },
      SheetNames: ["data"],
    };
    const excelBuffer: any = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });

    this.saveExcelFile(excelBuffer, fileName);
  }

  static saveExcelFile(excelBuffer, fileName) {
    const dataBlob = new Blob([excelBuffer], {
      type: "application/octet-stream",
    });
    const dataUrl = URL.createObjectURL(dataBlob);
    const downloadLink = document.createElement("a");
    downloadLink.href = dataUrl;
    downloadLink.download = `${fileName}.xlsx`;
    downloadLink.click();
    URL.revokeObjectURL(dataUrl);
  }
}

class Adminscreen1 extends React.Component<AllProps, AllState> {
  state: any = {
    selectedOption: "",
    isCalendarVisible: false,
    startDate: new Date(),
    endDate: new Date(),
    filterQuery: "",
    searchQuery: "",
    activeTab: "Daily",
    activeTabContent: "Developer",
    allTaskStatus: [],
    allTaskStatusadmin: [],
    allUser: [],
    selectedDeveloperId: 0,
    formattedFromDate: "",
    formattedToDate: "",
    fromDateInputValue: "",
    toDateInputValue: "",
    isModalOpen: false,
    selectedAttachmentUrl: "", // Initialize this with the URL of the selected attachment
    isAttachmentModalOpen: false,
    data: [],
    allRole: [],
    totalUser: [],
    selectedRole: "",
    selectedRoleName: "",
  };

  componentDidMount() {
    this.getUser();
    this.getDailyDeveloperTasks();
    this.getRole();
  }

  handleAttachmentClick = (attachmentUrl) => {
    this.setState({
      selectedAttachmentUrl: attachmentUrl,
      isAttachmentModalOpen: true,
    });
  };

  handleCloseModal = () => {
    this.setState({
      isAttachmentModalOpen: false,
    });
  };
  getUser() {
    callApi("GET", "user_get.php")
      .then((res) => res.data)
      .then((response) => {
        this.setState({ allUser: response.data, totalUser: response.data });
        console.log("allUser", this.state.allUser);
      })
      .catch((err) => {
        console.log(err);
      });
  }
  getRole() {
    callApi("GET", "role_get.php")
      .then((res) => res.data)
      .then((response) => {
        const filteredRoles = response.data.filter(
          (role) => role.role !== "Admin" && role.role !== "HR"
        );
        this.setState({ allRole: filteredRoles });
        console.log("allRole", this.state.allRole);
      })
      .catch((err) => {});
  }

  handleDeveloperSelect = (developerId: number) => {
    if (developerId > 0) {
      this.setState({ selectedDeveloperId: developerId }, () => {
        if (this.state.activeTab === "Daily") {
          this.getDeveloperTasks();
        } else if (this.state.activeTab === "Weekly") {
          this.getDeveloperTasksForWeek();
        } else {
          this.getMonthlyDeveloperTasks(
            this.state.startDate,
            this.state.endDate
          );
        }
      });
    } else {
      this.setState({ selectedDeveloperId: 0, allTaskStatus: [] });
    }
  };

  //Before selecting developer from option
  getDailyDeveloperTasks = () => {
    const date = new Date();
    const formattedDate = moment(date).format("DD-MM-yyyy");
    let req = {
      date: formattedDate,
    };
    callApi("POST", "admin_task_get.php", req)
      .then((response) => {
        this.setState({ allTaskStatusadmin: response.data.data });
        console.log(this.state.allTaskStatusadmin);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  getWeeklyDeveloperTasks = () => {
    const today = new Date();
    const from = new Date(today);
    from.setDate(from.getDate() - today.getDay());
    const formattedFrom = moment(from).format("DD-MM-yyyy");
    const formattedTo = moment(today).format("DD-MM-yyyy");
    this.setState({
      formattedFromDate: formattedFrom,
      formattedToDate: formattedTo,
    });
    const req = {
      date: formattedFrom,
      todate: formattedTo,
    };
    callApi("POST", "admin_task_get.php", req)
      .then((response) => {
        this.setState({ allTaskStatusadmin: response.data.data });
        console.log(this.state.allTaskStatusadmin);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  getMonthlyDeveloperTasks = (fromDate: Date, toDate: Date) => {
    const formattedFromDate = moment(fromDate).format("DD-MM-yyyy");
    const formattedToDate = moment(toDate).format("DD-MM-yyyy");
    const req = {
      emp_id: this.state.selectedDeveloperId,
      date: formattedFromDate,
      todate: formattedToDate,
    };
    callApi("POST", "admin_task_get.php", req)
      .then((response) => {
        this.setState({ allTaskStatusadmin: response.data.data });
        console.log(this.state.allTaskStatusadmin);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  //After selecting developer from option
  getDeveloperTasks = () => {
    const date = new Date();
    const formattedDate = moment(date).format("DD-MM-yyyy");
    let req = {
      emp_id: this.state.selectedDeveloperId,
      date: formattedDate,
    };
    callApi("POST", "admin_task_get.php", req)
      .then((response) => {
        this.setState({ allTaskStatusadmin: response.data.data.reverse() });
        console.log(this.state.allTaskStatusadmin);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  getDeveloperTasksForWeek = () => {
    const today = new Date();
    const from = new Date(today);
    from.setDate(from.getDate() - today.getDay());
    const formattedFrom = moment(from).format("DD-MM-yyyy");
    const formattedTo = moment(today).format("DD-MM-yyyy");
    this.setState({
      formattedFromDate: formattedFrom,
      formattedToDate: formattedTo,
    });
    const req = {
      emp_id: this.state.selectedDeveloperId,
      date: formattedFrom,
      todate: formattedTo,
    };
    callApi("POST", "admin_task_get.php", req)
      .then((response) => {
        this.setState({ allTaskStatusadmin: response.data.data.reverse() });
        console.log(this.state.allTaskStatusadmin);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  getDeveloperTasksForMonth = (fromDate: Date, toDate: Date) => {
    const formattedFromDate = moment(fromDate).format("DD-MM-yyyy");
    const formattedToDate = moment(toDate).format("DD-MM-yyyy");
    const req = {
      emp_id: this.state.selectedDeveloperId,
      date: formattedFromDate,
      todate: formattedToDate,
    };
    callApi("POST", "admin_task_get.php", req)
      .then((response) => {
        this.setState({ allTaskStatusadmin: response.data.data.reverse() });
        console.log(this.state.allTaskStatusadmin);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  handleActiveTabChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedRole = Number(e.target.value);
    const selectedRoleName = e.target.options[e.target.selectedIndex].text;
    this.setState({ selectedRole, selectedRoleName }, () => {
      const ForSelectedRole = this.state.totalUser.filter(
        (user) => user.designation == selectedRole
      );
      this.setState({ allUser: ForSelectedRole });
      console.log("filter user", ForSelectedRole);
    });
    this.getDailyDeveloperTasks();
    this.getDailyDeveloperTasks();
  };

  handleTabChange = (tab: string) => {
    console.log(tab);
    if (tab === "Monthly") {
      this.setState({
        activeTab: tab,
        isCalendarVisible: true,
        allTaskStatusadmin: [],
        fromDateInputValue: "",
        toDateInputValue: "",
      });
    } else if (tab === "Weekly") {
      this.setState({ activeTab: tab, isCalendarVisible: false });
      if (this.state.selectedDeveloperId != 0) {
        this.getDeveloperTasksForWeek();
      } else {
        this.getWeeklyDeveloperTasks();
      }
    } else {
      this.setState({ activeTab: tab, isCalendarVisible: false });
      if (this.state.selectedDeveloperId != 0) {
        this.getDeveloperTasks();
      } else {
        this.getDailyDeveloperTasks();
      }
    }
  };

  handleBoxClick = () => {
    this.setState({ isCalendarVisible: true });
  };

  updateToDateInputValue = (date: Date) => {
    const formattedDate = moment(date).format("DD/MM/yyyy");
    this.setState({ toDateInputValue: formattedDate });
  };

  updateFromDateInputValue = (date: Date) => {
    const formattedDate = moment(date).format("DD/MM/yyyy");
    this.setState({ fromDateInputValue: formattedDate });
  };

  handleFromDateChange = (date: Date) => {
    this.setState({ startDate: date }, () => {
      this.updateFromDateInputValue(date);
      this.updateToDateMinDate(date);
    });
  };

  updateToDateMinDate = (date: Date) => {
    this.setState({ endDate: date });
  };

  handleToDateChange = (date: Date) => {
    this.setState({ endDate: date }, () => {
      this.updateToDateInputValue(date);
    });
    if (this.state.selectedDeveloperId != 0) {
      this.getDeveloperTasksForMonth(this.state.startDate, this.state.endDate);
    } else {
      this.getMonthlyDeveloperTasks(this.state.startDate, this.state.endDate);
    }
  };
  handleExportToCSV = () => {
    const { allTaskStatusadmin } = this.state;

    let allTasks: {
      date: string;
      task: string;
      status: string;
      gitid: string;
      url: string;
      comments: string;
      name: string;
    }[] = [];

    allTaskStatusadmin.forEach((item) => {
      if (item.tasks && item.tasks.length > 0) {
        item.tasks.forEach((taskItem) => {
          const task = taskItem.task || [];
          const status = taskItem.status || [];
          const gitid = taskItem.gitid || [];
          const url = taskItem.url || [];
          const comments = taskItem.comments || [];
          const name = taskItem.name || "";

          task.forEach((taskValue, index) => {
            const statusValue = status[index]?.value || "";
            const gitidValue = gitid[index]?.value || "";
            const urlValue = url[index]?.value || "";
            const commentValue = comments[index]?.value || "";

            allTasks.push({
              date: item.date,
              task: taskValue.value || "",
              status: statusValue,
              gitid: gitidValue,
              url: urlValue,
              comments: commentValue,
              name: name,
            });
          });
        });
      }
    });

    // Grouping tasks by date
    const groupedTasks = allTasks.reduce((acc, task) => {
      if (!acc[task.date]) {
        acc[task.date] = [];
      }
      acc[task.date].push(task);
      return acc;
    }, {});

    const csvContent =
      "data:text/csv;charset=utf-8," +
      [
        "date",
        "name",
        "task",
        "task_status",
        "task_gitid",
        "task_url",
        "task_comments",
      ].join(",") +
      "\n" +
      Object.keys(groupedTasks)
        .map((date) => {
          const tasksForDate = groupedTasks[date];
          const tasksCSV = tasksForDate
            .map(
              (task) =>
                `"${task.date}","${task.name}","${task.task}","${task.status}","${task.gitid}","${task.url}","${task.comments}"`
            )
            .join("\n");
          return tasksCSV;
        })
        .join("\n\n");

    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "Taskstatus_Admin.csv");
    document.body.appendChild(link);
    link.click();
  };

  render() {
    const { isCalendarVisible, searchQuery, activeTab, activeTabContent } =
      this.state;
    const userDetails = sessionStorage.getItem("userdetails");
    const userName = userDetails ? JSON.parse(userDetails)["name"] : "";

    return (
      <section className="pcoded-main-container">
        <div className="pcoded-wrapper">
          <div className="pcoded-content">
            <div className="pcoded-inner-content">
              <div className="main-body">
                <div className="page-wrapper">
                  <div className="row">
                    <div className="col-sm-12">
                      <div className="card card-wrapper">
                        <div
                          className="card-header"
                          style={{ padding: "45px 40px 30px 40px" }}
                        >
                          <h2>Hi {userName}</h2>
                          <ul className="nav nav-underline">
                            <select
                              className="form-control"
                              // value={activeTabContent}
                              // value={this.state.selectedRole}
                              onChange={this.handleActiveTabChange}
                              style={{ height: "50px", width: "300px" }}
                            >
                              <option value={0}>Select Role</option>
                              {this.state.allRole.map((role) => (
                                <option key={role.id} value={role.id}>
                                  {role.role}
                                </option>
                              ))}
                            </select>
                          </ul>
                          <div>
                            {activeTabContent === "Developer" && (
                              <div>
                                <div
                                  className="row"
                                  style={{ marginTop: "4px" }}
                                >
                                  <ul className="nav nav-underline">
                                    <li className="nav-item">
                                      <a
                                        className={`nav-link ${
                                          activeTab === "Daily" ? "active" : ""
                                        }`}
                                        onClick={() =>
                                          this.handleTabChange("Daily")
                                        }
                                        style={{
                                          fontSize: "16px",
                                          paddingLeft: "14px",
                                          color:
                                            activeTab === "Daily"
                                              ? "green"
                                              : "black",
                                        }}
                                        aria-current="page"
                                        href="#"
                                      >
                                        Daily
                                      </a>
                                    </li>
                                    <li className="nav-item">
                                      <a
                                        className={`nav-link ${
                                          activeTab === "Weekly" ? "active" : ""
                                        }`}
                                        onClick={() =>
                                          this.handleTabChange("Weekly")
                                        }
                                        style={{
                                          fontSize: "16px",
                                          color:
                                            activeTab === "Weekly"
                                              ? "green"
                                              : "black",
                                        }}
                                        href="#"
                                      >
                                        Weekly
                                      </a>
                                    </li>
                                    <li className="nav-item">
                                      <a
                                        className={`nav-link ${
                                          activeTab === "Monthly"
                                            ? "active"
                                            : ""
                                        }`}
                                        style={{
                                          fontSize: "16px",
                                          color:
                                            activeTab === "Monthly"
                                              ? "green"
                                              : "black",
                                          paddingLeft: "14px",
                                        }}
                                        onClick={() =>
                                          this.handleTabChange("Monthly")
                                        }
                                        href="#"
                                      >
                                        Monthly
                                      </a>
                                    </li>
                                  </ul>
                                </div>
                                <div className="row">
                                  {isCalendarVisible &&
                                    activeTab === "Monthly" && (
                                      <div
                                        style={{
                                          display: "flex",
                                          cursor: "pointer",
                                          marginTop: "10px",
                                          marginLeft: "20px",
                                        }}
                                        onClick={this.handleBoxClick}
                                      >
                                        <div
                                          style={{
                                            display: "flex",
                                            alignItems: "center",
                                            border: "1px solid grey",
                                            height: "40px",
                                            width: "132px",
                                          }}
                                        >
                                          <FontAwesomeIcon
                                            icon={faCalendar}
                                            style={{
                                              marginRight: "5px",
                                              height: "20px",
                                              width: "30px",
                                              marginLeft: "10px",
                                            }}
                                          />
                                          <DatePicker
                                            className="date-wrapper"
                                            onChange={this.handleFromDateChange}
                                            placeholderText={
                                              this.state.fromDateInputValue ||
                                              "DD/MM/YYYY"
                                            }
                                            dateFormat="dd/MM/yyyy"
                                          />
                                        </div>
                                        <div
                                          style={{
                                            display: "flex",
                                            alignItems: "center",
                                            border: "1px solid grey",
                                            height: "40px",
                                            width: "132px",
                                            marginLeft: "20px",
                                          }}
                                        >
                                          <FontAwesomeIcon
                                            icon={faCalendar}
                                            style={{
                                              marginRight: "5px",
                                              height: "20px",
                                              width: "30px",
                                              marginLeft: "10px",
                                            }}
                                          />
                                          <DatePicker
                                            className="date-wrapper"
                                            onChange={this.handleToDateChange}
                                            placeholderText={
                                              this.state.toDateInputValue ||
                                              "DD/MM/YYYY"
                                            }
                                            dateFormat="dd/MM/yyyy"
                                            minDate={this.state.startDate}
                                          />
                                        </div>
                                      </div>
                                    )}
                                  <select
                                    className="form-control"
                                    value={this.state.selectedDeveloperId}
                                    style={{
                                      width: "220px",
                                      margin: "10px 0px 20px 20px",
                                    }}
                                    onChange={(e) =>
                                      this.handleDeveloperSelect(
                                        Number(e.target.value)
                                      )
                                    }
                                  >
                                    <option value={0}>
                                      Select {this.state.selectedRoleName}
                                    </option>
                                    {this.state.selectedRole !== "" &&
                                      this.state.allUser.map((user) => (
                                        <option key={user.id} value={user.id}>
                                          {user.name}
                                        </option>
                                      ))}
                                  </select>
                                  <button
                                    className="btn btn-bg btn-primary app-bg semiBold-font add-user"
                                    style={{
                                      marginRight: "2%",
                                      height: "49px",
                                      marginLeft: "28px",
                                    }}
                                    onClick={this.handleExportToCSV}
                                  >
                                    CSV Report
                                  </button>
                                </div>
                              </div>
                            )}
                            {/* Inside the render method */}
                            {this.state.allTaskStatusadmin &&
                            this.state.allTaskStatusadmin.length > 0 ? (
                              <div className="container">
                                {this.state.allTaskStatusadmin.map(
                                  (task, index) => (
                                    <div className="row" key={index}>
                                      <div
                                        className="col-md-2"
                                        style={{
                                          background: "#aaecc6",
                                          color: "black",
                                          padding: "5%",
                                        }}
                                      >
                                        {task.date}
                                      </div>
                                      <div
                                        className="col-md-10"
                                        style={{ padding: "3% 3% 0% 3%" }}
                                      >
                                        {task.tasks.map((value) => (
                                          <div className="row" key={value.name}>
                                            <div className="col-md-3">
                                              <h6>{value.name}</h6>
                                              {/* Add other user-specific details */}
                                            </div>
                                            <div className="col-md-9">
                                              <div
                                                className="row header-row"
                                                style={{ paddingLeft: "5px" }}
                                              >
                                                <div className="col-md-2">
                                                  Task
                                                </div>
                                                <div className="col-md-2">
                                                  Status
                                                </div>
                                                {this.state.selectedRoleName ===
                                                  "Test Engineer" && (
                                                  <div className="col-md-2">
                                                    URL
                                                  </div>
                                                )}
                                                {this.state.selectedRoleName ===
                                                  "Software Developer" && (
                                                  <div className="col-md-2">
                                                    Git
                                                  </div>
                                                )}
                                                <div className="col-md-2">
                                                  Attach
                                                </div>
                                                <div className="col-md-2">
                                                  Comment
                                                </div>
                                              </div>

                                              {value.task.map(
                                                (taskValue, innerIndex) => (
                                                  <div
                                                    className="row"
                                                    key={innerIndex}
                                                  >
                                                    <div className="">
                                                      {innerIndex + 1}
                                                    </div>
                                                    <div className="col-md-2">
                                                      {taskValue.value}
                                                    </div>
                                                    <div className="col-md-2">
                                                      {/* Show the corresponding status */}
                                                      {
                                                        value.status[innerIndex]
                                                          ?.value
                                                      }
                                                    </div>
                                                    {this.state
                                                      .selectedRoleName ===
                                                      "Software Developer" && (
                                                      <div className="col-md-2">
                                                        {
                                                          value.gitid[
                                                            innerIndex
                                                          ]?.value
                                                        }
                                                      </div>
                                                    )}
                                                    {this.state
                                                      .selectedRoleName ===
                                                      "Test Engineer" && (
                                                      <div className="col-md-2">
                                                        {
                                                          value.url[innerIndex]
                                                            ?.value
                                                        }
                                                      </div>
                                                    )}
                                                    <div className="col-md-2">
                                                      {/* Show the corresponding attachment */}
                                                      <img
                                                        src={
                                                          value.attach[
                                                            innerIndex
                                                          ]?.value
                                                        }
                                                        alt="taskimages"
                                                        width="50px"
                                                        height="50px"
                                                      />
                                                    </div>
                                                    <div className="col-md-2">
                                                      {/* Show the corresponding comment */}
                                                      {
                                                        value.comments[
                                                          innerIndex
                                                        ]?.value
                                                      }
                                                    </div>
                                                  </div>
                                                )
                                              )}

                                              <div
                                                className="row review-row"
                                                style={{ marginTop: "3%" }}
                                              >
                                                <div className="col-md-3">
                                                  Review
                                                </div>
                                                <div className="col-md-9">
                                                  <span>{value.review}</span>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        ))}
                                      </div>
                                    </div>
                                  )
                                )}
                              </div>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="modal" id="edit-user" role="dialog" aria-hidden="true">
          <div className="modal-dialog modal-xl">
            <div className="modal-content">
              <div className="modal-body" style={{ height: "700px" }}>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
                <h4>Attachments</h4>
                <div
                  className="model-xl"
                  style={{ height: "200px", position: "relative" }}
                >
                  <div className="card-body">
                    <div className="form-group">
                      <img
                        src={this.state.selectedAttachmentUrl}
                        alt="Attachment url"
                        style={{ height: "500", width: "1000px" }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
export default Adminscreen1;
