import React from "react";
import { Link } from "react-router-dom";
import Table from "../user/Table";
import { callApi } from "../../utils/api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendar,
  faPaperclip,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { Dropdown, DropdownButton } from "react-bootstrap";
interface AllProps {
  history: any;
}

interface Log {
  date: string;
  name: string;
  in_time: string;
  out_time: string;
  total_hours: number;
}

interface User {
  id: number;
  name: string;
}

interface Logdetailsscreenstate {
  isCalendarVisible: boolean;
  startDate: Date;
  endDate: Date;
  searchQuery: string;
  activeTab: string;
  activeTabContent: string;
  alllogs: Log[];
  emp_id: number;
  fromDate: Date;
  fromDateInputValue: string;
  toDateString: string;
  toDateInputValue: string;
  allUser: User[];
  selectedDeveloperId: number;
  role_id: number;
  user_id: number;
}

class LogDetails extends React.Component<AllProps> {
  state: Logdetailsscreenstate = {
    isCalendarVisible: false,
    startDate: new Date(),
    endDate: new Date(),
    searchQuery: "",
    activeTab: "Daily",
    activeTabContent: "Developer",
    alllogs: [],
    emp_id: 0,
    fromDate: new Date(),
    fromDateInputValue: "",
    toDateString: moment(new Date()).format("DD/MM/yyyy"),
    toDateInputValue: "",
    allUser: [],
    selectedDeveloperId: 0,
    role_id: 0,
    user_id: 0,
  };

  componentDidMount() {
    this.getUser();
    const userDetails = sessionStorage.getItem("userdetails");
    let userId = userDetails ? JSON.parse(userDetails).id : null;
    if (userDetails) {
      const parsedUserDetails = JSON.parse(userDetails);
      userId = parseInt(parsedUserDetails.id);
      const designationId = parseInt(parsedUserDetails.designation);
      this.setState({ user_id: userId, role_id: designationId }, () => {
        this.getRologs();
      });
    }
  }

  getUser() {
    callApi("GET", "user_get.php")
      .then((res) => res.data)
      .then((response) => {
        const filteredUsers = response.data.filter(
          (user) => user.designation !== "1" && user.designation !== "10"
        );
        this.setState({ allUser: filteredUsers });
        console.log("allUser", this.state.allUser);
      })
      .catch((err) => {});
  }

  handleActiveTabChange = (tab: string) => {
    this.setState({ activeTab: "Daily", activeTabContent: tab });
  };

  handleTabChange = (tab: string) => {
    console.log(tab);
    if (tab === "Monthly") {
      this.setState({
        activeTab: tab,
        isCalendarVisible: true,
        alllogs: [],
        fromDateInputValue: "",
        toDateInputValue: "",
      });
    } else if (tab === "Weekly") {
      this.setState({ activeTab: tab, isCalendarVisible: false });
      this.getlogsForWeek();
    } else {
      this.setState({ activeTab: tab, isCalendarVisible: false });
      this.getRologs();
    }
  };

  handleUserSelect = (event) => {
    const selectedUserId = event.target.value;
    this.setState({ selectedDeveloperId: parseInt(selectedUserId) }, () => {
      this.fetchLogDataForSelectedUser();
      this.handleTabChange("Daily");
    });
  };

  fetchLogDataForSelectedUser = () => {
    const selectedUserId = this.state.selectedDeveloperId;
    if (
      selectedUserId &&
      this.state.user_id !== 1 &&
      this.state.user_id !== 2
    ) {
      const req = {
        user_id: selectedUserId,
      };

      callApi("POST", "log_admin_get.php", req)
        .then((res) => res.data)
        .then((response) => {
          this.setState({ alllogs: response.data });
          console.log("status", this.state.alllogs);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      console.log("No user selected");
    }
  };

  handleBoxClick = () => {
    this.setState({ isCalendarVisible: true });
  };

  handleFromDateChange = (date: Date) => {
    this.setState({ startDate: date }, () => {
      this.updateFromDateInputValue(date);
    });
  };

  updateFromDateInputValue = (date: Date) => {
    const formattedDate = moment(date).format("DD/MM/yyyy");
    this.setState({ fromDateInputValue: formattedDate });
  };

  handleToDateChange = (date: Date) => {
    this.setState({ endDate: date }, () => {
      this.updateToDateInputValue(date);
      this.getlogsForMonth(this.state.startDate, this.state.endDate);
    });
  };

  handleDeveloperSelect = (developerId: number) => {
    this.setState({ selectedDeveloperId: developerId });
  };

  updateToDateInputValue = (date: Date) => {
    const formattedDate = moment(date).format("DD/MM/yyyy");
    this.setState({ toDateInputValue: formattedDate });
  };
  updateToDateMinDate = (date: Date) => {
    this.setState({ endDate: date });
  };
  getRologs = () => {
    const date = new Date();
    const formattedDate = moment(date).format("DD-MM-yyyy");
    if (this.state.role_id === 1 || this.state.role_id === 2) {
      const req = {
        user_id: this.state.selectedDeveloperId,
        date: formattedDate,
      };

      callApi("POST", "log_admin_get.php", req)
        .then((res) => res.data)
        .then((response) => {
          this.setState({ alllogs: response.data });
          console.log("status", this.state.alllogs);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      const req = {
        user_id: this.state.user_id,
        date: formattedDate,
      };

      callApi("POST", "log_get.php", req)
        .then((res) => res.data)
        .then((response) => {
          this.setState({ alllogs: response.data });
          console.log("status", this.state.alllogs);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  getlogsForWeek = () => {
    const today = new Date();
    const from = new Date(today);
    from.setDate(from.getDate() - today.getDay());
    const formattedFrom = moment(from).format("DD-MM-yyyy");
    const formattedTo = moment(today).format("DD-MM-yyyy");
    if (this.state.role_id === 1 || this.state.role_id === 2) {
      const req = {
        user_id: this.state.selectedDeveloperId,
        date: formattedFrom,
        todate: formattedTo,
      };

      callApi("POST", "log_admin_get.php", req)
        .then((res) => res.data)
        .then((response) => {
          this.setState({ alllogs: response.data });
          console.log("status", this.state.alllogs);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      // Use the user_id of the logged-in user for other users
      const req = {
        user_id: this.state.user_id,
        date: formattedFrom,
        todate: formattedTo,
      };

      callApi("POST", "log_get.php", req)
        .then((res) => res.data)
        .then((response) => {
          this.setState({ alllogs: response.data });
          console.log("status", this.state.alllogs);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  getlogsForMonth = (fromDate: Date, toDate: Date) => {
    const formattedFromDate = moment(fromDate).format("DD-MM-yyyy");
    const formattedToDate = moment(toDate).format("DD-MM-yyyy");
    if (this.state.user_id === 1 || this.state.user_id === 2) {
      const req = {
        user_id: this.state.selectedDeveloperId,
        date: formattedFromDate,
        todate: formattedToDate,
      };

      callApi("POST", "log_admin_get.php", req)
        .then((res) => res.data)
        .then((response) => {
          this.setState({ alllogs: response.data });
          console.log("status", this.state.alllogs);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      // Use the user_id of the logged-in user for other users
      const req = {
        user_id: this.state.user_id,
        date: formattedFromDate,
        todate: formattedToDate,
      };

      callApi("POST", "log_get.php", req)
        .then((res) => res.data)
        .then((response) => {
          this.setState({ alllogs: response.data });
          console.log("status", this.state.alllogs);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  calculatehours = (in_time, out_time) => {
    const format = "HH:mm:ss";
    if (out_time) {
      const startTime = moment(in_time, format);
      const endTime = moment(out_time, format);
      const duration = moment.duration(endTime.diff(startTime));

      const hours = Math.floor(duration.asHours());
      const minutes = duration.minutes();
      const seconds = duration.seconds();

      const formattedHours = hours.toString().padStart(2, "0");
      const formattedMinutes = minutes.toString().padStart(2, "0");
      const formattedSeconds = seconds.toString().padStart(2, "0");

      return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
    } else {
      return "00:00:00";
    }
  };

  handleEdit = (rowData) => {
    console.log("Edit action clicked for row:", rowData);
  };

  render() {
    const data = this.state.alllogs;
    let columns = [
      {
        Header: "Date",
        accessor: "date",
        sort: true,
      },
      {
        Header: "Name",
        accessor: "name",
        sort: true,
      },
      {
        Header: "Checkin",
        accessor: "in_time",
        sort: true,
      },
      {
        Header: "Checkout",
        accessor: "out_time",
        sort: false,
      },
      {
        Header: "Remarks",
        accessor: "checkout_reason",
        sort: false,
      },
      {
        Header: "Total.Hrs",
        accessor: "total_hours",
        sort: false,
        Cell: ({ row }) =>
          this.calculatehours(row.original.in_time, row.original.out_time),
      },
    ];

    const sizePerPageList = [
      {
        text: "5",
        value: 5,
      },
      {
        text: "10",
        value: 10,
      },
      {
        text: "25",
        value: 25,
      },
      {
        text: "All",
        value: data.length,
      },
    ];

    const { isCalendarVisible, activeTab, activeTabContent } = this.state;
    const userDetails = sessionStorage.getItem("userdetails");
    const userName = userDetails ? JSON.parse(userDetails)["name"] : "";

    return (
      <section className="pcoded-main-container">
        <div className="pcoded-wrapper">
          <div className="pcoded-content">
            <div className="pcoded-inner-content">
              <div className="main-body">
                <div className="page-wrapper">
                  <div className="row">
                    <div className="col-sm-12">
                      <div className="card card-wrapper">
                        <div
                          className="card-header"
                          style={{ padding: "45px 40px 30px 40px" }}
                        >
                          <h2 style={{ marginLeft: "30px" }}>Hi {userName}</h2>
                          <div>
                            {(this.state.role_id === 2 ||
                              this.state.role_id === 1) && (
                              <div>
                                <select
                                  onChange={this.handleUserSelect}
                                  style={{
                                    height: "30px",
                                    width: "200px",
                                    marginLeft: "30px",
                                  }}
                                >
                                  <option value="">Select User</option>
                                  {this.state.allUser.map((user) => (
                                    <option key={user.id} value={user.id}>
                                      {user.name}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            )}
                            {activeTabContent === "Developer" && (
                              <div>
                                <div
                                  className="row"
                                  style={{
                                    marginTop: "4px",
                                    marginLeft: "20px",
                                  }}
                                >
                                  <ul className="nav nav-underline">
                                    <li className="nav-item">
                                      <a
                                        className={`nav-link ${
                                          activeTab === "Daily" ? "active" : ""
                                        }`}
                                        onClick={() =>
                                          this.handleTabChange("Daily")
                                        }
                                        style={{
                                          fontSize: "16px",
                                          paddingLeft: "14px",
                                          color:
                                            activeTab === "Daily"
                                              ? "green"
                                              : "black",
                                        }}
                                        aria-current="page"
                                        href="#"
                                      >
                                        Daily
                                      </a>
                                    </li>
                                    <li className="nav-item">
                                      <a
                                        className={`nav-link ${
                                          activeTab === "Weekly" ? "active" : ""
                                        }`}
                                        onClick={() =>
                                          this.handleTabChange("Weekly")
                                        }
                                        style={{
                                          fontSize: "16px",
                                          color:
                                            activeTab === "Weekly"
                                              ? "green"
                                              : "black",
                                        }}
                                        href="#"
                                      >
                                        Weekly
                                      </a>
                                    </li>
                                    <li className="nav-item">
                                      <a
                                        className={`nav-link ${
                                          activeTab === "Monthly"
                                            ? "active"
                                            : ""
                                        }`}
                                        style={{
                                          fontSize: "16px",
                                          color:
                                            activeTab === "Monthly"
                                              ? "green"
                                              : "black",
                                          paddingLeft: "14px",
                                        }}
                                        onClick={() =>
                                          this.handleTabChange("Monthly")
                                        }
                                        href="#"
                                      >
                                        Monthly
                                      </a>
                                    </li>
                                  </ul>
                                </div>
                                <div>
                                  {isCalendarVisible &&
                                    activeTab === "Monthly" && (
                                      <div
                                        style={{
                                          display: "flex",
                                          cursor: "pointer",
                                          marginTop: "10px",
                                          marginLeft: "20px",
                                        }}
                                        onClick={this.handleBoxClick}
                                      >
                                        <div
                                          style={{
                                            display: "flex",
                                            alignItems: "center",
                                            border: "1px solid grey",
                                            height: "40px",
                                            width: "132px",
                                          }}
                                        >
                                          <FontAwesomeIcon
                                            icon={faCalendar}
                                            style={{
                                              marginRight: "5px",
                                              height: "20px",
                                              width: "30px",
                                              marginLeft: "10px",
                                            }}
                                          />
                                          <DatePicker
                                            className="date-wrapper"
                                            onChange={this.handleFromDateChange}
                                            placeholderText={
                                              this.state.fromDateInputValue ||
                                              "DD/MM/YYYY"
                                            }
                                            dateFormat="dd/MM/yyyy"
                                          />
                                        </div>
                                        <div
                                          style={{
                                            display: "flex",
                                            alignItems: "center",
                                            border: "1px solid grey",
                                            height: "40px",
                                            width: "132px",
                                            marginLeft: "20px",
                                          }}
                                        >
                                          <FontAwesomeIcon
                                            icon={faCalendar}
                                            style={{
                                              marginRight: "5px",
                                              height: "20px",
                                              width: "30px",
                                              marginLeft: "10px",
                                            }}
                                          />
                                          <DatePicker
                                            className="date-wrapper"
                                            onChange={this.handleToDateChange}
                                            placeholderText={
                                              this.state.toDateInputValue ||
                                              "DD/MM/YYYY"
                                            }
                                            dateFormat="dd/MM/yyyy"
                                            minDate={this.state.startDate}
                                          />
                                        </div>
                                      </div>
                                    )}
                                </div>
                                <div className="card-body">
                                  <div className="dt-responsive table-responsive">
                                    <Table
                                      columns={columns}
                                      data={data}
                                      pageSize={25}
                                      sizePerPageList={sizePerPageList}
                                      isSortable={true}
                                      pagination={true}
                                      isSearchable={true}
                                    />
                                  </div>
                                </div>
                                <div
                                  className="modal"
                                  id="edit-user"
                                  role="dialog"
                                  aria-hidden="true"
                                >
                                  <div className="modal-dialog modal-xl">
                                    <div className="modal-content">
                                      <div
                                        className="modal-body"
                                        style={{ height: "700px" }}
                                      >
                                        <button
                                          type="button"
                                          className="close"
                                          data-dismiss="modal"
                                          aria-label="Close"
                                        >
                                          <span aria-hidden="true">
                                            &times;
                                          </span>
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
export default LogDetails;
