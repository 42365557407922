import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import AuthGuard from "./AuthGuard";
import Login from "./components/login/Login";
import Loginone from "./components/login/login_one";
import PrivateRoutes from "./PrivateRoutes";

class Routes extends React.Component {
  public render() {
    return (
      <Router>
        <Switch>
          {/* <Route path="/login" component={Login} /> */}
          <Route path="/login" component={Loginone} />
          <AuthGuard exact component={PrivateRoutes} />
        </Switch>
      </Router>
    );
  }
}
export default Routes;
