import * as React from "react";
import { Link } from "react-router-dom";
import Table from "../user/Table";
import { Dropdown } from "react-bootstrap";
import { callApi } from "../../utils/api";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Select from "react-select";
import moment from "moment";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReactTable from "react-editable-table";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useState } from "react";

interface AllProps {
  history: any;
  match: any;
}

interface Member {
  id: number;
  name: string;
}
interface TaskManagementState {
  allTasks: {
    id: number;
    task: string;
    mintime: string;
    maxtime: string;
    actualtime: string;
    status: string;
    committeddate: string;
    assigned: string;
    support: string;
    remarks: string;
  }[];
  allroles: never[];
  allUser: { id: number; name: string }[];
  taskname: string;
  clientName: string;
  Role: string;
  id: number;
  status: number;
  nextReviewDate: null;
  ReleaseDate: null;
  owner: string;
  members: string;
  user_id: null;
  selecteduser: string;
  username: string;
  selectedMembers: string[];
  mintime: string | null;
  maxtime: string | null;
  actualtime: string | null;
  remarks: string;
  allstatus: { id: number; name: string }[];
  selectedStatus: { id: number; name: string } | null;
  statusCounts: {
    task_status: string;
    total_count: string;
  }[];
  actualtime_count: string;
  min_totaltime: string;
  max_totaltime: string;
  projectname: string;
}

class taskmanagement extends React.Component<AllProps> {
  state = {
    allTasks: [] as {
      id: number;
      task: string;
      mintime: string;
      maxtime: string;
      actualtime: string;
      status: string;
      committeddate: string;
      assigned: string;
      support: string;
      remarks: string;
    }[],
    statusCounts: [] as {
      task_status: string;
      total_count: string;
    }[],
    allroles: [],
    allUser: [] as { id: number; name: string }[],
    allstatus: [] as { id: number; name: string }[],
    taskname: "",
    clientName: "",
    Role: "",
    id: 0,
    status: 0,
    nextReviewDate: null,
    ReleaseDate: null,
    owner: "",
    members: "",
    user_id: null,
    selecteduser: "",
    username: "",
    selectedMembers: [],
    mintime: null,
    maxtime: null,
    actualtime: null,
    remarks: "",
    assigned: "",
    committeddate: null,
    support: "",
    selectedsupport: "",
    selectedStatus: null as { id: number; name: string } | null,
    livecount: 0,
    min_totaltime: "",
    max_totaltime: "",
    actualtime_count: "",
    editMode: false,
    editedTask: "",
    projectname: "",
  };
  componentDidMount() {
    this.getTasks();
    this.getstatus();
    this.getUser();
    this.getstatuscount();
    this.gettotaltime();
    this.getprojects();
    const userDetails = sessionStorage.getItem("userdetails");
    let user_id = userDetails ? JSON.parse(userDetails).id : null;
    this.setState({ user_id: user_id });
  }
  getstatus() {
    callApi("GET", "task_status_get.php")
      .then((res) => res.data)
      .then((response) => {
        const statusArray = response.data.map((user) => ({
          id: user.id,
          name: user.task_status,
        }));
        this.setState({ allstatus: statusArray });
        console.log("status", this.state.allstatus);
      })
      .catch((err) => {});
  }
  getstatuscount() {
    let req = {
      id: this.props.match.params.id,
    };
    callApi("POST", "status_count_get.php", req)
      .then((res) => res.data)
      .then((response) => {
        this.setState({ statusCounts: response.data });
      })
      .catch((err) => {});
  }
  getprojects() {
    callApi("POST", "project_get.php")
      .then((res) => res.data)
      .then((response) => {
        const projects = response.data;
        const projectById = projects.find(
          (project) => project.id === this.props.match.params.id
        );
        const projectname = projectById.projectname;

        this.setState({ projectname });
        console.log("name", this.state.projectname);
      })
      .catch((err) => {});
  }
  gettotaltime() {
    let req = {
      id: this.props.match.params.id,
    };
    callApi("POST", "actualtime_count_get.php", req)
      .then((res) => res.data)
      .then((response) => {
        const totalActualTime = response.data.total_actualtime;
        const minTotalTime = response.data.min_totaltime;
        const maxTotalTime = response.data.max_totaltime;

        const formattedTotalTime = this.formatTimeToHHMM(totalActualTime);
        const formattedMinTime = this.formatTimeToHHMM(minTotalTime);
        const formattedMaxTime = this.formatTimeToHHMM(maxTotalTime);

        this.setState({
          actualtime_count: formattedTotalTime,
          min_totaltime: formattedMinTime,
          max_totaltime: formattedMaxTime,
        });
        console.log("time", this.state.actualtime_count);
        console.log("time", this.state.min_totaltime);

        console.log("time", this.state.max_totaltime);
      })
      .catch((err) => {});
  }

  formatTimeToHHMM(timeString) {
    const timeComponents = timeString.split(":");
    const hours = parseInt(timeComponents[0], 10);
    const minutes = parseInt(timeComponents[1], 10);

    const formattedTime = `${hours.toString().padStart(2, "0")}:${minutes
      .toString()
      .padStart(2, "0")}`;
    return formattedTime;
  }

  renderStatusCountTable() {
    const { statusCounts } = this.state;

    if (!statusCounts || statusCounts.length === 0) {
      return null;
    }

    let statusCountString = "";

    statusCounts.forEach((statusCount, index) => {
      statusCountString += `${statusCount.task_status}: ${statusCount.total_count}`;
      if (index !== statusCounts.length - 1) {
        statusCountString += " \u22EE";
      }
    });

    return (
      <div
        className="container"
        style={{
          border: "1px solid #ccc",
          padding: "10px",
          fontSize: "17px",
          display: "table-cell",
        }}
      >
        <span>{statusCountString}</span>
      </div>
    );
  }
  getUser() {
    // Make an API request to fetch all user names
    callApi("GET", "user_get.php")
      .then((res) => res.data)
      .then((response) => {
        const userArray = response.data.map((user) => ({
          id: user.id,
          name: user.name,
        }));

        this.setState({ allUser: userArray });
      })
      .catch((err) => {
        console.log(err);
      });
  }
  getTasks() {
    let req = {
      id: this.props.match.params.id,
    };
    callApi("POST", "project_task_get.php", req)
      .then((res) => res.data)
      .then((response) => {
        const filteredtask = response.data.filter(
          (project) => project.task_status !== "Live"
        );
        this.setState({ allTasks: filteredtask });
        const live = response.data.filter(
          (project) => project.task_status === "Live"
        );
        this.setState({ livecount: live.length });
      })
      .catch((err) => {
        console.log(err);
      });
  }
  handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      Role: event.target.value,
    });
  };

  handleSubmit = () => {
    if (!this.validateForm()) {
      toast.error("Please fill in all mandatory fields", { autoClose: 3000 });
    }
    const CurrentDate = moment().format("YYYY-MM-DD");
    const {
      taskname,
      mintime,
      maxtime,
      actualtime,
      status,
      committeddate,
      assigned,
      support,
      remarks,
    } = this.state;
    let selectedStatusToSend = this.state.selectedStatus
      ? this.state.selectedStatus.name
      : null;

    // If selectedStatus is not set, use the default status
    if (!this.state.selectedStatus) {
      selectedStatusToSend = this.state.allstatus[0].name;
    }
    const req = {
      project_id: this.props.match.params.id,
      date: CurrentDate,
      task: taskname,
      mintime: mintime,
      maxtime: maxtime,
      actualtime: actualtime,
      status: selectedStatusToSend,
      committeddate: committeddate,
      assigned: assigned,
      support: support,
      remarks: remarks,
    };

    // Call the API
    callApi("POST", "project_task_insert.php", req)
      .then((res) => res.data)
      .then((response) => {
        if (response.success === true) {
          toast.success(response.message, { autoClose: 3000 });
          window.location.reload();
          this.setState({
            allTasks: [...this.state.allTasks, req],
            taskname: "",
            mintime: null,
            maxtime: null,
            actualtime: null,
            status: "",
            committeddate: null,
            assigned: "",
            support: "",
            remarks: "",
          });
        } else {
          toast.error(response.message, { autoClose: 3000 });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  updateProject = () => {
    let req = {
      id: this.state.id,
      Role: this.state.Role,
    };
    callApi("POST", "", req)
      .then((res) => res.data)
      .then((response) => {
        if (response.success == true) {
          toast.success(response.message, { autoClose: 3000 });
        } else {
          toast.error(response.message, { autoClose: 3000 });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  handleUserChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    this.setState({
      selecteduser: event.target.value,
    });
  };

  validateForm = () => {
    const { taskname } = this.state;

    if (!taskname) {
      return false;
    }

    return true;
  };

  handleMinTimeInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    console.log("Min Time Input Value: ", event.target.value);

    this.setState({
      mintime: event.target.value,
    });
  };
  handleMinTimeUpdate = (e: React.ChangeEvent<HTMLInputElement>, row: any) => {
    const { allTasks } = this.state;
    const updatedTasks = [...allTasks];
    const rowIndex = updatedTasks.findIndex(
      (task) => task.id === row.original.id
    );

    const fullTime = e.target.value;
    console.log("Min Time in Table: ", fullTime);

    // Update the mintime value in the corresponding row
    updatedTasks[rowIndex].mintime = fullTime;

    this.setState({
      allTasks: updatedTasks,
    });
  };

  handleMaxTimeInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    console.log("Max Time Input Value: ", event.target.value);

    this.setState({
      maxtime: event.target.value,
    });
  };

  handleMaxTimeUpdate = (e: React.ChangeEvent<HTMLInputElement>, row: any) => {
    const { allTasks } = this.state;
    const updatedTasks = [...allTasks];
    const rowIndex = updatedTasks.findIndex(
      (task) => task.id === row.original.id
    );

    console.log("Max Time in Table: ", e.target.value);

    updatedTasks[rowIndex].maxtime = e.target.value;

    this.setState({
      allTasks: updatedTasks,
    });
  };
  handleActualTimeInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    console.log("Actual Time Input Value: ", event.target.value);

    this.setState({
      actualtime: event.target.value,
    });
  };

  handleActualTimeUpdate = (
    e: React.ChangeEvent<HTMLInputElement>,
    row: any
  ) => {
    const { allTasks } = this.state;
    const updatedTasks = [...allTasks];
    const rowIndex = updatedTasks.findIndex(
      (task) => task.id === row.original.id
    );

    console.log("Actual Time in Table: ", e.target.value);

    updatedTasks[rowIndex].actualtime = e.target.value;

    this.setState({
      allTasks: updatedTasks,
    });
  };

  handleCommittedDateInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    console.log("Committed Date Input Value: ", event.target.value);

    this.setState({
      committeddate: event.target.value,
    });
  };

  sendStatusUpdateToApi = (taskId, selectedOption) => {
    const req = {
      taskId: taskId,
      newStatus: selectedOption.name,
    };

    // Call the API to update the task status
    callApi("POST", "project_task_update.php", req)
      .then((res) => res.data)
      .then((response) => {
        if (response.success === true) {
          toast.success(response.message, { autoClose: 3000 });
          this.getTasks();
          this.getstatuscount();
          this.gettotaltime();
        } else {
          toast.error(response.message, { autoClose: 3000 });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  sendMinTimeUpdateToApi = (taskId, newMinTime) => {
    const req = {
      taskId: taskId,
      newMinTime: newMinTime,
    };
    callApi("POST", "project_task_update.php", req)
      .then((res) => res.data)
      .then((response) => {
        if (response.success === true) {
          toast.success(response.message, { autoClose: 3000 });
          this.getTasks();
          this.gettotaltime();
        } else {
          toast.error(response.message, { autoClose: 3000 });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  sendMaxTimeUpdateToApi = (taskId, newMaxTime) => {
    const req = {
      taskId: taskId,
      newMaxTime: newMaxTime,
    };

    callApi("POST", "project_task_update.php", req)
      .then((res) => res.data)
      .then((response) => {
        if (response.success === true) {
          toast.success(response.message, { autoClose: 3000 });
          this.getTasks();
          this.gettotaltime();
        } else {
          toast.error(response.message, { autoClose: 3000 });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  sendActualTimeUpdateToApi = (taskId, newActualTime) => {
    const req = {
      taskId: taskId,
      newActualTime: newActualTime,
    };

    // Call the API to update the task actualtime
    callApi("POST", "project_task_update.php", req) // Update the API endpoint
      .then((res) => res.data)
      .then((response) => {
        if (response.success === true) {
          toast.success(response.message, { autoClose: 3000 });
          this.getTasks();
        } else {
          toast.error(response.message, { autoClose: 3000 });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  sendCommittedDateUpdateToApi = (taskId, newCommittedDate) => {
    const req = {
      taskId: taskId,
      newCommittedDate: newCommittedDate,
    };

    callApi("POST", "project_task_update.php", req)
      .then((res) => res.data)
      .then((response) => {
        if (response.success === true) {
          toast.success(response.message, { autoClose: 3000 });
          this.getTasks();
          this.gettotaltime();
        } else {
          toast.error(response.message, { autoClose: 3000 });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  sendAssignedToDataToApi = (taskId, selectedOption) => {
    const req = {
      taskId: taskId,
      assignedTo: selectedOption.name,
    };

    callApi("POST", "project_task_update.php", req)
      .then((res) => res.data)
      .then((response) => {
        if (response.success === true) {
          toast.success(response.message, { autoClose: 3000 });
          this.getTasks();
        } else {
          toast.error(response.message, { autoClose: 3000 });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  sendSupportDataToApi = (taskId, selectedOption) => {
    const req = {
      taskId: taskId,
      supportPerson: selectedOption.name,
    };

    callApi("POST", "project_task_update.php", req)
      .then((res) => res.data)
      .then((response) => {
        if (response.success === true) {
          toast.success(response.message, { autoClose: 3000 });
          this.getTasks();
        } else {
          toast.error(response.message, { autoClose: 3000 });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  handleRemarksSubmit = (taskId, value) => {
    const req = {
      taskId: taskId,
      remarks: value,
    };

    callApi("POST", "project_task_update.php", req)
      .then((res) => res.data)
      .then((response) => {
        if (response.success === true) {
          toast.success(response.message, { autoClose: 3000 });
          this.getTasks();
        } else {
          toast.error(response.message, { autoClose: 3000 });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  handletaskSubmit = (taskId, value) => {
    const req = {
      taskId: taskId,
      task: value,
    };

    callApi("POST", "project_task_update.php", req)
      .then((res) => res.data)
      .then((response) => {
        if (response.success === true) {
          toast.success(response.message, { autoClose: 3000 });
          this.getTasks();
        } else {
          toast.error(response.message, { autoClose: 3000 });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  render() {
    const data = this.state.allTasks;
    const userDetails = sessionStorage.getItem("userdetails");
    const userDesignation = userDetails
      ? JSON.parse(userDetails).designation
      : null;
    const CustomDatePickerInput = ({ value, onClick }) => (
      <input
        type="text"
        value={value}
        onClick={onClick}
        style={{
          // Add your custom styles here
          borderRadius: "5px",
          border: "1px solid #ccc",
          fontSize: "14px",
          // Add more styles as needed
        }}
      />
    );
    const columns = [
      {
        Header: "T ID",
        accessor: "id",
        Cell: ({ value }) => (value ? value : "-"),
        sort: true,
      },
      {
        Header: "Task",
        accessor: "task",
        Cell: ({ value, row }) => {
          const [taskValue, setTaskValue] = useState(value);

          const handleKeyPress = (e) => {
            if (e.key === "Enter") {
              this.handletaskSubmit(row.original.id, taskValue);
            }
          };

          const handleChange = (e) => {
            setTaskValue(e.target.value);
          };

          return (
            <div style={{ display: "flex", alignItems: "center" }}>
              <textarea
                value={taskValue}
                onChange={handleChange}
                onKeyPress={handleKeyPress}
                style={{
                  height: "40px",
                  border: "1px solid #ccc",
                  borderRadius: "5px",
                  marginRight: "5px",
                }}
              />
            </div>
          );
        },
        sort: true,
      },

      {
        Header: "Min Time",
        accessor: "mintime",
        Cell: ({ value, row }) => {
          const [selectedMinTime, setSelectedMinTime] = useState({
            hour: value ? moment(value, "HH:mm").format("HH") : "",
            minute: value ? moment(value, "HH:mm").format("mm") : "",
          });

          const handleMinTimeUpdate = (e) => {
            const { name, value } = e.target;

            setSelectedMinTime((prevSelectedMinTime) => ({
              ...prevSelectedMinTime,
              [name]: value,
            }));
          };

          const handleMinTimeBlur = () => {
            if (selectedMinTime.hour !== "" && selectedMinTime.minute !== "") {
              const formattedMinTime = moment(
                `${selectedMinTime.hour}:${selectedMinTime.minute}`,
                "HH:mm"
              ).format("HH:mm");

              // Assuming `sendMinTimeUpdateToApi` is a function available in your component
              this.sendMinTimeUpdateToApi(row.original.id, formattedMinTime);
            }
          };

          return (
            <input
              type="time"
              name="hour"
              value={value ? moment(value, "HH:mm").format("HH:mm") : ""}
              onChange={handleMinTimeUpdate}
              onBlur={handleMinTimeBlur}
              style={{
                height: "38px",
                border: "0px solid #ccc",
                borderRadius: "5px",
              }}
            />
          );
        },
        sort: true,
      },
      {
        Header: "Max Time",
        accessor: "maxtime",
        Cell: ({ value, row }) => {
          const [selectedMaxTime, setSelectedMaxTime] = useState({
            hour: value ? moment(value, "HH:mm").format("HH") : "",
            minute: value ? moment(value, "HH:mm").format("mm") : "",
          });

          const handleMaxTimeUpdate = (e) => {
            const { name, value } = e.target;

            setSelectedMaxTime((prevSelectedMaxTime) => ({
              ...prevSelectedMaxTime,
              [name]: value,
            }));
          };

          const handleMaxTimeBlur = () => {
            if (selectedMaxTime.hour !== "" && selectedMaxTime.minute !== "") {
              const formattedMaxTime = moment(
                `${selectedMaxTime.hour}:${selectedMaxTime.minute}`,
                "HH:mm"
              ).format("HH:mm");

              this.sendMaxTimeUpdateToApi(row.original.id, formattedMaxTime);
            }
          };

          return (
            <input
              type="time"
              name="hour"
              value={value ? moment(value, "HH:mm").format("HH:mm") : ""}
              onChange={handleMaxTimeUpdate}
              onBlur={handleMaxTimeBlur}
              style={{
                height: "38px",
                border: "0px solid #ccc",
                borderRadius: "5px",
              }}
            />
          );
        },
        sort: true,
      },
      {
        Header: "Actual Time",
        accessor: "actualtime",
        Cell: ({ value, row }) => {
          const [selectedTime, setSelectedTime] = useState({
            hour: value ? moment(value, "HH:mm").format("HH") : "",
            minute: value ? moment(value, "HH:mm").format("mm") : "",
          });

          const handleTimeUpdate = (e) => {
            const { name, value } = e.target;

            setSelectedTime((prevSelectedTime) => ({
              ...prevSelectedTime,
              [name]: value,
            }));
          };

          const handleTimeBlur = () => {
            if (selectedTime.hour !== "" && selectedTime.minute !== "") {
              const formattedTime = moment(
                `${selectedTime.hour}:${selectedTime.minute}`,
                "HH:mm"
              ).format("HH:mm");

              this.sendActualTimeUpdateToApi(row.original.id, formattedTime);
            }
          };

          return (
            <input
              type="time"
              name="hour"
              value={value ? moment(value, "HH:mm").format("HH:mm") : ""}
              onChange={handleTimeUpdate}
              onBlur={handleTimeBlur}
              style={{
                height: "38px",
                border: "0px solid #ccc",
                borderRadius: "5px",
              }}
            />
          );
        },
        sort: true,
      },
      {
        Header: "Committed Date",
        accessor: "committed_date",
        Cell: ({ value, row, column }) => (
          <DatePicker
            selected={value ? moment(value).toDate() : null}
            onChange={(date) => {
              this.sendCommittedDateUpdateToApi(
                row.original.id,
                moment(date).format("YYYY-MM-DD")
              ); // Send to API
            }}
            style={{
              height: "38px",
              border: "1px solid #ccc",
              borderRadius: "5px",
            }}
            showYearDropdown
            showMonthDropdown
            dropdownMode="select"
            minDate={value ? moment(value).toDate() : null}
            dateFormat="yyyy-MM-dd"
          />
        ),
        sort: true,
      },
      {
        Header: "Status",
        accessor: "task_status",
        Cell: ({ value, row }) => (
          <Select
            id={`status-${row.id}`}
            name={`status-${row.id}`}
            options={this.state.allstatus}
            value={
              this.state.allstatus.find((status) => status.name === value) ||
              this.state.selectedStatus
            }
            styles={{
              control: (provided) => ({
                ...provided,
                width: "150px", // Set the width for the control (before clicking)
                borderRadius: "5px",
              }),
              menu: (provided, state) => ({
                ...provided,
                position: "absolute",
                zIndex: 9999,
                width: "200px", // Set the desired width here
              }),
            }}
            menuPortalTarget={document.body}
            onChange={(selectedOption) => {
              const updatedTasks = [...this.state.allTasks];
              const rowIndex = updatedTasks.findIndex(
                (task) => task.id === row.original.id
              );
              updatedTasks[rowIndex].status = selectedOption.name;

              this.setState(
                {
                  allTasks: updatedTasks,
                },
                () => {
                  this.sendStatusUpdateToApi(row.original.id, selectedOption);
                }
              );
            }}
            getOptionLabel={(option) => option.name}
            getOptionValue={(option) => option.id.toString()}
          />
        ),
        sort: true,
      },
      {
        Header: "Assigned To",
        accessor: "assignedto",
        Cell: ({ value, row }) => (
          <Select
            id={`assigned-to-${row.id}`}
            name={`assigned-to-${row.id}`}
            options={this.state.allUser}
            value={
              this.state.allUser.find((user) => user.name === value) || null
            }
            menuPortalTarget={document.body}
            styles={{
              control: (provided) => ({
                ...provided,
                width: "150px", // Set the width for the control (before clicking)
                borderRadius: "5px",
              }),
              menu: (provided, state) => ({
                ...provided,
                position: "absolute",
                zIndex: 9999,
                width: "200px", // Set the desired width here
              }),
            }}
            onChange={(selectedOption) => {
              const updatedTasks = [...this.state.allTasks];
              const rowIndex = updatedTasks.findIndex(
                (task) => task.id === row.original.id
              );

              updatedTasks[rowIndex].assigned = selectedOption.name;

              this.setState(
                {
                  allTasks: updatedTasks,
                },
                () => {
                  this.sendAssignedToDataToApi(row.original.id, selectedOption);
                }
              );
            }}
            getOptionLabel={(option) => option.name}
            getOptionValue={(option) => option.id.toString()}
          />
        ),
        sort: true,
      },
      {
        Header: "Remarks",
        accessor: "remarks",
        Cell: ({ value, row }) => {
          const [remarksValue, setRemarksValue] = useState(value);

          const handleKeyPress = (e) => {
            if (e.key === "Enter") {
              this.handleRemarksSubmit(row.original.id, remarksValue);
            }
          };

          const handleChange = (e) => {
            setRemarksValue(e.target.value);
          };

          return (
            <div style={{ display: "flex", alignItems: "center" }}>
              <textarea
                placeholder={value}
                value={remarksValue}
                onChange={handleChange}
                onKeyPress={handleKeyPress}
                style={{
                  height: "40px",
                  border: "1px solid #ccc",
                  borderRadius: "5px",
                  marginRight: "5px",
                }}
              />
            </div>
          );
        },
        sort: true,
      },
      {
        Header: "Support",
        accessor: "support",
        Cell: ({ value, row }) => (
          <Select
            id={`support-${row.id}`}
            name={`support-${row.id}`}
            options={this.state.allUser}
            value={
              this.state.allUser.find((user) => user.name === value) || null
            }
            menuPortalTarget={document.body}
            styles={{
              control: (provided) => ({
                ...provided,
                width: "150px",
                borderRadius: "5px",
              }),
              menu: (provided, state) => ({
                ...provided,
                position: "absolute",
                zIndex: 9999,
                width: "200px", // Set the desired width here
              }),
            }}
            onChange={(selectedOption) => {
              const updatedTasks = [...this.state.allTasks];
              const rowIndex = updatedTasks.findIndex(
                (task) => task.id === row.original.id
              );

              updatedTasks[rowIndex].support = selectedOption.name;

              this.setState(
                {
                  allTasks: updatedTasks,
                },
                () => {
                  this.sendSupportDataToApi(row.original.id, selectedOption);
                }
              );
            }}
            getOptionLabel={(option) => option.name}
            getOptionValue={(option) => option.id.toString()}
          />
        ),
        sort: true,
      },
    ];

    const sizePerPageList = [
      {
        text: "5",
        value: 5,
      },
      {
        text: "10",
        value: 10,
      },
      {
        text: "25",
        value: 25,
      },
      {
        text: "All",
        value: data.length,
      },
    ];

    return (
      <>
        <section className="pcoded-main-container">
          <div className="pcoded-wrapper">
            <div className="pcoded-content">
              <div className="pcoded-inner-content">
                <div className="main-body">
                  <div className="page-wrapper">
                    <div className="row">
                      <div className="col-sm-12">
                        <div className="card">
                          <div
                            className="card-header"
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                              padding: "17px",
                            }}
                          >
                            <h3>{this.state.projectname}-Tasks</h3>
                            <div
                              style={{
                                padding: "10px",
                                border: "1px solid #ccc",
                                borderRadius: "10px",
                              }}
                            >
                              <p
                                style={{ marginBottom: "0em", fontWeight: 900 }}
                              >
                                Min Time:{" "}
                                <span style={{ color: "green" }}>
                                  {this.state.min_totaltime}
                                </span>{" "}
                                <span
                                  style={{
                                    margin: "0 10px",
                                    borderLeft: "3px dotted #000",
                                    height: "16px",
                                  }}
                                ></span>
                                Max Time:{" "}
                                <span style={{ color: "green" }}>
                                  {this.state.max_totaltime}
                                </span>
                                <span
                                  style={{
                                    margin: "0 10px",
                                    borderLeft: "3px dotted #000",
                                    height: "16px",
                                  }}
                                ></span>
                                Actual Time:{" "}
                                <span
                                  style={{
                                    color:
                                      parseInt(this.state.actualtime_count) >
                                      parseInt(this.state.max_totaltime)
                                        ? "red"
                                        : "green",
                                  }}
                                >
                                  {this.state.actualtime_count}
                                </span>
                              </p>
                            </div>

                            <div>
                              <Link
                                to="/manage-project"
                                className="btn btn-secondary"
                              >
                                Go Back
                              </Link>
                            </div>
                          </div>
                          <div className="card-body">
                            {this.renderStatusCountTable()}

                            <div className="dt-responsive table-responsive">
                              <Link
                                to={"/Role/add/0"}
                                className="btn btn-primary m-b-20"
                                data-toggle="modal"
                                data-target="#Role-modal"
                                style={{
                                  borderRadius: "10px",
                                  backgroundColor: "green",
                                  color: "#fff",
                                  borderColor: "green",
                                }}
                              >
                                <FontAwesomeIcon icon={faPlus} />
                                Add Task
                              </Link>
                              <Link
                                to={`/task-live/${this.props.match.params.id}`}
                                className="btn btn-primary m-b-20"
                                style={{
                                  float: "inline-end",
                                  borderRadius: "10px",
                                  backgroundColor: "green",
                                  color: "#fff",
                                  borderColor: "green",
                                }}
                              >
                                Live :{this.state.livecount}
                              </Link>

                              <Table
                                columns={columns}
                                data={data}
                                pageSize={50}
                                sizePerPageList={sizePerPageList}
                                isSortable={true}
                                pagination={true}
                                isSearchable={true}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        //Add Task
        <div className="modal" id="Role-modal">
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-body" style={{ height: "auto" }}>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
                <h4>Add Task</h4>
                <div
                  className="model-xl"
                  style={{
                    height: "auto",
                    position: "relative",
                    overflow: "auto",
                  }}
                >
                  <div className="card-body">
                    <div className="form-group">
                      <label htmlFor="taskname">
                        Task<span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        type="text"
                        id="taskname"
                        name="taskname"
                        className="form-control"
                        minLength={2}
                        max={50}
                        value={this.state.taskname}
                        onChange={(e) =>
                          this.setState({ taskname: e.target.value })
                        }
                        maxLength={50}
                        required
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="mintime">Min Time</label>
                      <input
                        type="time"
                        id="mintime"
                        name="mintime"
                        className="form-control"
                        onChange={this.handleMinTimeInputChange}
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="maxtime">Max Time</label>
                      <input
                        type="time"
                        id="maxtime"
                        name="maxtime"
                        className="form-control"
                        onChange={this.handleMaxTimeInputChange}
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="actualtime">Actual Time</label>
                      <input
                        type="time"
                        id="actualtime"
                        name="actualtime"
                        className="form-control"
                        onChange={this.handleActualTimeInputChange}
                      />
                    </div>

                    <div className="form-group">
                      <label htmlFor="committeddate">Committed Date</label>
                      <input
                        type="date"
                        id="committeddate"
                        name="committeddate"
                        className="form-control"
                        onChange={this.handleCommittedDateInputChange}
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="status">Status</label>
                      <Select
                        id="status"
                        name="status"
                        options={this.state.allstatus}
                        value={
                          this.state.selectedStatus || this.state.allstatus[0]
                        }
                        onChange={(selectedOption) =>
                          this.setState({ selectedStatus: selectedOption })
                        }
                        getOptionLabel={(option) => option.name}
                        getOptionValue={(option) => option.id.toString()}
                      />
                    </div>
                  </div>
                </div>
                <div className="modal-footer">
                  {" "}
                  {/* Add a modal footer */}
                  <button
                    type="button"
                    className="btn btn-primary"
                    data-dismiss="modal"
                    // onClick={this.disableModalHandler}
                    style={{
                      float: "right",
                      marginBottom: "20px",
                      backgroundColor: "green",
                      borderColor: "green",
                    }}
                  >
                    {" "}
                    close
                  </button>
                  <button
                    type="button"
                    className="btn btn-primary"
                    data-dismiss="modal"
                    onClick={this.handleSubmit}
                    // onClick={this.disableModalHandler}
                    style={{
                      float: "right",
                      marginBottom: "20px",
                      borderColor: "cadetblue",
                    }}
                  >
                    {" "}
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="modal" id="edit-user" role="dialog" aria-hidden="true">
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-body">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
                <h4>Manage Projects</h4>
                <div
                  className="model-xl"
                  style={{
                    height: "200px",
                    position: "relative",
                    overflow: "auto",
                  }}
                >
                  <div className="card-body">
                    <div className="form-group">
                      <label htmlFor="Role"></label>
                      <input
                        type="text"
                        id="Role"
                        name="Role"
                        className="form-control"
                        style={{ marginTop: "47px" }}
                        value={this.state.Role}
                        onChange={this.handleInputChange}
                      />
                    </div>
                  </div>
                </div>
                <div className="modal-footer">
                  {" "}
                  {/* Add a modal footer */}
                  <button
                    type="button"
                    className="btn btn-primary"
                    data-dismiss="modal"
                    // onClick={this.disableModalHandler}
                    style={{
                      float: "right",
                      marginBottom: "20px",
                      backgroundColor: "green",
                      borderColor: "green",
                    }}
                  >
                    {" "}
                    close
                  </button>
                  <button
                    type="button"
                    className="btn btn-primary"
                    data-dismiss="modal"
                    onClick={this.updateProject}
                    // onClick={this.disableModalHandler}
                    style={{
                      float: "right",
                      marginBottom: "20px",
                      borderColor: "cadetblue",
                    }}
                  >
                    {" "}
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default taskmanagement;
