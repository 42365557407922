import React from "react";
import { Route, Switch } from "react-router-dom";
import Dashboard from "./components/dashboard/Dashboard";
import Header from "./components/layout/Header";
import Sidebar from "./components/layout/Sidebar";
import ManageUser from "./components/user/ManageUser";
import ManageDropdown from "./components/DropdownManagement/ManageDropdown";
import AddUser from "./components/user/AddUser";
import EditUser from "./components/user/EditUser";
import ManageRole from "./components/Role/ManageRole";
import Report from "./components/dashboard/Report";
import ReportingOfficerscreen1 from "./components/dashboard/ReportingOfficerscreen-1";
import Reportingofficerscreen2 from "./components/dashboard/Reportingofficerscreen-2";
import Reportingofficerscreen3 from "./components/dashboard/Reportingofficerscreen-3";
import Adminscreen1 from "./components/dashboard/Admin/Adminscreen1";
import LogDetails from "./components/dashboard/LogDetails";
import Hrscreen from "./components/dashboard/Hr/Hrscreen";
import Changepassword from "./components/dashboard/Changepassword";
import Leavemanage from "./components/LeaveManagement/Leavemanage";
import Applyleave from "./components/LeaveManagement/Apply_leave";
import Leaveapprove from "./components/LeaveManagement/Leave_approve";
import ManageProject from "./components/projects/manage_project";
import projectpage from "./components/projects/project-page";
import onhold from "./components/projects/onhold";
import closedproject from "./components/projects/closed-project";
import taskmanagement from "./components/Tasks/task-management";
import tasklive from "./components/Tasks/task-live";
declare var $: any;
class PrivateRoutes extends React.Component {
  render() {
    return (
      <div className="wrapper">
        <Sidebar />
        <div id="content">
          <Header />
          <Switch>
            <Route exact path="/dashboard" component={Dashboard} />
            <Route exact path="/user" component={ManageUser} />
            <Route exact path="/role" component={ManageRole} />
            <Route exact path="/Dropdown" component={ManageDropdown} />
            <Route exact path="/user/add" component={AddUser} />
            <Route exact path="/user/edit/:id" component={EditUser} />
            <Route exact path="/Report" component={Report} />
            <Route
              exact
              path="/Reporting-officer"
              component={ReportingOfficerscreen1}
            />
            <Route
              exact
              path="/Report-officer"
              component={Reportingofficerscreen2}
            />
            <Route
              exact
              path="/developer"
              component={Reportingofficerscreen3}
            />
            <Route exact path="/testerreport" component={Adminscreen1} />
            <Route exact path="/log-details" component={LogDetails} />
            <Route exact path="/hr-screen" component={Hrscreen} />
            <Route exact path="/change-password" component={Changepassword} />
            <Route exact path="/leave-manage" component={Leavemanage} />
            <Route exact path="/apply-leave" component={Applyleave} />
            <Route exact path="/leave-approve" component={Leaveapprove} />
            <Route exact path="/manage-project" component={ManageProject} />
            <Route
              exact
              path="/projects/project-page/:id"
              component={projectpage}
            />
            <Route exact path="/onhold" component={onhold} />
            <Route exact path="/closed-project" component={closedproject} />
            <Route exact path="/task-live/:id" component={tasklive} />
            <Route
              exact
              path="/task-management/:id"
              component={taskmanagement}
            />
            <Route component={() => <div>Not Found</div>} />
          </Switch>
        </div>
      </div>
    );
  }
}

export default PrivateRoutes;
