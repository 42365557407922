import { Link } from "react-router-dom";
import React, { Component } from "react";
import moment from "moment";
import { callApi } from "../../utils/api";
import { toast } from "react-toastify";

interface User {
  id: number;
  name: string;
  emp_id: string;
}
interface Log {
  date: string;
  name: string;
  in_time: string;
  out_time: string;
  total_hours: number;
}
interface HeaderState {
  showUserDetails: boolean;
  checkedIn: boolean;
  lastCheckIndate: string | null;
  alllogs: Log[];
  allUser: User[];
  user_id: number;
  name: string;
  buttonDisabled: boolean;
  showEarlyCheckoutPopup: boolean;
  earlyCheckoutReason: string;
  emp_id: string;
}

class Header extends Component<{}, HeaderState> {
  state: HeaderState = {
    showUserDetails: false,
    checkedIn: false,
    lastCheckIndate: null,
    alllogs: [],
    allUser: [],
    user_id: 0,
    name: "",
    buttonDisabled: false,
    showEarlyCheckoutPopup: false,
    earlyCheckoutReason: "",
    emp_id: "",
  };

  componentDidMount() {
    document.addEventListener("click", this.handleDocumentClick);
    const userDetails = sessionStorage.getItem("userdetails");
    let userId = userDetails ? JSON.parse(userDetails).id : null;
    let Empid = userDetails ? JSON.parse(userDetails).emp_id : null;
    if (userDetails) {
      const parsedUserDetails = JSON.parse(userDetails);
      userId = parseInt(parsedUserDetails.id);
      const Name = parsedUserDetails.name;
      const user_id = parsedUserDetails.emp_id;
      this.state.user_id = userId;
      this.state.emp_id = Empid;
      this.setState({
        user_id: userId,
        name: Name,
        emp_id: Empid,
      });
    }
    this.getLog();
  }

  componentWillUnmount() {
    document.removeEventListener("click", this.handleDocumentClick);
  }

  handleDocumentClick = (event) => {
    const userProfileDropdown = document.querySelector(".user-profile");
    if (userProfileDropdown && !userProfileDropdown.contains(event.target)) {
      this.setState({
        showUserDetails: false,
      });
    }
  };

  getUser() {
    callApi("GET", "user_get.php")
      .then((res) => res.data)
      .then((response) => {
        this.setState({ allUser: response.data });
        console.log("allUser", this.state.allUser);
      })
      .catch((err) => {});
  }

  getLog() {
    const date = new Date();
    const formattedDate = moment(date).format("DD-MM-yyyy");
    console.log(this.state);
    const req = {
      user_id: this.state.user_id,
      date: formattedDate,
    };
    callApi("POST", "log_get.php", req)
      .then((res) => res.data)
      .then((response) => {
        this.setState({ alllogs: response.data });
        console.log("logs", this.state.alllogs);
        let data = this.state.alllogs.forEach((log) => {
          if (log.date === formattedDate && log.out_time === "") {
            this.setState({
              checkedIn: log?.in_time ? true : false,
            });
          } else if (
            log.date === formattedDate &&
            log.out_time &&
            log.in_time
          ) {
            this.setState({
              buttonDisabled: true,
            });
          }
        });
        console.log("data", data);
      })
      .catch((err) => {});
  }

  handleCheckIn = () => {
    const date = new Date();
    const formattedDate = moment(date).format("DD-MM-yyyy");
    const formattedTime = moment(date).format("HH:mm:ss");

    const checkInTimeAfter = moment("09:15:00", "HH:mm:ss");
    const currentTime = moment(formattedTime, "HH:mm:ss");

    if (currentTime.isAfter(checkInTimeAfter)) {
      const reason = prompt(
        "You are checking in after 9:15 AM. Please provide a reason:"
      );
      if (reason === null || reason === "") {
        return;
      }
      const req = {
        user_id: this.state.user_id,
        emp_id: this.state.emp_id,
        date: formattedDate,
        name: this.state.name,
        in_time: formattedTime,
        checkin_reason: reason,
      };

      callApi("POST", "log_insert_checkin.php", req)
        .then((res) => res.data)
        .then((response) => {
          this.setState({
            alllogs: response.data,
            checkedIn: true,
            lastCheckIndate: formattedDate,
          });
          toast.success("Checked In successfully!", { autoClose: 2000 });
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      const req = {
        user_id: this.state.user_id,
        emp_id: this.state.emp_id,
        date: formattedDate,
        name: this.state.name,
        in_time: formattedTime,
      };

      callApi("POST", "log_insert.php", req)
        .then((res) => res.data)
        .then((response) => {
          this.setState({
            alllogs: response.data,
            checkedIn: true,
            lastCheckIndate: formattedDate,
          });
          toast.success("Checked In successfully!", { autoClose: 2000 });
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  handleCheckout = () => {
    const date = new Date();
    const formattedDate = moment(date).format("DD-MM-yyyy");
    const formattedTime = moment(date).format("HH:mm:ss");

    const checkouttime = moment("18:00:00", "HH:mm:ss");
    const currentTime = moment(formattedTime, "HH:mm:ss");

    if (currentTime.isBefore(checkouttime)) {
      const reason = prompt(
        "You are checking out before 6:00 PM. Please provide a reason:"
      );
      if (reason === null || reason === "") {
        return;
      }
      const req = {
        user_id: this.state.user_id,
        emp_id: this.state.emp_id,
        date: formattedDate,
        name: this.state.name,
        out_time: formattedTime,
        checkout_reason: reason,
      };

      callApi("POST", "log_update_earlycheckout.php", req)
        .then((res) => res.data)
        .then((response) => {
          this.setState({
            alllogs: response.data,
            checkedIn: false,
            buttonDisabled: true,
          });
          toast.success("Checked Out successfully!", { autoClose: 2000 });
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      const req = {
        user_id: this.state.user_id,
        emp_id: this.state.emp_id,
        name: this.state.name,
        date: formattedDate,
        out_time: formattedTime,
      };

      callApi("POST", "log_update.php", req)
        .then((res) => res.data)
        .then((response) => {
          this.setState({
            alllogs: response.data,
            checkedIn: false,
            buttonDisabled: true,
          });
          toast.success("Checked Out successfully!", { autoClose: 2000 });
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  logout = () => {
    sessionStorage.clear();
  };

  toggleUserDetails = () => {
    this.setState((prevState) => ({
      showUserDetails: !prevState.showUserDetails,
    }));
  };

  render() {
    const userDetails = sessionStorage.getItem("userdetails");
    const userDesignation = userDetails
      ? JSON.parse(userDetails).designation
      : null;
    const role = userDetails ? JSON.parse(userDetails).role : null;
    const { showEarlyCheckoutPopup } = this.state;

    return (
      <header
        className="navbar pcoded-header navbar-expand-lg navbar-light headerpos-fixed"
        style={{ height: "30px" }}
      >
        <div className="m-header">
          <a className="mobile-menu" id="mobile-collapse1" href="#!">
            {userDesignation !== "1" && (
              <>
                {this.state.checkedIn ? (
                  <li>
                    <button
                      className="btn btn-success"
                      style={{
                        backgroundColor: "transparent",
                        borderColor: "transparent",
                        color: "burlywood",
                      }}
                      onClick={this.handleCheckout}
                    >
                      Log-Out
                    </button>
                  </li>
                ) : (
                  <li>
                    <button
                      className="btn btn-success btn-sm  button"
                      style={{
                        backgroundColor: "transparent",
                        borderColor: "transparent",
                        color: "burlywood",
                      }}
                      onClick={this.handleCheckIn}
                    >
                      Log-In
                    </button>
                  </li>
                )}
              </>
            )}
            <span />
          </a>
          <a href="index.html" className="b-brand">
            <div className="b-bg">Z</div>
            <span className="b-title">Zerame</span>
          </a>
        </div>
        <a className="mobile-menu" id="mobile-header" href="#!">
          <i className="feather icon-more-horizontal" />
        </a>
        <div className="collapse navbar-collapse">
          <a href="#!" className="mob-toggler" />
          <ul className="navbar-nav mr-auto">
            <li>
              <div className="page-header" />
            </li>
          </ul>
          <ul className="navbar-nav ml-auto">
            {userDesignation !== "1" && (
              <>
                {this.state.checkedIn ? (
                  <li>
                    <button
                      className="btn btn-success"
                      style={{ backgroundColor: "red" }}
                      onClick={this.handleCheckout}
                    >
                      Log-Out
                    </button>
                  </li>
                ) : (
                  <li>
                    <button
                      className="btn btn-success btn-sm  button"
                      onClick={this.handleCheckIn}
                    >
                      Log-In
                    </button>
                  </li>
                )}
              </>
            )}
            <li className="nav-item">
              <div className="dropdown drp-user">
                <a href="#" className="dropdown-toggle" data-toggle="dropdown">
                  <i className="icon feather fa fa-cog" />
                </a>
                <div
                  className="dropdown-menu dropdown-menu-right profile-notification"
                  style={{ maxHeight: "500px" }}
                >
                  <ul className="pro-body">
                    <li>
                      <a
                        href="#"
                        className="dropdown-item"
                        onClick={this.logout}
                      >
                        <i className="feather icon-log-out" /> Logout
                      </a>
                    </li>
                    {(userDesignation === "1" || userDesignation === "6") && (
                      <>
                        <li>
                          <Link to={"/user"} className="dropdown-item">
                            <i className="feather icon-user" /> User Management
                          </Link>
                        </li>
                        <li>
                          <Link to={"/role"} className="dropdown-item">
                            <i className="feather icon-corner-right-down" />
                            Role Management
                          </Link>
                        </li>
                        <li>
                          <Link to={"/Dropdown"} className="dropdown-item">
                            <i className="feather icon-corner-right-down" />
                            Dropdown Management
                          </Link>
                        </li>
                      </>
                    )}
                  </ul>
                </div>
              </div>
            </li>
            <li>
              <div onClick={this.toggleUserDetails} className="user-profile">
                <img
                  src="//static.vecteezy.com/system/resources/previews/013/042/571/original/default-avatar-profile-icon-social-media-user-photo-in-flat-style-vector.jpg"
                  className="img-radius"
                  alt="User-Profile-Image"
                  width="57px"
                  height="57px"
                />
                {this.state.showUserDetails && (
                  <div
                    style={{
                      position: "absolute",
                      top: "57px",
                      right: 30,
                      backgroundColor: "#fff",
                      border: "1px solid #ccc",
                      padding: "20px",
                      boxShadow:
                        "0 2px 4px rgba(0, 0, 0, 0.1), 0 4px 8px rgba(0, 0, 0, 0.1)",
                      zIndex: 1,
                      width: "300px",
                      height: "300px",
                      borderRadius: "25px",
                    }}
                  >
                    <table
                      style={{ width: "100%", borderCollapse: "collapse" }}
                    >
                      <tbody>
                        <tr>
                          <td
                            style={{
                              padding: "5px 8px",
                              margin: "0",
                              lineHeight: "2",
                            }}
                          >
                            Emp.Id:
                          </td>
                          <td
                            style={{
                              padding: "5px 8px",
                              margin: "0",
                              lineHeight: "2",
                            }}
                          >
                            {userDetails && JSON.parse(userDetails).emp_id}
                          </td>
                        </tr>
                        <tr>
                          <td
                            style={{
                              padding: "5px 8px",
                              margin: "0",
                              lineHeight: "2",
                            }}
                          >
                            Name:
                          </td>
                          <td
                            style={{
                              padding: "5px 8px",
                              margin: "0",
                              lineHeight: "2",
                            }}
                          >
                            {userDetails && JSON.parse(userDetails).name}
                          </td>
                        </tr>
                        <tr>
                          <td
                            style={{
                              padding: "5px 8px",
                              margin: "0",
                              lineHeight: "2",
                            }}
                          >
                            Email:
                          </td>
                          <td
                            style={{
                              padding: "5px 8px",
                              margin: "0",
                              lineHeight: "2",
                            }}
                          >
                            {userDetails && JSON.parse(userDetails).email}
                          </td>
                        </tr>
                        <tr>
                          <td
                            style={{
                              padding: "5px 8px",
                              margin: "0",
                              lineHeight: "2",
                            }}
                          >
                            Mobile:
                          </td>
                          <td
                            style={{
                              padding: "5px 8px",
                              margin: "0",
                              lineHeight: "2",
                            }}
                          >
                            {userDetails && JSON.parse(userDetails).ph_number}
                          </td>
                        </tr>
                        <tr>
                          <td
                            style={{
                              padding: "5px 8px",
                              margin: "0",
                              lineHeight: "2",
                            }}
                          >
                            ReportTo:
                          </td>
                          <td
                            style={{
                              padding: "5px 8px",
                              margin: "0",
                              lineHeight: "2",
                            }}
                          >
                            {userDetails &&
                              JSON.parse(userDetails).reporting_to}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <Link
                      to="/change-password"
                      className="text-cta"
                      style={{ marginLeft: "50px" }}
                    >
                      <>Change Password</>{" "}
                    </Link>
                  </div>
                )}
                <span> {role}</span>
              </div>
            </li>
          </ul>
        </div>
      </header>
    );
  }
}

export default Header;
