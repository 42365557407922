import { connectRouter } from "connected-react-router";
import { History } from "history";
import { Action, AnyAction, combineReducers, Dispatch } from "redux";
import { all } from "redux-saga/effects";

// The top-level state object
export interface ApplicationState {}
export interface ConnectedReduxProps<A extends Action = AnyAction> {
  dispatch: Dispatch<A>;
}
export const createRootReducer = (history: History) =>
  combineReducers({
    router: connectRouter(history)
  });
export function* rootSaga() {
  yield all([]);
}
