import * as React from "react";
import "../user/AddUser.css";
import { callApi } from "../../utils/api";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DatePicker from "react-datepicker";
import { Link } from "react-router-dom";
import moment from "moment";
interface AllProps {
  history: any;
  match: any;
}

interface DesignationOption {
  id: number;
  role: string;
}
interface user {
  id: number;
  name: string;
}

class EditUser extends React.Component<AllProps> {
  state = {
    employeeid: "",
    username: "",
    usernameError: "",
    email: "",
    emailError: "",
    password: "",
    confrimpassword: "",
    designationOptions: [] as DesignationOption[],
    designation: "",
    phonenumber: "",
    dateofbirth: "",
    singleUser: [],
    id: 0,
    status: 1,
    reportingTo: "",
    reportingToOptions: [] as user[],
  };

  componentDidMount(): void {
    console.log("userid", this.props.match.params.id);
    this.getRole();
    const empId = this.props.match.params.id;
    this.getSigleUser(empId);
    this.getReportingToOptions();
  }

  getReportingToOptions() {
    callApi("GET", "user_get.php")
      .then((res) => res.data)
      .then((response) => {
        this.setState({ reportingToOptions: response.data });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  getSigleUser(empId) {
    let req = {
      id: empId,
    };
    callApi("POST", "user_single_get.php", req)
      .then((res) => res.data)
      .then((response) => {
        const userData = response.data[0];
        this.setState({
          employeeid: userData.emp_id,
          username: userData.name,
          email: userData.email,
          password: userData.password,
          designation: userData.designation,
          phonenumber: userData.ph_number,
          dateofbirth: userData.dob,
          reportingTo: userData.reporting,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }
  getRole() {
    callApi("GET", "role_get.php")
      .then((res) => res.data)
      .then((response) => {
        this.setState({ designationOptions: response.data });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  handleInputChange = (event) => {
    const { name, value } = event.target;
    if (name === "username") {
      if (!/^[a-zA-Z\s]*$/.test(value)) {
        this.setState({
          username: value,
          usernameError: "Username must contain letters and spaces only",
        });
      } else {
        this.setState({ username: value, usernameError: "" });
      }
    }

    if (name === "email") {
      const endsWithValidDomain =
        value.toLowerCase().endsWith(".com") ||
        value.toLowerCase().endsWith(".on");

      if (!endsWithValidDomain) {
        this.setState({
          email: value,
          emailError: "Email must end with .com or .on",
        });
      } else {
        this.setState({ email: value, emailError: "" });
      }
    }
    if (name === "phonenumber") {
      if (value.length !== 10) {
        this.setState({ phoneNumberError: "Phone number must be 10 digits" });
      } else {
        this.setState({ phoneNumberError: "" });
      }
    }
    this.setState({ [name]: value });
  };
  handleDateChange = (date) => {
    const formattedDate = moment(date).format("YYYY-MM-DD"); // Adjust the format as needed
    this.setState({ dateofbirth: formattedDate });
  };

  userUpdate = (event) => {
    event.preventDefault();
    const userDetails = sessionStorage.getItem("userdetails");
    const userid = userDetails ? JSON.parse(userDetails)["id"] : "";

    let req = {
      id: this.props.match.params.id,
      emp_id: this.state.employeeid,
      username: this.state.username,
      email: this.state.email,
      password: this.state.password,
      designation: this.state.designation,
      ph_number: this.state.phonenumber,
      dob: this.state.dateofbirth,
      reporting_to: this.state.reportingTo,
      updated_by: userid,
      status: this.state.status,
    };
    callApi("POST", "user_update.php", req)
      .then((res) => res.data)
      .then((response) => {
        if (response.statusCode == 200) {
          this.props.history.goBack();
          toast.success(response.message, { autoClose: 3000 });
          this.props.history.push("/user");
        } else {
          toast.error(response.message, { autoClose: 3000 });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  deleteRole = () => {
    let req = {
      id: this.state.id,
      status: 0,
    };
    callApi("POST", "role_delete.php", req)
      .then((res) => res.data)
      .then((response) => {
        this.getRole();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  render() {
    const {
      employeeid,
      username,
      email,
      password,
      confrimpassword,
      designationOptions,
      phonenumber,
      dateofbirth,
      reportingToOptions,
    } = this.state;

    return (
      <div className="pcoded-main-container">
        <div className="pcoded-wrapper">
          <div className="pcoded-content">
            <div className="pcoded-inner-content">
              <div className="main-body">
                <div className="page-wrapper">
                  <div className="row">
                    <div className="col-sm-12">
                      <div className="card">
                        <div
                          className="card-header"
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            padding: "17px",
                          }}
                        >
                          <h5>Edit User</h5>
                          <div>
                            <Link to="/user" className="btn btn-secondary">
                              Go Back
                            </Link>
                          </div>
                        </div>
                        <div
                          className="card-body"
                          style={{ backgroundColor: "#f2f2f2" }}
                        >
                          <form className="row" onSubmit={this.userUpdate}>
                            <div className="col-md-6">
                              <div
                                className="form-group"
                                style={{ display: "grid" }}
                              >
                                <label htmlFor="employeeid">Employee Id</label>
                                <input
                                  type="text"
                                  name="employeeid"
                                  id="employeeid"
                                  value={employeeid}
                                  onChange={this.handleInputChange}
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div
                                className="form-group"
                                style={{ display: "grid" }}
                              >
                                <label htmlFor="username">Username</label>
                                <input
                                  type="text"
                                  name="username"
                                  id="username"
                                  value={this.state.username}
                                  onChange={this.handleInputChange}
                                />
                                {this.state.usernameError && (
                                  <p style={{ color: "red", marginTop: "5px" }}>
                                    {this.state.usernameError}
                                  </p>
                                )}
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div
                                className="form-group"
                                style={{ display: "grid" }}
                              >
                                <label htmlFor="email">Email</label>
                                <input
                                  type="text"
                                  name="email"
                                  id="email"
                                  value={this.state.email}
                                  onChange={this.handleInputChange}
                                />
                                {this.state.emailError && (
                                  <p style={{ color: "red", marginTop: "5px" }}>
                                    {this.state.emailError}
                                  </p>
                                )}
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div
                                className="form-group"
                                style={{ display: "grid" }}
                              >
                                <label htmlFor="password">Password</label>

                                <input
                                  type="text"
                                  name="password"
                                  id="password"
                                  value={this.state.password}
                                  onChange={this.handleInputChange}
                                  required
                                  pattern="^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*]).{8,}$"
                                  title="Password must contain at least one uppercase letter, one lowercase letter,one special character, one number, and be at least 8 characters long"
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label htmlFor="designation">Designation</label>
                                <select
                                  name="designation"
                                  id="designation"
                                  value={this.state.designation}
                                  onChange={this.handleInputChange}
                                  className="form-control"
                                >
                                  <option value="">Select Designation</option>
                                  {designationOptions.map((designation) => (
                                    <option
                                      key={designation.id}
                                      value={designation.id}
                                    >
                                      {designation.role}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div
                                className="form-group"
                                style={{ display: "grid" }}
                              >
                                <label htmlFor="phonenumber">
                                  Phone Number
                                </label>
                                <input
                                  type="text"
                                  name="phonenumber"
                                  id="phonenumber"
                                  value={this.state.phonenumber}
                                  onChange={this.handleInputChange}
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div
                                className="form-group"
                                style={{ display: "grid" }}
                              >
                                <label htmlFor="dateofbirth">DOB</label>
                                <DatePicker
                                  dateFormat="MM/dd/yyyy"
                                  id="dateofbirth"
                                  name="dateofbirth"
                                  className="form-control"
                                  placeholderText="Select DOB"
                                  showYearDropdown
                                  showMonthDropdown
                                  scrollableYearDropdown
                                  yearDropdownItemNumber={25}
                                  value={this.state.dateofbirth}
                                  onChange={this.handleDateChange}
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label htmlFor="reportingTo">
                                  Reporting To
                                </label>
                                <select
                                  name="reportingTo"
                                  id="reportingTo"
                                  value={this.state.reportingTo || ""}
                                  onChange={this.handleInputChange}
                                  className="form-control"
                                >
                                  <option value="">Select Reporting To</option>
                                  {reportingToOptions.map((user) => (
                                    <option key={user.id} value={user.id}>
                                      {user.name}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            </div>

                            <div className="col-md-6">
                              <div
                                className="form-group"
                                style={{ display: "grid" }}
                              >
                                <label htmlFor="active">Active/InActive</label>
                                <select
                                  name="status"
                                  id="status"
                                  //value={this.state.status}
                                  onChange={this.handleInputChange}
                                  className="form-control"
                                >
                                  <option value="1">Enable</option>
                                  <option value="0">Disable</option>
                                </select>
                              </div>
                            </div>

                            <div className="col-md-12 text-center mt-4 submit-button">
                              <button
                                type="submit"
                                className="btn btn-primary"
                                style={{
                                  borderRadius: "10px",
                                  backgroundColor: "green",
                                  left: "45%",
                                  marginTop: "1px",
                                  color: "#fff",
                                  borderColor: "green",
                                  width: "94px",
                                  height: "47px",
                                }}
                              >
                                Submit
                              </button>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default EditUser;
