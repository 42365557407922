import { createHashHistory } from "history";
import * as React from "react";
import * as ReactDOM from "react-dom";
import App from "./App";
import "./assets/style/animate.min.css";
import "./assets/style/datatables.min.css";
import "./assets/style/bootstrap.min.css";
import "./assets/style/style.css";
import configureStore from "./configureStore";
import "antd/dist/antd.css";
import "./index.css";
import * as serviceWorker from "./serviceWorker";

const history = createHashHistory();

const initialState = window.initialReduxState || {};
const store = configureStore(history, initialState);
ReactDOM.render(
  <App store={store} history={history} />,
  document.getElementById("root")
);
serviceWorker.unregister();
