import * as React from "react";
import { Link } from "react-router-dom";
import Table from "../user/Table";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendar, faPaperclip } from "@fortawesome/free-solid-svg-icons";
import { Tabs, Tab } from "react-bootstrap";
import MaskedInput from "react-text-mask";
// import Developernamescreen from "../Reporing-officer/Reportingofficerscreen-3";

interface AllProps {
  history: any;
}

interface AllState {
  selectedOption: string;
  isCalendarVisible: boolean;
  fromdate: string;
  todate: string;

}
interface DataItem {
  id: number;
  date: string;
  task: string;
  status: string;
  git_id: string;
  comments: string;
  attachments: boolean;
}
class Reportingofficerscreen3 extends React.Component<AllProps, AllState> {
  constructor(props: AllProps) {
    super(props);
    this.state = {
      selectedOption: "daily", // Default selection is "daily"
      isCalendarVisible: false,
      fromdate: "",
      todate: "",
    };
  }

  componentDidMount() {}

  handleOptionChange = (option: string) => {
    this.setState({ selectedOption: option, isCalendarVisible: false  });
  };
  handleCalendarIconClick = () => {
    this.setState((prevState) => ({
      isCalendarVisible: !prevState.isCalendarVisible,
    }));
  };
  renderCalendar() {
    // Placeholder for the calendar component
    // Replace this with your desired calendar component
    return (
      <div
        style={{
          position: "absolute",
          top: "100px",
          left: "50px",
          background: "#fff",
          border: "1px solid #ccc",
          boxShadow: "0 2px 4px rgba(0,0,0,0.2)",
          padding: "10px",
          marginLeft:"246px"
        }}
      >
        {/* Your calendar component here */}
        {/* Replace the input with your calendar component */}
        <input type="date" />
      </div>
    );
  }
  handleAttachmentClick = (row) => {
    // Add your logic here to handle the attachment click for the specific row
    // For example, you can display a modal with the attachment details or perform any other action.
    alert(`Attachment clicked for row with ID: ${row.id}`);
  };
  handleFromDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    this.setState({ [name]: value } as unknown as Pick<AllState, keyof AllState>);
  };

  handleToDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    this.setState({ [name]: value } as unknown as Pick<AllState, keyof AllState>);
  };
  render() {
    const data = [
      {
        id: 1,
        date: "2023-08-01",
        task: "Task 1",
        status: "Completed",
        git_id: "git123",
        comments: "Completed successfully.",
        attachments: true,
      },
      {
        id: 2,
        date: "2023-08-02",
        task: "Task 2",
        status: "Pending",
        git_id: "git456",
        comments: "In progress.",
        attachments: true,
      },
      {
        id: 3,
        date: "2023-08-03",
        task: "Task 3",
        status: "Completed",
        git_id: "git789",
        comments: "Completed with issues.",
        attachments: true,
      },
      {
        id: 4,
        date: "2023-08-04",
        task: "Task 4",
        status: "Pending",
        git_id: "git012",
        comments: "Waiting for review.",
        attachments: true,
      },
      // Add more sample data as needed
    ];

    const columns = [
      {
        Header: "Date",
        accessor: "date",
        sort: true,
      },
      {
        Header: "Task",
        accessor: "task",
        sort: true,
      },
      {
        Header: "Status",
        accessor: "status",
        sort: true,
      },
      {
        Header: "Check Status",
        accessor: "check_status",
        sort: false,
      },
      {
        Header: "GIT ID",
        accessor: "git_id",
        sort: true,
      },
      {
        Header: "Comments",
        accessor: "comments",
        sort: false,
      },
      {
        Header: "Attachments",
        accessor: "attachments",
        sort: false,
        Cell: ({ value, row }: { value: boolean; row: DataItem }) => (
          // Render an attachment icon if attachments exist for the row
          value ? <FontAwesomeIcon icon={faPaperclip}  onClick={() => this.handleAttachmentClick(row)}
          style={{ cursor: "pointer" }} /> : null
        ),
      },
    ];

    const sizePerPageList = [
      {
        text: "5",
        value: 5,
      },
      {
        text: "10",
        value: 10,
      },
      {
        text: "25",
        value: 25,
      },
      {
        text: "All",
        value: data.length,
      },
    ];

    const filteredData = data.filter((item) => {
      // Filter data based on selectedOption (daily, weekly, or monthly)
      if (this.state.selectedOption === "daily") {
        // Filter for daily data
        return item.date === "2023-08-03"; // For demonstration, we are using a specific date.
      } else if (this.state.selectedOption === "weekly") {
        // Filter for weekly data
        // For demonstration, we are using items with even ids as weekly data.
        return item.id % 2 === 0;
      } else if (this.state.selectedOption === "monthly") {
        // Filter for monthly data
        // For demonstration, we are using items with ids divisible by 3 as monthly data.
        return item.id % 3 === 0;
      }
      return null;
    });

    return (
      <section className="pcoded-main-container">
        <div className="pcoded-wrapper">
          <div className="pcoded-content">
            <div className="pcoded-inner-content">
              <div className="main-body">
                <div className="page-wrapper">
                  <div className="row">
                    <div className="col-sm-12">
                      <div className="card">
                        <div className="card-body">
                          <h4>Hii Reporting Officer Name</h4>
                          <ul className="nav nav-tabs">
                            <li className="nav-item">
                            {/* <a className="nav-link active" aria-current="page" href="#">Your Report</a> */}
                               <Link
                                 to="/Report-officer" 
                                className={`nav-link ${
                                  this.state.selectedOption === "Your Report"
                                    ? "active"
                                    : ""
                                }`}
                                onClick={() =>
                                  this.handleOptionChange("Your Report")
                                }
                              >
                                Your Report
                              </Link>
                            </li>
                            <li className="nav-item">
                            {/* <a className="nav-link active" aria-current="page" href="#">Developer Name</a> */}
                            <Link
                                 to="/developer" 
                                className={`nav-link ${
                                  this.state.selectedOption === "Your Report"
                                    ? "active"
                                    : ""
                                }`}
                                onClick={() =>
                                  this.handleOptionChange("Your Report")
                                }
                              >
                               Developer Name
                              </Link>
                            </li>
                          </ul>
                          <ul className="nav nav-tabs">
                            <li className="nav-item">
                              <a
                                className={`nav-link ${
                                  this.state.selectedOption === "daily"
                                    ? "active"
                                    : ""
                                }`}
                                onClick={() =>
                                  this.handleOptionChange("daily")
                                }
                              >
                                Daily
                              </a>
                            </li>
                            <li className="nav-item">
                              <a
                                className={`nav-link ${
                                  this.state.selectedOption === "weekly"
                                    ? "active"
                                    : ""
                                }`}
                                onClick={() =>
                                  this.handleOptionChange("weekly")
                                }
                              >
                                Weekly
                              </a>
                            </li>
                            {/* <li className="nav-item">
                              <a
                                className={`nav-link ${
                                  this.state.selectedOption === "monthly"
                                    ? "active"
                                    : ""
                                }`}
                                onClick={() =>{
                                  this.handleOptionChange("monthly")
                                  this.handleCalendarIconClick();
                                }}
                              >
                                Monthly
                                <FontAwesomeIcon icon={faCalendar} style={{marginLeft:"20px"}} />
                                {this.state.isCalendarVisible && this.renderCalendar()}
                              </a>
                              
                            </li> */}
                             <div className="col-xl-4 col-md-12">
                                    <h6>From Date</h6>
                                    <MaskedInput
                                      className="form-control"
                                      mask={[
                                        /[0-3]/,
                                        /\d/,
                                        ".",
                                        /[0-1]/,
                                        /\d/,
                                        ".",
                                        /[1-2]/,
                                        /\d/,
                                        /\d/,
                                        /\d/
                                      ]}
                                      placeholder="dd.mm.yyyy"
                                      value={this.state.fromdate}
                                      // onChange={e => this.handleChange(e)}
                                      onChange={this.handleFromDateChange}                                      name="fromdate"
                                      style={{width: "146px"}}
                                    />
                                  </div>
                                  <div className="col-xl-4 col-md-12" style={{marginLeft: "-231px"}}>
                                    <h6>To Date</h6>
                                    <MaskedInput
                                      className="form-control"
                                      mask={[
                                        /[0-3]/,
                                        /\d/,
                                        ".",
                                        /[0-1]/,
                                        /\d/,
                                        ".",
                                        /[1-2]/,
                                        /\d/,
                                        /\d/,
                                        /\d/
                                      ]}
                                      placeholder="dd.mm.yyyy"
                                      value={this.state.todate}
                                      onChange={this.handleToDateChange}                                      name="todate"
                                      style={{width: "146px"}}
                                    />
                                  </div>
                          </ul>
                          <div className="dt-responsive table-responsive">
                            <Table
                              columns={columns}
                              data={filteredData}
                              pageSize={5}
                              sizePerPageList={sizePerPageList}
                              isSortable={true}
                              pagination={true}
                              isSearchable={true}
                            />
                          </div>
                          <div className="form-group">
                            <label htmlFor="label1"></label>
                            <input type="text" id="label1" className="form-control" style={{marginLeft: "231px", width: "592px",marginTop:"47px"}} placeholder="Review and Instructions"/>
                          </div>
                          <button
                               type="button"
                               className="btn btn-primary"
                               data-dismiss="modal"
                              //  onClick={this.handleSubmitButtonClick}
                             //  onClick={this.handleSubmit}
                               // onClick={this.disableModalHandler}
                             style={{ float: "right", marginBottom: "20px",backgroundColor: "green",borderColor: "green",marginTop:"-53px"}}
                             > Submit</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default  Reportingofficerscreen3 ;
