import * as React from "react";
import { Link } from "react-router-dom";
import Table from "../user/Table";
import { Dropdown } from "react-bootstrap";
import { callApi } from "../../utils/api";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Select from "react-select";
import moment from "moment";

interface AllProps {
  history: any;
}

interface Member {
  id: number;
  name: string;
}

class closedproject extends React.Component<AllProps> {
  state = {
    allProjects: [],
    allroles: [],
    allUser: [] as { id: number; name: string }[],
    projectName: "",
    clientName: "",
    Role: "",
    id: 0,
    status: 0,
    nextReviewDate: null,
    ReleaseDate: null,
    owner: "",
    members: "",
    user_id: null,
    selecteduser: "",
    username: "",
    selectedMembers: [],
  };
  componentDidMount() {
    this.getProjects();
  }

  getProjects() {
    callApi("GET", "project_get.php")
      .then((res) => res.data)
      .then((response) => {
        const closedproject = response.data.filter(
          (project) => project.status === "Closed"
        );
        this.setState({ allProjects: closedproject });
      })
      .catch((err) => {});
  }

  render() {
    const data = this.state.allProjects;
    const columns = [
      {
        Header: "S No",
        accessor: "serialNumber",
        Cell: ({ row }) => row.index + 1,
        sort: true,
      },
      {
        Header: "Project ID",
        accessor: "id",
        Cell: ({ value }) => (value ? value : "-"),
        sort: true,
      },

      {
        Header: "Project Name",
        accessor: "projectname",
        Cell: ({ value }) => (value ? value : "-"),
        sort: true,
      },
      {
        Header: "Project Owner",
        accessor: "productowner",
        Cell: ({ value }) => (value ? value : "-"),
        sort: true,
      },
      {
        Header: "Project Members",
        accessor: "members",
        Cell: ({ value }) => (value ? value : "-"),
        sort: true,
      },
      {
        Header: "Next Review Date",
        accessor: "next_review_date",
        Cell: ({ value }) => (value ? moment(value).format("DD/MM/YYYY") : "-"),
        sort: true,
      },
      {
        Header: "Release Date",
        accessor: "release_date",
        Cell: ({ value }) => (value ? moment(value).format("DD/MM/YYYY") : "-"),
        sort: true,
      },
      {
        Header: "Status",
        accessor: "status",
        Cell: ({ value }) => (value ? value : "-"),
        sort: true,
      },

      {
        Header: "Action",
        accessor: "action",
        sort: false,
        Cell: ({ row }) => (
          <Link
            to={`/projects/project-page/${row.original.id}`} // Assuming project id is used in the URL
            className="btn btn-primary"
            style={{
              backgroundColor: "green",
              borderColor: "green",
              textDecoration: "none", // To remove default link underline
            }}
          >
            View Project
          </Link>
        ),
      },
    ];

    const sizePerPageList = [
      {
        text: "5",
        value: 5,
      },
      {
        text: "10",
        value: 10,
      },
      {
        text: "25",
        value: 25,
      },
      {
        text: "All",
        value: data.length,
      },
    ];

    return (
      <>
        <section className="pcoded-main-container">
          <div className="pcoded-wrapper">
            <div className="pcoded-content">
              <div className="pcoded-inner-content">
                <div className="main-body">
                  <div className="page-wrapper">
                    <div className="row">
                      <div className="col-sm-12">
                        <div className="card">
                          <div
                            className="card-header"
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                              padding: "17px",
                            }}
                          >
                            <h5>Closed Projects</h5>
                            <div>
                              <Link
                                to="/manage-project"
                                className="btn btn-secondary"
                              >
                                Go Back
                              </Link>
                            </div>
                          </div>
                          <div className="card-body">
                            <div className="dt-responsive table-responsive">
                              <Table
                                columns={columns}
                                data={data}
                                pageSize={50}
                                sizePerPageList={sizePerPageList}
                                isSortable={true}
                                pagination={true}
                                isSearchable={true}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}

export default closedproject;
