import * as React from "react";
import { Link } from "react-router-dom";
import Table from "../user/Table";
import { Button, Dropdown, Modal } from "react-bootstrap";
import { callApi } from "../../utils/api";
import { toast } from "react-toastify";

interface AllProps {
  history: any;
}

interface AllState {
  showDeleteModal: boolean;
  deleteUserId: number | null;
}
class ManageUser extends React.Component<AllProps> {
  state = {
    showModal: false,
    deleteUserId: null,
    allUser: [],
  };

  componentDidMount() {
    this.getUser();
  }
  getUser() {
    callApi("GET", "user_get.php")
      .then((res) => res.data)
      .then((response) => {
        this.setState({ allUser: response.data });
        console.log("allUser", this.state.allUser);
      })
      .catch((err) => {});
  }
  handleDeleteClick = (userId: number) => {
    console.log("Delete Clicked for User ID:", userId);
    let req = {
      id: userId,
    };
    callApi("POST", "user_delete.php", req)
      .then((res) => res.data)
      .then((response) => {
        toast.success(response.message, { autoClose: 3000 });
        this.getUser();
        this.setState({
          showDeleteModal: true,
          deleteUserId: userId,
        });
      })
      .catch((err) => {});
  };
  handleDeleteConfirmed = () => {
    console.log("handleDeleteConfirmed called");
    const { deleteUserId } = this.state;
    console.log("Deleting User ID:", deleteUserId);

    callApi("GET", "user_get.php")
      .then((res) => {
        // Handle success, update the state, or fetch the updated user list
        console.log("User deleted successfully");
        this.getUser(); // Assuming this function fetches the updated user list
      })
      .catch((err) => {
        // Handle error, display an error message, etc.
        console.error("Error deleting user:", err);
      })
      .finally(() => {
        // Reset the state
        this.setState({
          showDeleteModal: false,
          deleteUserId: null,
        });
      });
  };
  render() {
    const data = this.state.allUser;
    const columns = [
      {
        Header: "S.No",
        accessor: "id",
        sort: true,
        Cell: ({ row }) => {
          return <span>{row.index + 1}</span>;
        },
      },
      {
        Header: "ID",
        accessor: "emp_id",
        sort: true,
      },
      {
        Header: "Name",
        accessor: "name",
        sort: true,
      },
      {
        Header: "Email",
        accessor: "email",
        sort: false,
      },
      {
        Header: "PassWord",
        accessor: "password",
        sort: true,
      },
      {
        Header: "Phone Number",
        accessor: "ph_number",
        sort: false,
      },
      {
        Header: "Date Of Birth",
        accessor: "dob",
        sort: false,
      },
      {
        Header: "Action",
        accessor: "action",
        sort: false,
        Cell: ({ row }) => (
          <Dropdown>
            <Dropdown.Toggle
              variant="primary"
              id="action-dropdown"
              style={{ backgroundColor: "green", borderColor: "green" }}
            >
              Actions
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item
                as={Link}
                to={`/user/edit/${row.original.id}`}
                className="dropdown-item"
              >
                Edit
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => this.handleDeleteClick(row.original.id)}
                className="dropdown-item"
              >
                Delete
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        ),
      },
    ];

    const sizePerPageList = [
      {
        text: "5",
        value: 5,
      },
      {
        text: "10",
        value: 10,
      },
      {
        text: "25",
        value: 25,
      },
      {
        text: "All",
        value: data.length,
      },
    ];

    return (
      <section className="pcoded-main-container">
        <div className="pcoded-wrapper">
          <div className="pcoded-content">
            <div className="pcoded-inner-content">
              <div className="main-body">
                <div className="page-wrapper">
                  <div className="row">
                    <div className="col-sm-12">
                      <div className="card">
                        <div
                          className="card-header"
                          style={{
                            display: "flex",
                            position: "sticky",
                            justifyContent: "space-between",
                            alignItems: "center",
                            padding: "17px",
                          }}
                        >
                          <h5>User Manage</h5>
                          <div>
                            <Link to="/dashboard" className="btn btn-secondary">
                              Go Back
                            </Link>
                          </div>
                        </div>
                        <div className="card-body">
                          <div className="dt-responsive table-responsive">
                            <Link
                              to={"/user/add"}
                              className="btn btn-primary m-b-20"
                              style={{
                                borderRadius: "10px",
                                backgroundColor: "green",
                                borderColor: "green",
                              }}
                            >
                              Add User
                            </Link>
                            <Table
                              columns={columns}
                              data={data}
                              pageSize={25}
                              sizePerPageList={sizePerPageList}
                              isSortable={true}
                              pagination={true}
                              isSearchable={true}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="modal fade"
          id="delete-user"
          role="dialog"
          aria-hidden="true"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Delete User</h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <h6>Are you sure you want to Delete this user?</h6>
                <div className="form-group">
                  {/* <label>Reason</label> */}
                  {/* <textarea
                    name="reason"
                    className="form-control"
                    onChange={e => this.handleChange(e)}
                    value={this.state.reason}
                    placeholder="Reason"
                  /> */}
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                  onClick={this.handleDeleteConfirmed}
                  style={{ backgroundColor: "green", borderColor: "green" }}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="btn btn-primary"
                  data-dismiss="modal"
                  // onClick={this.deleteUser}
                  style={{
                    backgroundColor: "cadetblue",
                    borderColor: "cadetblue",
                  }}
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default ManageUser;
