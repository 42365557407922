import * as React from "react";
import { NavLink } from "react-router-dom";
import { callApi } from "../../utils/api";

interface User {
  id: number;
}

class Sidebar extends React.Component {
  state = {
    openFileMenu: false,
    toggleCompletedFiles: false,
    allUser: [],
    user_id: 0,
  };
  componentDidMount() {
    this.getUser();
    const userDetails = sessionStorage.getItem("userdetails");
    let userId = userDetails ? JSON.parse(userDetails).id : null;
  }

  getUser() {
    callApi("GET", "user_get.php")
      .then((res) => res.data)
      .then((response) => {
        this.setState({ allUser: response.data });
        console.log("allUser", this.state.allUser);
      })
      .catch((err) => {});
  }
  toggleFileMenu = () => {
    const openFileMenu = !this.state.openFileMenu;
    this.setState({ openFileMenu });
  };

  render() {
    const { allUser } = this.state;
    const userDetails = sessionStorage.getItem("userdetails");
    const userDesignation = userDetails
      ? JSON.parse(userDetails).designation
      : null;
    console.log("User Designation:", userDesignation);
    return (
      <nav className="pcoded-navbar menupos-fixed ">
        <div className="navbar-wrapper ">
          <div className="navbar-brand header-logo">
            <a className="b-brand">
              {/* <div className="b-bg">Z</div> */}
          <img
    src="//zerame.com/assets/img/logo.png"
    alt="Logo"
    className="logo"
    style={{ width: "49px", height: "auto", marginBottom: "5px" }}
/>


              <span className="b-title">Zerame Infotech</span>
            </a>
          </div>
          <div className="navbar-content scroll-div style-sidebar">
            <ul className="nav pcoded-inner-navbar ">
              <li
                data-username="dashboard default ecommerce sales Helpdesk ticket CRM analytics crypto project"
                className="nav-item"
              >
                <NavLink
                  to={"/dashboard"}
                  style={{
                    color: "blue",
                    textDecoration: "none",
                    display: "block",
                    padding: "10px",
                  }}
                  activeStyle={{ backgroundColor: "#e0e0e0", color: "#333" }}
                >
                  <span className="pcoded-micon">
                    <i className="feather icon-home" />
                  </span>
                  <span className="pcoded-mtext">Dashboard</span>
                </NavLink>
                <NavLink
                  to={"/log-details"}
                  style={{
                    color: "blue",
                    textDecoration: "none",
                    display: "block",
                    padding: "10px",
                  }}
                  activeStyle={{ backgroundColor: "#e0e0e0", color: "#333" }}
                >
                  <span className="pcoded-micon">
                    <i className="feather icon-file-text" />
                  </span>
                  <span className="pcoded-mtext">LogDetails</span>
                </NavLink>
                <NavLink
                  to={"/manage-project"}
                  style={{
                    color: "blue",
                    textDecoration: "none",
                    display: "block",
                    padding: "10px",
                  }}
                  activeStyle={{ backgroundColor: "#e0e0e0", color: "#333" }}
                >
                  <span className="pcoded-micon">
                    <i className="feather icon-folder" />
                  </span>
                  <span className="pcoded-mtext">Projects</span>
                </NavLink>

                {/* <a
                  href="#"
                  onClick={this.toggleFileMenu}
                  style={{
                    color: "blue",
                    textDecoration: "none",
                    display: "block",
                    padding: "10px",
                  }}
                >
                  <span className="pcoded-micon">
                    <i className="feather icon-clipboard" />
                  </span>
                  <span className="pcoded-mtext">Leave Process</span>
                  <span>{this.state.openFileMenu ? "▲" : "▼"}</span>
                </a>
                {this.state.openFileMenu && (
                  <ul className="submenu">
                    <li className="">
                      <NavLink
                        to={"leave-manage"}
                        style={{
                          color: "blue",
                          textDecoration: "none",
                          display: "block",
                          padding: "10px",
                        }}
                        activeStyle={{
                          backgroundColor: "#e0e0e0",
                          color: "#333",
                        }}
                      >
                        <span className="pcoded-micon">
                          <i className="feather icon-clipboard" />
                        </span>
                        <span className="pcoded-mtext">Leave Management</span>
                      </NavLink>
                    </li>
                    <li className="">
                      <NavLink
                        to={"/leave-cancel"}
                        style={{
                          color: "blue",
                          textDecoration: "none",
                          display: "block",
                          padding: "10px",
                        }}
                        activeStyle={{
                          backgroundColor: "#e0e0e0",
                          color: "#333",
                        }}
                      >
                        <span className="pcoded-micon">
                          <i className="feather icon-x-circle" />
                        </span>
                        <span className="pcoded-mtext">Leave Cancel</span>
                      </NavLink>
                    </li>
                    <li className="">
                      {userDesignation === "2" && (
                        <NavLink
                          to={"/leave-approve"}
                          style={{
                            color: "blue",
                            textDecoration: "none",
                            display: "block",
                            padding: "10px",
                          }}
                          activeStyle={{
                            backgroundColor: "#e0e0e0",
                            color: "#333",
                          }}
                        >
                          <span className="pcoded-micon">
                            <i className="feather icon-check-circle" />
                          </span>
                          <span className="pcoded-mtext">Leave Approval</span>
                        </NavLink>
                      )}
                      {userDesignation === "6" && (
                        <NavLink
                          to={"/leave-approve"}
                          style={{
                            color: "blue",
                            textDecoration: "none",
                            display: "block",
                            padding: "10px",
                          }}
                          activeStyle={{
                            backgroundColor: "#e0e0e0",
                            color: "#333",
                          }}
                        >
                          <span className="pcoded-micon">
                            <i className="feather icon-check-circle" />
                          </span>
                          <span className="pcoded-mtext">Leave Approval</span>
                        </NavLink>
                      )}
                    </li>
                  </ul>
                )} */}
              </li>
              {/* <ul className="submenu">
							    <li className="">
											<NavLink to={"/Reporting-officer"} activeClassName="active">
												Reporting Officer Task
											</NavLink>
										</li>
										</ul> */}

              {/* {this.state.openFileMenu && (
									<ul className="pcoded-submenu">
										<li className="">
											<NavLink to={"/pending-file"} activeClassName="active">
												Test
											</NavLink>
										</li>
										<li className="">
											<NavLink to={"/unassigned-file"} activeClassName="active">
												Test One
											</NavLink>
										</li>
									</ul>
								)} */}
            </ul>
          </div>
        </div>
      </nav>
    );
  }
}

export default Sidebar;
