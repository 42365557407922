import { ConnectedRouter } from "connected-react-router";
import { History } from "history";
import * as React from "react";
import { Provider } from "react-redux";
import { Store } from "redux";
import Routes from "./Routes";
import { ApplicationState } from "./store";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";


declare var $: any;

interface MainProps {
  store: Store<ApplicationState>;
  history: History;
}

class App extends React.Component<MainProps, any> {
  constructor(props) {
    super(props);
    this.state = {

    };
  }

  componentDidMount() {
  }


  render(): React.ReactNode {
    return (
      <Provider store={this.props.store}>
        <ConnectedRouter history={this.props.history}>
          <>
            <Routes />
            <ToastContainer
              position="bottom-right"
              autoClose={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              style={{ width: "25%" }}
            />
          </>
        </ConnectedRouter>
      </Provider>
    );
  }
}

export default App;

