import * as React from "react";
import { Link } from "react-router-dom";
import Table from "../user/Table";
import { Dropdown } from "react-bootstrap";
import { callApi } from "../../utils/api";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { CSVLink } from "react-csv";
import moment from "moment";
import { Redirect } from "react-router-dom";
import {
  faCalendar,
  faCheck,
  faTasks,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Select from "react-select";
import { withRouter } from "react-router-dom";

interface AllProps {
  history: any;
  match: any;
}

interface ProjectPageState {
  allProjects: any[];
  allroles: any[];
  allstatus: any[];
  allUser: any[];
  projectName: string;
  clientName: string;
  Role: string;
  id: number;
  status: number;
  nextReviewDate: any;
  ReleaseDate: any;
  selectedStatus: number | string;
  comments: string;
  user_id: string | null;
  isCalendarVisible: boolean;
  isReleaseDatePopupVisible: boolean;
  releaseDateChangeReason: string;
  lastaddedstatus: string;
  selecteduser: any;
  selectedmembers: any;
  productowner: string;
  members: string;
  isNextReviewDatePopupVisible: boolean;
  nextReviewDateChangeReason: string;
  isStatusChangePopupVisible: boolean;
  statusChangeReason: string;
  releaseDateChangeReasonError: boolean;
  nextReviewDateChangeReasonError: boolean;
  statusChangeReasonError: boolean;
}

class projectpage extends React.Component<AllProps, ProjectPageState> {
  state: ProjectPageState = {
    allProjects: [],
    allroles: [],
    allstatus: [],
    projectName: "",
    clientName: "",
    Role: "",
    id: 0,
    status: 0,
    nextReviewDate: null,
    ReleaseDate: null,
    selectedStatus: "",
    comments: "",
    allUser: [],
    user_id: null,
    isCalendarVisible: false,
    isReleaseDatePopupVisible: false,
    releaseDateChangeReason: "",
    lastaddedstatus: "",
    selecteduser: "",
    selectedmembers: [],
    productowner: "",
    members: "",
    isNextReviewDatePopupVisible: false,
    nextReviewDateChangeReason: "",
    isStatusChangePopupVisible: false,
    statusChangeReason: "",
    releaseDateChangeReasonError: false,
    nextReviewDateChangeReasonError: false,
    statusChangeReasonError: false,
  };
  componentDidMount() {
    this.getstatus();
    this.getUser();
    this.getProjectscomments();
    this.getprojectsdata();
    const userDetails = sessionStorage.getItem("userdetails");
    let user_id = userDetails ? JSON.parse(userDetails).id : null;
    this.setState({ user_id: user_id });
  }

  getUser() {
    callApi("GET", "user_get.php")
      .then((res) => res.data)
      .then((response) => {
        this.setState({ allUser: response.data });
      })
      .catch((err) => {
        console.error("Error fetching user data:", err);
      });
  }
  getprojectsdata() {
    let req = {
      id: this.props.match.params.id,
    };
    callApi("POST", "project_selecteddata_get.php", req)
      .then((res) => res.data)
      .then((response) => {
        const projects = response.data;
        const projectNames = projects.map((project) => project.projectname);
        const clientNames = projects.map((project) => project.clientname);
        const owner = projects.map((project) => project.productowner);
        const member = projects.map((project) => project.members);

        this.setState({
          projectName: projectNames,
          clientName: clientNames,
          productowner: owner,
          members: member,
        });
      })
      .catch((err) => {});
  }
  getProjectscomments = () => {
    let req = {
      id: this.props.match.params.id,
    };
    callApi("POST", "projectlastcomment_get.php", req)
      .then((res) => res.data)
      .then((response) => {
        this.setState({ allProjects: response.data });

        if (response.data && response.data.length > 0) {
          const firstProject = response.data[0];
          console.log("firstProject", firstProject);

          const r_date = firstProject.release_date
            ? new Date(firstProject.release_date)
            : null;
          const n_date = firstProject.next_review_date
            ? new Date(firstProject.next_review_date)
            : null;

          console.log(
            "Release Date:",
            firstProject.release_date,
            "Parsed Date:",
            r_date
          );
          console.log(
            "Next Review Date:",
            firstProject.next_review_date,
            "Parsed Date:",
            n_date
          );

          this.setState({
            ReleaseDate: r_date,
            nextReviewDate: n_date,
            selectedStatus: firstProject.project_status,
          });
        } else {
          console.error("Invalid API response format:", response);
        }
      })
      .catch((err) => {
        console.error("Error fetching projects:", err);
      });
  };
  handleStatusChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedStatus = event.target.value;
    this.setState({
      selectedStatus,
      isStatusChangePopupVisible: true,
    });
  };

  handleCommentsChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    this.setState({
      comments: event.target.value,
    });
  };

  handleSubmit = (type) => {
    const date = new Date();
    const formattedDate = moment(date).format("DD-MM-yyyy");
    const { nextReviewDate } = this.state;

    const formattedNextReviewDate = this.state.nextReviewDate
      ? moment(this.state.nextReviewDate).format("YYYY-MM-DDTHH:mm:ssZ")
      : null;
    const formattedReleaseDate = this.state.ReleaseDate
      ? moment(this.state.ReleaseDate).format("YYYY-MM-DDTHH:mm:ssZ")
      : null;
    let req = {
      currentdate: formattedDate,
      userid: this.state.user_id,
      id: this.props.match.params.id,
      status: this.state.selectedStatus,
      nextReviewDate: formattedNextReviewDate,
      comments:
        type === "save"
          ? this.state.comments
          : type === "nextReviewRemark"
          ? this.state.nextReviewDateChangeReason
          : type === "statusRemark"
          ? this.state.statusChangeReason
          : this.state.releaseDateChangeReason,
      ReleaseDate: formattedReleaseDate,
    };
    callApi("POST", "project_comment_insert.php", req)
      .then((res) => res.data)
      .then((response) => {
        if (response.success == true) {
          this.getProjectscomments();
          toast.success(response.message, { autoClose: 3000 });
          this.setState({
            nextReviewDate: null,
            selectedStatus: "",
            comments: "",
            ReleaseDate: null,
            releaseDateChangeReason: "",
          });
          this.handleNextReviewDatePopupClose();
          this.handleReleaseDatePopupClose();
          this.handleStatusChangePopupClose();
        } else {
          toast.error(response.message, { autoClose: 3000 });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  handlememberSubmit = () => {
    let req = {
      id: this.props.match.params.id,
      projectName: this.state.projectName,
      clientName: this.state.clientName,
      owner: this.state.productowner,
      members: this.state.selectedmembers,
    };
    callApi("POST", "project_update.php", req)
      .then((res) => res.data)
      .then((response) => {
        if (response.success == true) {
          this.getprojectsdata();
          toast.success(response.message, { autoClose: 3000 });
        } else {
          toast.error(response.message, { autoClose: 3000 });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  getRole() {
    callApi("GET", "")
      .then((res) => res.data)
      .then((response) => {
        this.setState({ allroles: response.data });
      })
      .catch((err) => {});
  }
  getstatus() {
    callApi("GET", "projectstatus_get.php")
      .then((res) => res.data)
      .then((response) => {
        this.setState({ allstatus: response.data });
      })
      .catch((err) => {});
  }
  exportToCSV = () => {
    const { allProjects, projectName, clientName } = this.state;

    // Create CSV data with the project name row
    const csvData = [
      ...allProjects.map((project) => ({
        ProjectName: "",
        ClientName: clientName || "-",
        Date: project.date || "-",
        "Updated By": project.review_by || "-",
        Comments: project.comments || "-",
      })),
    ];

    const csv = csvData
      .map((row) =>
        Object.values(row)
          .map((value) => `"${value}"`)
          .join(",")
      )
      .join("\n");

    const blob = new Blob([csv], { type: "text/csv" });

    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = `${projectName}_project_data.csv`;
    link.click();
  };

  handleReleaseDateChange = (date: Date) => {
    this.setState(
      { isReleaseDatePopupVisible: true, ReleaseDate: date },
      () => {}
    );
  };

  handleReleaseDatePopupClose = () => {
    this.setState({ isReleaseDatePopupVisible: false });
  };

  handleReleaseDateChangeReason = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    this.setState({ releaseDateChangeReason: event.target.value });
  };

  handleNextReviewDateChange = (date: Date) => {
    console.log("abc", date);

    this.setState(
      { isNextReviewDatePopupVisible: true, nextReviewDate: date },
      () => {
        console.log("def", this.state.nextReviewDate);
      }
    );
  };

  handleNextReviewDatePopupClose = () => {
    this.setState({ isNextReviewDatePopupVisible: false });
  };

  handleNextReviewDateChangeReason = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    this.setState({ nextReviewDateChangeReason: event.target.value });
  };
  handleStatusChangePopupClose = () => {
    this.setState({
      isStatusChangePopupVisible: false,
      statusChangeReason: "",
    });
  };
  handleStatusChangeReason = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    this.setState({ statusChangeReason: event.target.value });
  };

  handleUserChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    this.setState({
      productowner: event.target.value,
    });
  };
  handleMembersChange = (selectedOptions) => {
    const selectedmembers = selectedOptions.map((option) => option.value);
    this.setState({ selectedmembers });
  };
  handleDeleteClick = (id: number) => {
    const isConfirmed = window.confirm(
      "Are you sure you want to delete this project?"
    );

    if (!isConfirmed) {
      return;
    }

    let req = {
      id: id,
    };

    callApi("POST", "project_delete.php", req)
      .then((res) => res.data)
      .then((response) => {
        toast.success(response.message, { autoClose: 3000 });
        this.getprojectsdata();
        const { history } = this.props;
        history.push("/manage-project");
      })
      .catch((err) => {});
  };
  render() {
    const data = this.state.allProjects;
    const {
      isReleaseDatePopupVisible,
      releaseDateChangeReason,
      projectName,
      productowner,
      members,
      nextReviewDate,
      ReleaseDate,
    } = this.state;
    const membersWithoutOwner = this.state.allUser.filter(
      (member) => member.name !== this.state.productowner
    );

    const userDetails = sessionStorage.getItem("userdetails");
    const userDesignation = userDetails
      ? JSON.parse(userDetails).designation
      : null;
    const minDate = new Date();

    const columns = [
      {
        Header: "S No",
        accessor: "serialNumber",
        Cell: ({ row }) => row.index + 1,
        sort: true,
      },
      {
        Header: "Date",
        accessor: "date",
        sort: true,
        Cell: ({ value }) => (value ? value : "-"),
      },
      {
        Header: "Updated By",
        accessor: "review_by",
        sort: true,
        Cell: ({ value }) => (value ? value : "-"),
      },
      {
        Header: "comments",
        accessor: "comments",
        sort: true,
        Cell: ({ value }) => (value ? value : "-"),
      },
    ];

    const sizePerPageList = [
      {
        text: "5",
        value: 5,
      },
      {
        text: "10",
        value: 10,
      },
      {
        text: "25",
        value: 25,
      },
      {
        text: "All",
        value: data.length,
      },
    ];

    return (
      <>
        <section className="pcoded-main-container">
          <div className="pcoded-wrapper">
            <div className="pcoded-content">
              <div className="pcoded-inner-content">
                <div className="main-body">
                  <div className="page-wrapper">
                    <div className="row">
                      <div className="col-sm-12">
                        <div className="card">
                          <div className="card-body">
                            <div
                              className="card-header"
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                padding: "17px",
                              }}
                            >
                              <h4>{projectName}</h4>
                              <div>
                                <Link
                                  to="/manage-project"
                                  className="btn btn-secondary"
                                >
                                  Go Back
                                </Link>
                              </div>
                            </div>
                            <div
                              className="container"
                              style={{
                                border: "1px solid #ccc",
                                padding: "10px",
                                fontSize: "17px",
                                display: "table-cell",
                              }}
                            >
                              <span
                                style={{
                                  marginRight: "20px",
                                  borderRight: "1px solid #ccc",
                                  paddingRight: "10px",
                                }}
                              >
                                Owner: {productowner}
                              </span>
                              <span>Members: {members}</span>
                            </div>
                            <div
                              className="form-group d-flex align-items-center mt-6"
                              style={{ marginTop: "20px" }}
                            >
                              <div className="input-container mr-2">
                                <label htmlFor="nextReviewDate">
                                  Next Review Date:
                                </label>
                                <DatePicker
                                  id="nextReviewDate"
                                  selected={this.state.nextReviewDate}
                                  onChange={(date) =>
                                    this.handleNextReviewDateChange(date)
                                  }
                                  placeholderText={
                                    this.state.nextReviewDate ||
                                    "DD/MM/YYYY" ||
                                    null
                                  }
                                  showMonthDropdown
                                  showYearDropdown
                                  minDate={minDate}
                                  maxDate={this.state.ReleaseDate}
                                  dateFormat="dd/MM/yyyy"
                                  autoComplete="off"
                                />
                              </div>

                              {this.state.isNextReviewDatePopupVisible && (
                                <div
                                  className="next-review-date-popup"
                                  style={{
                                    position: "fixed",
                                    top: "50%",
                                    left: "50%",
                                    transform: "translate(-50%, -50%)",
                                    backgroundColor: "#fff",
                                    border: "1px solid #ccc",
                                    padding: "40px",
                                    borderRadius: "5px",
                                    zIndex: 9999,
                                    width: "350px",
                                  }}
                                >
                                  <div>
                                    <label htmlFor="nextReviewDateChangeReason">
                                      Reason for Next Review Date Change:
                                    </label>
                                    <textarea
                                      id="nextReviewDateChangeReason"
                                      value={
                                        this.state.nextReviewDateChangeReason
                                      }
                                      onChange={
                                        this.handleNextReviewDateChangeReason
                                      }
                                      rows={4}
                                      cols={5}
                                      placeholder="Enter reason for next review date change..."
                                      maxLength={200}
                                      style={{
                                        borderColor: this.state
                                          .nextReviewDateChangeReasonError
                                          ? "red"
                                          : "#ccc",
                                      }}
                                    />
                                  </div>
                                  {this.state
                                    .nextReviewDateChangeReasonError && (
                                    <div
                                      style={{
                                        color: "red",
                                        marginTop: "10px",
                                      }}
                                    >
                                      Reason for next review date change is
                                      required
                                    </div>
                                  )}
                                  <button
                                    onClick={
                                      this.handleNextReviewDatePopupClose
                                    }
                                    style={{
                                      marginTop: "40px",
                                      marginRight: "88px",
                                    }}
                                  >
                                    Cancel
                                  </button>
                                  <button
                                    onClick={() => {
                                      if (
                                        this.state.nextReviewDateChangeReason.trim() !==
                                        ""
                                      ) {
                                        this.handleSubmit("nextReviewRemark");
                                      } else {
                                        // Set the error state to true
                                        this.setState({
                                          nextReviewDateChangeReasonError: true,
                                        });
                                      }
                                    }}
                                  >
                                    Submit
                                  </button>
                                </div>
                              )}

                              <div className="input-container mr-2">
                                <label htmlFor="ReleaseDate">
                                  Release Date:
                                </label>

                                <DatePicker
                                  className="date-wrapper"
                                  id="ReleaseDate"
                                  selected={this.state.ReleaseDate}
                                  onChange={(date) =>
                                    this.handleReleaseDateChange(date)
                                  }
                                  placeholderText={
                                    this.state.ReleaseDate ||
                                    "DD/MM/YYYY" ||
                                    null
                                  }
                                  minDate={this.state.nextReviewDate}
                                  dateFormat="dd/MM/yyyy"
                                  showTimeInput={false}
                                  showMonthDropdown
                                  showYearDropdown
                                  autoComplete="off"
                                />
                              </div>
                              {isReleaseDatePopupVisible && (
                                <div
                                  className="release-date-popup"
                                  style={{
                                    position: "fixed",
                                    top: "50%",
                                    left: "50%",
                                    transform: "translate(-50%, -50%)",
                                    backgroundColor: "#fff",
                                    border: "1px solid #ccc",
                                    padding: "40px",
                                    borderRadius: "5px",
                                    zIndex: 9999,
                                    width: "350px",
                                  }}
                                >
                                  <div>
                                    <label htmlFor="releaseDateChangeReason">
                                      Reason for Release Date Change:
                                    </label>
                                    <textarea
                                      id="releaseDateChangeReason"
                                      value={releaseDateChangeReason}
                                      onChange={
                                        this.handleReleaseDateChangeReason
                                      }
                                      rows={4}
                                      cols={5}
                                      placeholder="Enter reason for release date change..."
                                      maxLength={200}
                                      style={{
                                        borderColor: this.state
                                          .releaseDateChangeReasonError
                                          ? "red"
                                          : "#ccc",
                                      }}
                                    />
                                  </div>
                                  {this.state.releaseDateChangeReasonError && (
                                    <div
                                      style={{
                                        color: "red",
                                        marginTop: "10px",
                                      }}
                                    >
                                      Reason for release date change is required
                                    </div>
                                  )}
                                  <button
                                    onClick={this.handleReleaseDatePopupClose}
                                    style={{
                                      marginTop: "20px",
                                      marginRight: "88px",
                                    }}
                                  >
                                    Cancel
                                  </button>
                                  <button
                                    onClick={() => {
                                      if (
                                        releaseDateChangeReason.trim() !== ""
                                      ) {
                                        this.handleSubmit("releaseRemark");
                                      } else {
                                        // Set the error state to true
                                        this.setState({
                                          releaseDateChangeReasonError: true,
                                        });
                                      }
                                    }}
                                  >
                                    Submit
                                  </button>
                                </div>
                              )}

                              <div className="input-container mr-2">
                                <label htmlFor="status"> Status:</label>
                                {userDesignation === "1" ||
                                userDesignation === "2" ? (
                                  // Editable dropdown for userDesignation 1 or 2
                                  <select
                                    id="status"
                                    name="status"
                                    style={{
                                      height: "44px",
                                      border: "1px solid #ccc",
                                      borderRadius: "5px",
                                    }}
                                    onChange={(e) => this.handleStatusChange(e)}
                                    value={
                                      this.state.selectedStatus ||
                                      this.state.lastaddedstatus
                                    }
                                  >
                                    <option value="">Select status</option>
                                    {this.state.allstatus.map((status) => (
                                      <option
                                        key={status.id}
                                        value={status.project_status}
                                      >
                                        {status.project_status}
                                      </option>
                                    ))}
                                  </select>
                                ) : (
                                  // Read-only representation for other userDesignations
                                  <input
                                    type="text"
                                    value={
                                      this.state.selectedStatus ||
                                      this.state.lastaddedstatus
                                    }
                                    readOnly
                                  />
                                )}
                              </div>
                              {this.state.isStatusChangePopupVisible && (
                                <div
                                  className="status-change-popup"
                                  style={{
                                    position: "fixed",
                                    top: "50%",
                                    left: "50%",
                                    transform: "translate(-50%, -50%)",
                                    backgroundColor: "#fff",
                                    border: "1px solid #ccc",
                                    padding: "40px",
                                    borderRadius: "5px",
                                    zIndex: 9999,
                                    width: "350px",
                                  }}
                                >
                                  <div>
                                    <label htmlFor="statusChangeReason">
                                      Reason for Status Change:
                                    </label>
                                    <textarea
                                      id="statusChangeReason"
                                      value={this.state.statusChangeReason}
                                      onChange={this.handleStatusChangeReason}
                                      rows={4}
                                      cols={5}
                                      placeholder="Enter reason for status change..."
                                      maxLength={200}
                                      style={{
                                        borderColor: this.state
                                          .statusChangeReasonError
                                          ? "red"
                                          : "#ccc",
                                      }}
                                    />
                                  </div>
                                  {this.state.statusChangeReasonError && (
                                    <div
                                      style={{
                                        color: "red",
                                        marginTop: "10px",
                                      }}
                                    >
                                      Error: Reason for status change is
                                      required
                                    </div>
                                  )}
                                  <button
                                    onClick={this.handleStatusChangePopupClose}
                                    style={{
                                      marginTop: "40px",
                                      marginRight: "88px",
                                    }}
                                  >
                                    Cancel
                                  </button>
                                  <button
                                    onClick={() => {
                                      if (
                                        this.state.statusChangeReason.trim() !==
                                        ""
                                      ) {
                                        this.handleSubmit("statusRemark");
                                      } else {
                                        // Set the error state to true
                                        this.setState({
                                          statusChangeReasonError: true,
                                        });
                                      }
                                    }}
                                  >
                                    Submit
                                  </button>
                                </div>
                              )}

                              {(userDesignation === "1" ||
                                userDesignation === "2") && (
                                <div className="input-container mr-2">
                                  <label
                                    htmlFor="comments"
                                    style={{ marginTop: "7px" }}
                                  >
                                    Comments:
                                  </label>
                                  <textarea
                                    style={{
                                      height: "40px",
                                      width: "250%",
                                    }}
                                    id="comments"
                                    name="comments"
                                    value={this.state.comments}
                                    onChange={this.handleCommentsChange}
                                    maxLength={200}
                                    rows={4}
                                    cols={5}
                                    placeholder="Enter comments..."
                                  ></textarea>
                                </div>
                              )}
                              {(userDesignation === "1" ||
                                userDesignation === "2") && (
                                <button
                                  type="button"
                                  className="btn btn-primary"
                                  onClick={() => this.handleSubmit("save")}
                                  style={{
                                    marginLeft: "153px",
                                    marginTop: "21px",
                                  }}
                                >
                                  Save
                                </button>
                              )}
                            </div>
                            {userDesignation === "1" ||
                            userDesignation === "2" ? (
                              <div className="form-group">
                                <CSVLink
                                  data={[
                                    ...this.state.allProjects.map(
                                      (project, index) => ({
                                        sno: index + 1,
                                        date: project.date || "-",
                                        review_by: project.review_by || "-",
                                        comments: project.comments || "-",
                                        projectName: "",
                                      })
                                    ),
                                    {
                                      sno: "",
                                      date: "",
                                      review_by: "",
                                      comments: "",
                                      projectName: `Project Name: ${this.state.projectName}`,
                                    },
                                    {
                                      sno: "",
                                      date: "",
                                      review_by: "",
                                      comments: "",
                                      projectName: `Client Name: ${this.state.clientName}`,
                                    },
                                    {
                                      sno: "",
                                      date: "",
                                      review_by: "",
                                      comments: "",
                                      projectName: `Owner: ${this.state.productowner}`,
                                    },
                                    {
                                      sno: "",
                                      date: "",
                                      review_by: "",
                                      comments: "",
                                      projectName: `Members: ${this.state.members}`,
                                    },
                                    {
                                      sno: "",

                                      date: "",
                                      review_by: "",
                                      comments: "",
                                      projectName: `Nextreviewdate :  ${moment(
                                        this.state.nextReviewDate
                                      ).format("DD/MM/YYYY")}`,
                                    },
                                    {
                                      sno: "",

                                      date: "",
                                      review_by: "",
                                      comments: "",
                                      projectName: `ReleaseDate :  ${moment(
                                        this.state.ReleaseDate
                                      ).format("DD/MM/YYYY")}`,
                                    },
                                  ]}
                                  headers={[
                                    { label: "S.No", key: "sno" },
                                    { label: "Date", key: "date" },
                                    { label: "Updated By", key: "review_by" },
                                    { label: "Comments", key: "comments" },
                                    {
                                      label: "Project Name",
                                      key: "projectName",
                                    },
                                  ]}
                                  filename={`${this.state.projectName}_project_data.csv`}
                                >
                                  <button
                                    type="button"
                                    className="btn btn-success"
                                    style={{
                                      marginRight: "20px",
                                      marginTop: "0px",
                                    }}
                                  >
                                    Export to CSV
                                  </button>
                                </CSVLink>
                                <Link
                                  to={`/task-management/${this.props.match.params.id}`}
                                  className="btn btn-primary m-b-20"
                                  style={{
                                    borderRadius: "10px",
                                    backgroundColor: "green",
                                    color: "#fff",
                                    borderColor: "green",
                                    marginBottom: "10px",
                                  }}
                                >
                                  Tasks
                                </Link>

                                <Dropdown
                                  className="d-inline mx-2"
                                  style={{
                                    float: "inline-end",
                                    marginTop: "15px",
                                  }}
                                >
                                  <Dropdown.Toggle id="dropdown-autoclose-true">
                                    Action
                                  </Dropdown.Toggle>

                                  <Dropdown.Menu>
                                    <Dropdown.Item
                                      to={"/Role/add/0"}
                                      data-toggle="modal"
                                      data-target="#Role-modal"
                                    >
                                      Edit
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      onClick={() =>
                                        this.handleDeleteClick(
                                          this.props.match.params.id
                                        )
                                      }
                                    >
                                      Delete
                                    </Dropdown.Item>
                                  </Dropdown.Menu>
                                </Dropdown>
                              </div>
                            ) : (
                              <div className="form group">
                                <Link
                                  to={`/task-management/${this.props.match.params.id}`}
                                  className="btn btn-primary m-b-20"
                                  style={{
                                    borderRadius: "10px",
                                    backgroundColor: "green",
                                    color: "#fff",
                                    borderColor: "green",
                                  }}
                                >
                                  <FontAwesomeIcon icon={faTasks} />
                                  <span>Tasks</span>
                                </Link>
                              </div>
                            )}

                            <div>
                              <div className="card-body">
                                <div className="dt-responsive table-responsive">
                                  {userDesignation === "1" ||
                                  userDesignation === "2" ? (
                                    <Table
                                      columns={columns}
                                      data={data}
                                      pageSize={undefined}
                                      sizePerPageList={sizePerPageList}
                                      isSortable={true}
                                      pagination={true}
                                      isSearchable={true}
                                    />
                                  ) : (
                                    <Redirect
                                      to={`/task-management/${this.props.match.params.id}`}
                                    />
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div className="modal" id="Role-modal">
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-body" style={{ height: "500px" }}>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
                <h4>Edit </h4>
                <div
                  className="model-xl"
                  style={{
                    height: "370px",
                    position: "relative",
                    overflow: "auto",
                  }}
                >
                  <div className="card-body">
                    <div className="form-group">
                      <label htmlFor="projectName">Project Name</label>
                      <input
                        type="text"
                        id="projectName"
                        name="projectName"
                        className="form-control"
                        value={this.state.projectName}
                        onChange={(e) =>
                          this.setState({ projectName: e.target.value })
                        }
                        required
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="clientName">Client Name</label>
                      <input
                        type="text"
                        id="clientName"
                        name="clientName"
                        className="form-control"
                        value={this.state.clientName}
                        onChange={(e) =>
                          this.setState({ clientName: e.target.value })
                        }
                        required
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="status">Select Product Owner:</label>
                      <select
                        id="status"
                        name="status"
                        style={{
                          height: "44px",
                          border: "1px solid #ccc",
                          width: "727px",
                          borderRadius: "5px",
                        }}
                        onChange={(e) => this.handleUserChange(e)}
                        value={this.state.productowner}
                      >
                        <option value="">Select Owner</option>
                        {this.state.allUser.map((user) => (
                          <option key={user.id} value={user.name}>
                            {user.name}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="form-group">
                      <label htmlFor="selectedMembers">Select Members:</label>
                      <Select
                        isMulti
                        id="selectedMembers"
                        name="selectedMembers"
                        options={membersWithoutOwner.map((member) => ({
                          value: member.name,
                          label: member.name,
                        }))}
                        onChange={this.handleMembersChange}
                        value={this.state.selectedmembers.map((member) => ({
                          value: member,
                          label: member,
                        }))}
                      />
                    </div>
                  </div>
                </div>
                <div className="modal-footer">
                  {" "}
                  {/* Add a modal footer */}
                  <button
                    type="button"
                    className="btn btn-primary"
                    data-dismiss="modal"
                    // onClick={this.disableModalHandler}
                    style={{
                      float: "right",
                      marginBottom: "20px",
                      backgroundColor: "green",
                      borderColor: "green",
                    }}
                  >
                    {" "}
                    close
                  </button>
                  <button
                    type="button"
                    className="btn btn-primary"
                    data-dismiss="modal"
                    onClick={this.handlememberSubmit}
                    // onClick={this.disableModalHandler}
                    style={{
                      float: "right",
                      marginBottom: "20px",
                      borderColor: "cadetblue",
                    }}
                  >
                    {" "}
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default projectpage;
