import * as React from "react";
import { Link } from "react-router-dom";
import Table from "../user/Table";
import { Dropdown } from "react-bootstrap";
import { callApi } from "../../utils/api";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Select from "react-select";
import moment from "moment";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReactTable from "react-editable-table";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

interface AllProps {
  history: any;
  match: any;
}

interface Member {
  id: number;
  name: string;
}
interface taskliveState {
  allTasks: {
    id: number;
    task: string;
    mintime: string;
    maxtime: string;
    actualtime: string;
    status: string;
    committeddate: string;
    assigned: string;
    support: string;
    remarks: string;
  }[];
  allroles: never[];
  allUser: { id: number; name: string }[];
  taskname: string;
  clientName: string;
  Role: string;
  id: number;
  status: number;
  nextReviewDate: null;
  ReleaseDate: null;
  owner: string;
  members: string;
  user_id: null;
  selecteduser: string;
  username: string;
  selectedMembers: string[];
  mintime: string | null;
  maxtime: string | null;
  actualtime: string | null;
  remarks: string;
  allstatus: { id: number; name: string }[];
  selectedStatus: { id: number; name: string } | null;
  statusCounts: { task_status: string; total_count: string }[];
}

class tasklive extends React.Component<AllProps> {
  state = {
    allTasks: [] as {
      id: number;
      task: string;
      mintime: string;
      maxtime: string;
      actualtime: string;
      status: string;
      committeddate: string;
      assigned: string;
      support: string;
      remarks: string;
    }[],
    statusCounts: [] as {
      task_status: string;
      total_count: string;
      total_actualtime: string;
    }[],
    allroles: [],
    allUser: [] as { id: number; name: string }[],
    allstatus: [] as { id: number; name: string }[],
    taskname: "",
    clientName: "",
    Role: "",
    id: 0,
    status: 0,
    nextReviewDate: null,
    ReleaseDate: null,
    owner: "",
    members: "",
    user_id: null,
    selecteduser: "",
    username: "",
    selectedMembers: [],
    mintime: null,
    maxtime: null,
    actualtime: null,
    remarks: "",
    assigned: "",
    committeddate: null,
    support: "",
    selectedsupport: "",
    selectedStatus: null as { id: number; name: string } | null,
  };
  componentDidMount() {
    this.getTasks();
    this.getstatus();
    this.getUser();
    const userDetails = sessionStorage.getItem("userdetails");
    let user_id = userDetails ? JSON.parse(userDetails).id : null;
    this.setState({ user_id: user_id });
  }
  getstatus() {
    callApi("GET", "task_status_get.php")
      .then((res) => res.data)
      .then((response) => {
        const statusArray = response.data.map((user) => ({
          id: user.id,
          name: user.task_status,
        }));
        this.setState({ allstatus: statusArray });
        console.log("status", this.state.allstatus);
      })
      .catch((err) => {});
  }
  getUser() {
    // Make an API request to fetch all user names
    callApi("GET", "user_get.php")
      .then((res) => res.data)
      .then((response) => {
        const userArray = response.data.map((user) => ({
          id: user.id,
          name: user.name,
        }));

        this.setState({ allUser: userArray });
      })
      .catch((err) => {
        console.log(err);
      });
  }
  getTasks() {
    let req = {
      id: this.props.match.params.id,
    };
    callApi("POST", "project_task_get.php", req)
      .then((res) => res.data)
      .then((response) => {
        const tasklived = response.data.filter(
          (project) => project.task_status === "Live"
        );
        this.setState({ allTasks: tasklived });
      })
      .catch((err) => {
        console.log(err);
      });
  }
  handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      Role: event.target.value,
    });
  };

  handleUserChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    this.setState({
      selecteduser: event.target.value,
    });
  };

  validateForm = () => {
    const { taskname } = this.state;

    if (!taskname) {
      return false;
    }

    return true;
  };

  handleMinTimeInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    console.log("Min Time Input Value: ", event.target.value);

    this.setState({
      mintime: event.target.value,
    });
  };
  handleMinTimeUpdate = (e: React.ChangeEvent<HTMLInputElement>, row: any) => {
    const { allTasks } = this.state;
    const updatedTasks = [...allTasks];
    const rowIndex = updatedTasks.findIndex(
      (task) => task.id === row.original.id
    );

    const fullTime = e.target.value;
    console.log("Min Time in Table: ", fullTime);

    // Update the mintime value in the corresponding row
    updatedTasks[rowIndex].mintime = fullTime;

    this.setState({
      allTasks: updatedTasks,
    });
  };

  handleMaxTimeInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    console.log("Max Time Input Value: ", event.target.value);

    this.setState({
      maxtime: event.target.value,
    });
  };

  handleMaxTimeUpdate = (e: React.ChangeEvent<HTMLInputElement>, row: any) => {
    const { allTasks } = this.state;
    const updatedTasks = [...allTasks];
    const rowIndex = updatedTasks.findIndex(
      (task) => task.id === row.original.id
    );

    console.log("Max Time in Table: ", e.target.value);

    updatedTasks[rowIndex].maxtime = e.target.value;

    this.setState({
      allTasks: updatedTasks,
    });
  };
  handleActualTimeInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    console.log("Actual Time Input Value: ", event.target.value);

    this.setState({
      actualtime: event.target.value,
    });
  };

  handleActualTimeUpdate = (
    e: React.ChangeEvent<HTMLInputElement>,
    row: any
  ) => {
    const { allTasks } = this.state;
    const updatedTasks = [...allTasks];
    const rowIndex = updatedTasks.findIndex(
      (task) => task.id === row.original.id
    );

    console.log("Actual Time in Table: ", e.target.value);

    updatedTasks[rowIndex].actualtime = e.target.value;

    this.setState({
      allTasks: updatedTasks,
    });
  };

  handleCommittedDateInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    console.log("Committed Date Input Value: ", event.target.value);

    this.setState({
      committeddate: event.target.value,
    });
  };

  sendStatusUpdateToApi = (taskId, selectedOption) => {
    const req = {
      taskId: taskId,
      newStatus: selectedOption.name,
    };

    // Call the API to update the task status
    callApi("POST", "project_task_update.php", req)
      .then((res) => res.data)
      .then((response) => {
        if (response.success === true) {
          toast.success(response.message, { autoClose: 3000 });
          this.getTasks();
        } else {
          toast.error(response.message, { autoClose: 3000 });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  sendMinTimeUpdateToApi = (taskId, newMinTime) => {
    const req = {
      taskId: taskId,
      newMinTime: newMinTime,
    };
    callApi("POST", "project_task_update.php", req)
      .then((res) => res.data)
      .then((response) => {
        if (response.success === true) {
          toast.success(response.message, { autoClose: 3000 });
          this.getTasks();
        } else {
          toast.error(response.message, { autoClose: 3000 });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  sendMaxTimeUpdateToApi = (taskId, newMaxTime) => {
    const req = {
      taskId: taskId,
      newMaxTime: newMaxTime,
    };

    callApi("POST", "project_task_update.php", req)
      .then((res) => res.data)
      .then((response) => {
        if (response.success === true) {
          toast.success(response.message, { autoClose: 3000 });
          this.getTasks();
        } else {
          toast.error(response.message, { autoClose: 3000 });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  sendActualTimeUpdateToApi = (taskId, newActualTime) => {
    const req = {
      taskId: taskId,
      newActualTime: newActualTime,
    };

    // Call the API to update the task actualtime
    callApi("POST", "project_task_update.php", req) // Update the API endpoint
      .then((res) => res.data)
      .then((response) => {
        if (response.success === true) {
          toast.success(response.message, { autoClose: 3000 });
          this.getTasks();
        } else {
          toast.error(response.message, { autoClose: 3000 });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  sendCommittedDateUpdateToApi = (taskId, newCommittedDate) => {
    const req = {
      taskId: taskId,
      newCommittedDate: newCommittedDate,
    };

    callApi("POST", "project_task_update.php", req)
      .then((res) => res.data)
      .then((response) => {
        if (response.success === true) {
          toast.success(response.message, { autoClose: 3000 });
          this.getTasks();
        } else {
          toast.error(response.message, { autoClose: 3000 });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  sendAssignedToDataToApi = (taskId, selectedOption) => {
    const req = {
      taskId: taskId,
      assignedTo: selectedOption.name,
    };

    callApi("POST", "project_task_update.php", req)
      .then((res) => res.data)
      .then((response) => {
        if (response.success === true) {
          toast.success(response.message, { autoClose: 3000 });
          this.getTasks();
        } else {
          toast.error(response.message, { autoClose: 3000 });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  sendSupportDataToApi = (taskId, selectedOption) => {
    const req = {
      taskId: taskId,
      supportPerson: selectedOption.name,
    };

    callApi("POST", "project_task_update.php", req)
      .then((res) => res.data)
      .then((response) => {
        if (response.success === true) {
          toast.success(response.message, { autoClose: 3000 });
          this.getTasks();
        } else {
          toast.error(response.message, { autoClose: 3000 });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  handleRemarksSubmit = (taskId, value) => {
    const req = {
      taskId: taskId,
      remarks: value,
    };

    callApi("POST", "project_task_update.php", req)
      .then((res) => res.data)
      .then((response) => {
        if (response.success === true) {
          toast.success(response.message, { autoClose: 3000 });
          this.getTasks();
        } else {
          toast.error(response.message, { autoClose: 3000 });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  handletaskSubmit = (taskId, value) => {
    const req = {
      taskId: taskId,
      task: value,
    };

    callApi("POST", "project_task_update.php", req)
      .then((res) => res.data)
      .then((response) => {
        if (response.success === true) {
          toast.success(response.message, { autoClose: 3000 });
          this.getTasks();
        } else {
          toast.error(response.message, { autoClose: 3000 });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  render() {
    const data = this.state.allTasks;
    const userDetails = sessionStorage.getItem("userdetails");
    const userDesignation = userDetails
      ? JSON.parse(userDetails).designation
      : null;
    const customStyles = {
      control: (provided) => ({
        ...provided,
        width: "150px",
        borderRadius: "5px",
      }),
      dropdownIndicator: (provided) => ({
        ...provided,
        padding: "5px",
      }),
      menu: (provided, state) => ({
        ...provided,
        position: "absolute",
        zIndex: 9999, // Set a high zIndex value
        overflow: "visible",
        maxHeight: "200px",
      }),
      menuPosition: "fixed",
      container: (provided) => ({
        ...provided,
        overflow: "visible",
      }),
      menuPortal: (provided) => ({
        ...provided,
        zIndex: 9999, // Set a high zIndex value
      }),
    };

    const columns = [
      {
        Header: "T ID",
        accessor: "id",
        Cell: ({ value }) => (value ? value : "-"),
        sort: true,
      },

      {
        Header: "Task",
        accessor: "task",
        Cell: ({ value, row }) => (
          <div style={{ display: "flex", alignItems: "center" }}>
            <input
              type="text"
              placeholder={value}
              onChange={(e) => (value = e.target.value)}
              style={{
                height: "40px",
                border: "1px solid #ccc",
                borderRadius: "5px",
                marginRight: "5px",
              }}
            />
            <button
              type="button"
              onClick={() => this.handletaskSubmit(row.original.id, value)}
              style={{
                height: "30px",
                padding: "0 10px",
                borderRadius: "5px",
                backgroundColor: "green",
                color: "#fff",
                border: "none",
                cursor: "pointer",
              }}
            >
              <FontAwesomeIcon icon={faPlus} />
            </button>
          </div>
        ),
        sort: true,
      },
      {
        Header: "Min Time",
        accessor: "mintime",
        Cell: ({ value, row, column }) => (
          <input
            type="time"
            value={value ? moment(value, "HH:mm").format("HH:mm") : ""}
            onChange={(e) => {
              this.handleMinTimeUpdate(e, row); // Update state
              this.sendMinTimeUpdateToApi(row.original.id, e.target.value); // Send to API
            }}
            style={{
              height: "38px",
              border: "1px solid #ccc",
              borderRadius: "5px",
            }}
          />
        ),
        sort: true,
      },
      {
        Header: "Max Time",
        accessor: "maxtime",
        Cell: ({ value, row, column }) => (
          <input
            type="time"
            value={value ? moment(value, "HH:mm").format("HH:mm") : ""}
            onChange={(e) => {
              this.handleMaxTimeUpdate(e, row); // Update state
              this.sendMaxTimeUpdateToApi(row.original.id, e.target.value); // Send to API
            }}
            style={{
              height: "38px",
              border: "1px solid #ccc",
              borderRadius: "5px",
            }}
          />
        ),
        sort: true,
      },
      {
        Header: "Actual Time",
        accessor: "actualtime",
        Cell: ({ value, row }) => (
          <input
            type="time"
            value={value ? moment(value, "HH:mm").format("HH:mm") : ""}
            onChange={(e) => {
              this.handleActualTimeUpdate(e, row); // Update state
              this.sendActualTimeUpdateToApi(row.original.id, e.target.value); // Send to API
            }}
            style={{
              height: "38px",
              border: "1px solid #ccc",
              borderRadius: "5px",
            }}
          />
        ),
        sort: true,
      },
      {
        Header: "Committed Date",
        accessor: "committed_date",
        Cell: ({ value, row, column }) => (
          <input
            type="date"
            value={value ? moment(value).format("YYYY-MM-DD") : ""}
            onChange={(e) => {
              this.sendCommittedDateUpdateToApi(
                row.original.id,
                e.target.value
              ); // Send to API
            }}
            style={{
              height: "38px",
              border: "1px solid #ccc",
              borderRadius: "5px",
            }}
          />
        ),
        sort: true,
      },
      {
        Header: "Status",
        accessor: "task_status",
        Cell: ({ value, row }) => (
          <Select
            id={`status-${row.id}`}
            name={`status-${row.id}`}
            options={this.state.allstatus}
            value={
              this.state.allstatus.find((status) => status.name === value) ||
              this.state.selectedStatus
            }
            styles={{
              control: (provided) => ({
                ...provided,
                width: "150px", // Set the width for the control (before clicking)
                borderRadius: "5px",
              }),
              menu: (provided, state) => ({
                ...provided,
                position: "absolute",
                zIndex: 9999,
                width: "200px", // Set the desired width here
              }),
            }}
            menuPortalTarget={document.body}
            onChange={(selectedOption) => {
              const updatedTasks = [...this.state.allTasks];
              const rowIndex = updatedTasks.findIndex(
                (task) => task.id === row.original.id
              );
              updatedTasks[rowIndex].status = selectedOption.name;

              this.setState(
                {
                  allTasks: updatedTasks,
                },
                () => {
                  this.sendStatusUpdateToApi(row.original.id, selectedOption);
                }
              );
            }}
            getOptionLabel={(option) => option.name}
            getOptionValue={(option) => option.id.toString()}
          />
        ),
        sort: true,
      },
      {
        Header: "Assigned To",
        accessor: "assignedto",
        Cell: ({ value, row }) => (
          <Select
            id={`assigned-to-${row.id}`}
            name={`assigned-to-${row.id}`}
            options={this.state.allUser}
            value={
              this.state.allUser.find((user) => user.name === value) || null
            }
            menuPortalTarget={document.body}
            styles={{
              control: (provided) => ({
                ...provided,
                width: "150px", // Set the width for the control (before clicking)
                borderRadius: "5px",
              }),
              menu: (provided, state) => ({
                ...provided,
                position: "absolute",
                zIndex: 9999,
                width: "200px", // Set the desired width here
              }),
            }}
            onChange={(selectedOption) => {
              const updatedTasks = [...this.state.allTasks];
              const rowIndex = updatedTasks.findIndex(
                (task) => task.id === row.original.id
              );

              updatedTasks[rowIndex].assigned = selectedOption.name;

              this.setState(
                {
                  allTasks: updatedTasks,
                },
                () => {
                  this.sendAssignedToDataToApi(row.original.id, selectedOption);
                }
              );
            }}
            getOptionLabel={(option) => option.name}
            getOptionValue={(option) => option.id.toString()}
          />
        ),
        sort: true,
      },
      {
        Header: "Remarks",
        accessor: "remarks",
        Cell: ({ value, row }) => (
          <div style={{ display: "flex", alignItems: "center" }}>
            <input
              type="text"
              placeholder={value}
              onChange={(e) => (value = e.target.value)}
              style={{
                height: "30px",
                border: "1px solid #ccc",
                borderRadius: "5px",
                marginRight: "5px",
              }}
            />
            <button
              type="button"
              onClick={() => this.handleRemarksSubmit(row.original.id, value)}
              style={{
                height: "30px",
                padding: "0 10px",
                borderRadius: "5px",
                backgroundColor: "green",
                color: "#fff",
                border: "none",
                cursor: "pointer",
              }}
            >
              <FontAwesomeIcon icon={faPlus} />
            </button>
          </div>
        ),
        sort: true,
      },
      {
        Header: "Support",
        accessor: "support",
        Cell: ({ value, row }) => (
          <Select
            id={`support-${row.id}`}
            name={`support-${row.id}`}
            options={this.state.allUser}
            value={
              this.state.allUser.find((user) => user.name === value) || null
            }
            menuPortalTarget={document.body}
            styles={{
              control: (provided) => ({
                ...provided,
                width: "150px",
                borderRadius: "5px",
              }),
              menu: (provided, state) => ({
                ...provided,
                position: "absolute",
                zIndex: 9999,
                width: "200px", // Set the desired width here
              }),
            }}
            onChange={(selectedOption) => {
              const updatedTasks = [...this.state.allTasks];
              const rowIndex = updatedTasks.findIndex(
                (task) => task.id === row.original.id
              );

              updatedTasks[rowIndex].support = selectedOption.name;

              this.setState(
                {
                  allTasks: updatedTasks,
                },
                () => {
                  this.sendSupportDataToApi(row.original.id, selectedOption);
                }
              );
            }}
            getOptionLabel={(option) => option.name}
            getOptionValue={(option) => option.id.toString()}
          />
        ),
        sort: true,
      },
    ];

    const sizePerPageList = [
      {
        text: "5",
        value: 5,
      },
      {
        text: "10",
        value: 10,
      },
      {
        text: "25",
        value: 25,
      },
      {
        text: "All",
        value: data.length,
      },
    ];

    return (
      <>
        <section className="pcoded-main-container">
          <div className="pcoded-wrapper">
            <div className="pcoded-content">
              <div className="pcoded-inner-content">
                <div className="main-body">
                  <div className="page-wrapper">
                    <div className="row">
                      <div className="col-sm-12">
                        <div className="card">
                          <div
                            className="card-header"
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                              padding: "17px",
                            }}
                          >
                            <h5>Live</h5>
                            <div>
                              <Link
                                to="/manage-project"
                                className="btn btn-secondary"
                              >
                                Go Back
                              </Link>
                            </div>
                          </div>
                          <div className="card-body">
                            <div className="dt-responsive table-responsive">
                              <Table
                                columns={columns}
                                data={data}
                                pageSize={50}
                                sizePerPageList={sizePerPageList}
                                isSortable={true}
                                pagination={true}
                                isSearchable={true}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}

export default tasklive;
