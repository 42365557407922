import React from "react";
import { Link } from "react-router-dom";

class Leavemanage extends React.Component {
  render() {
    return (
      <div className="pcoded-main-container">
        <div className="pcoded-wrapper">
          <div className="pcoded-content">
            <div className="pcoded-inner-content">
              <div className="main-body">
                <div className="page-wrapper">
                  <div className="row pr-3 pl-3">
                    <div className="col-sm-12">
                      <div className="card card-wrapper">
                        <div
                          className="card-header"
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            padding: "17px",
                          }}
                        >
                          <h4>Leave Management</h4>
                          <div>
                            <Link
                              to="/apply-leave"
                              className="btn btn-secondary"
                            >
                              Apply Leave
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Leavemanage;
