import * as React from "react";
import { Link } from "react-router-dom";
import Table from "../user/Table";
import { Dropdown } from "react-bootstrap";
import { callApi } from "../../utils/api";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

interface AllProps {
  history: any;
}

class ManageRole extends React.Component<AllProps> {
    state = {
      allRole: [],
      role: "",
      id: 0,
      status: 0,
    };
  componentDidMount() {
    this.getRole();
  }
  handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      role: event.target.value,
    });
  };
  handleEditClick = (roleId, roleName) => {
    this.setState({
      id: roleId,
      role: roleName,
    });
  };
  handleDeleteClick = (roleId, roleStatus) => {
    this.state.id = roleId;
    this.state.status = roleStatus;
    console.log("Delete clicked for role with ID:", roleId);
    console.log("Role status:", roleStatus);
  };
  handleSubmit = () => {
    let req = {
      role: this.state.role
    }
    callApi("POST", "role_insert.php", req)
    .then(res => res.data)
    .then(response => {
      if (response.success == true) {
        this.getRole();
        toast.success(response.message, { autoClose: 3000 });
        
      } else {
        toast.error(response.message, { autoClose: 3000 });
      }
    })
    .catch(err => {
      console.log(err)
   });
  };
  updateRole = () => {
    let req = {
      id: this.state.id,
      role: this.state.role
    }
    callApi("POST", "role_update.php", req)
    .then(res => res.data)
    .then(response => {
    if (response.success == true) {
      this.getRole();
      toast.success(response.message, { autoClose: 3000 });

    } else {
      toast.error(response.message, { autoClose: 3000 });
    }
   
    })
    .catch(err => {
      console.log(err)

    });
  }
  deleteRole = () => {
    let req = {
      id: this.state.id,
      status: 0
    }
    callApi("POST", "role_delete.php", req)
    .then(res => res.data)
    .then(response => {
      if (response.success == true) {
        toast.success(response.message,{ autoClose: 3000 });
      } else {
        toast.error(response.message, { autoClose: 3000 });
      }

      this.getRole();
    })
    .catch(err => {
      console.log(err)
    });
  }
  getRole() {
    callApi("GET", "role_get.php",)
      .then(res => res.data)
      .then(response => {
        this.setState({ allRole: response.data })
        console.log("allRole", this.state.allRole)
        console.log("id", this.state.id)
      })
      .catch(err => {
      });
  }
  render() {
    const data = this.state.allRole;
    const columns = [
      {
        Header: "S.No",
        accessor: "s.no",
        sort: true,
         Cell: ({ row }) => {
          return <span>{row.index + 1}</span>;
        },
      },
      {
        Header: "Role",
        accessor: "role",
        sort: true,
      },
      
      {
        Header: "Action",
        accessor: "action",
        sort: false,
        Cell: ({ row }) => (
          <Dropdown>
            <Dropdown.Toggle variant="primary" id="action-dropdown" style={{backgroundColor: "green",borderColor: "green"}}>
              Actions
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item
                as={Link}
                onClick={() => this.handleEditClick(row.original.id, row.original.role)}
                to={`/user/edit/${row.original.id}`}
                className="dropdown-item"
                data-toggle="modal"
                data-target="#edit-user"
              >
                Edit
              </Dropdown.Item>
              <Dropdown.Item
                className="dropdown-item text-danger"
                data-toggle="modal"
                data-target="#delete-user"
                onClick={() => this.handleDeleteClick(row.original.id, row.original.status)}
           
              >
                Delete
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        ),

        
      
      },
    ];
    
    const sizePerPageList = [
      {
        text: "5",
        value: 5,
      },
      {
        text: "10",
        value: 10,
      },
      {
        text: "25",
        value: 25,
      },
      {
        text: "All",
        value: data.length,
      },
    ];
    
    return (
      <><section className="pcoded-main-container">
        <div className="pcoded-wrapper">
          <div className="pcoded-content">
            <div className="pcoded-inner-content">
              <div className="main-body">
                <div className="page-wrapper">
                  <div className="row">
                    <div className="col-sm-12">
                      <div className="card">
                      <div className="card-header" style={{ display: "flex", justifyContent: "space-between", alignItems: "center", padding: "17px" }}>
                          <h5>Manage Role</h5>
                          <div>
                            <Link to="/dashboard" className="btn btn-secondary">
                              Go Back
                            </Link>
                          </div>
                        </div>
                        <div className="card-body">
                          <div className="dt-responsive table-responsive">
                            <Link to={"/Role/add/0"} className="btn btn-primary m-b-20" data-toggle="modal"
                              data-target="#Role-modal" style={{ borderRadius: "10px", backgroundColor: "green", color: "#fff", borderColor: "green" }}>
                              Add Role
                            </Link>
                            <Table
                              columns={columns}
                              data={data}
                              pageSize={5}
                              sizePerPageList={sizePerPageList}
                              isSortable={true}
                              pagination={true}
                              isSearchable={true} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
        //Add Role
        <div className="modal" id="Role-modal">
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-body">
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
                <h4>Manage Role</h4>
                <div className="model-xl" style={{ height: '200px', position: 'relative', overflow: 'auto' }}>
                  <div className='card-body'>
                    <div className="form-group">
                      <label htmlFor="role"></label>
                      <input
                        type="text"
                        id="role"
                        name="role"
                        className="form-control"
                        style={{ marginTop: "47px" }}
                        value={this.state.role}
                        onChange={this.handleInputChange} />
                    </div>
                  </div>
                </div>
                <div className="modal-footer"> {/* Add a modal footer */}
                  <button
                    type="button"
                    className="btn btn-primary"
                    data-dismiss="modal"
                    // onClick={this.disableModalHandler}
                    style={{ float: "right", marginBottom: "20px", backgroundColor: "green", borderColor: "green" }}
                  > close</button>
                  <button
                    type="button"
                    className="btn btn-primary"
                    data-dismiss="modal"
                    onClick={this.handleSubmit}
                    // onClick={this.disableModalHandler}
                    style={{ float: "right", marginBottom: "20px", borderColor: "cadetblue" }}
                  > Submit</button>
                </div>
              </div>

            </div>

          </div>
        </div><div className="modal" id="edit-user"
          role="dialog"
          aria-hidden="true">
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-body">
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
                <h4>Manage Role</h4>
                <div className="model-xl" style={{ height: '200px', position: 'relative', overflow: 'auto' }}>
                  <div className='card-body'>
                    <div className="form-group">
                      <label htmlFor="role"></label>
                      <input
                        type="text"
                        id="role"
                        name="role"
                        className="form-control"
                        style={{ marginTop: "47px" }}
                        value={this.state.role}
                        onChange={this.handleInputChange} />
                    </div>
                  </div>
                </div>
                <div className="modal-footer"> {/* Add a modal footer */}
                  <button
                    type="button"
                    className="btn btn-primary"
                    data-dismiss="modal"
                    // onClick={this.disableModalHandler}
                    style={{ float: "right", marginBottom: "20px", backgroundColor: "green", borderColor: "green" }}
                  > close</button>
                  <button
                    type="button"
                    className="btn btn-primary"
                    data-dismiss="modal"
                    onClick={this.updateRole}
                    // onClick={this.disableModalHandler}
                    style={{ float: "right", marginBottom: "20px", borderColor: "cadetblue" }}
                  > Submit</button>
                </div>
              </div>

            </div>

          </div>
        </div><div
          className="modal fade"
          id="delete-user"
          role="dialog"
          aria-hidden="true"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Delete User</h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <h6>Are you sure you want to Delete this user?</h6>
                <div className="form-group">
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                  style={{ backgroundColor: "green", borderColor: "green" }}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="btn btn-primary"
                  data-dismiss="modal"
                  onClick={this.deleteRole}
                  style={{ backgroundColor: "cadetblue", borderColor: "cadetblue" }}
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
        </div></>
       
       
    );
  }
}

export default ManageRole;
