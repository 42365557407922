import * as React from "react";
// import "./Dashboard.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUpload } from "@fortawesome/free-solid-svg-icons";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
declare var $: any;

interface AllProps {
  match: any;
  history: any;
  router: any;
  route: any;
}
class ReportingOfficer extends React.Component<AllProps> {
  state = {
    startDate: null,
  };
  componentDidMount() {
  
  }
  handleReportButtonClick = () => {
    this.props.history.push("/Report-officer"); // Replace "/next-page" with the path of the next page you want to navigate to
  };
  handleUploadClick = () => {
    // Trigger the file input when the icon is clicked
    const fileInput = document.getElementById("fileInput");
    if (fileInput) {
      fileInput.click();
    }
  };

  handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      // Now, you can do whatever you want with the selected file. For example, you can upload it to a server or process it in some way.
      console.log("Selected file:", file);
    }
  };
  render() {
    
    return (
      <div className="pcoded-main-container">
        <div className="pcoded-wrapper">
          <div className="pcoded-content">
            <div className="pcoded-inner-content">
              <div className="main-body">
                <div className="page-wrapper">
                  <div className="row pr-3 pl-3">
                    <div className="col-sm-12">
                      <div className="card">
                        <div className="card-header" style={{padding: "17px"}}>
                          <h4>Hii Reporting officer Name</h4>
                          <div style={{ display: "flex", justifyContent: "center",marginRight: "964px", marginTop:"20px"}}>
                            <DatePicker
                              selected={this.state.startDate}
                              onChange={(date) => this.setState({ startDate: date })}
                              placeholderText="Select a date"
                              dateFormat="dd/MM/yyyy"
                            />
                          </div>
                          <button
                        type="button"
                        className="btn btn-primary"
                        onClick={this.handleReportButtonClick} 
                        style={{float:"right",backgroundColor: "green",borderColor:"green"}}
                        // Add your onClick handler for the report button here
                        // For example, onClick={this.handleReportButtonClick}
                      >
                        Report
          
                      </button>
                        <h2 className="file-status-header" style={{ display: "flex", justifyContent: "center",textAlign:"center" }}>Task</h2>
                        <div className="row">

                        <div className="form-group col-md-6">
                            <label htmlFor="label1"></label>
                            <input type="text" id="label1" className="form-control" style={{marginLeft: "231px", width: "321px"}} placeholder="Enter task"/>
                          </div>
                          <div className="form-group ">
                            <label htmlFor="label2"></label>
                            <select id="label2" className="form-control" style={{width:"211px"}}>
                             <option value="option1">completed/pending</option>
                             <option value="option2">completed</option>
                             <option value="option3">pending</option>
                           </select>
                            {/* <input type="text" id="label2" className="form-control" /> */}
                          </div>
                          {/* <div className="form-group">
                            <label htmlFor="label3"></label>
                            <input type="text" id="label3" className="form-control" style={{marginLeft: "12px"}}  placeholder="Git ID"/>
                          </div> */}
                          <div className="form-group" style={{marginLeft:"33px", marginTop: "20px"}}>
                          <FontAwesomeIcon icon={faUpload}  onClick={this.handleUploadClick} />
                          <input type="file" id="fileInput" style={{ display: "none" }} onChange={this.handleFileChange} />
                          </div>
                          <div className="form-group col-md-6">
                            <label htmlFor="label4"></label>
                            <input type="text" id="label4" className="form-control" style={{marginLeft: "231px", width: "321px"}} placeholder="Enter task"/>
                          </div>
                          <div className="form-group">
                            <label htmlFor="label5"></label>
                            <select id="label2" className="form-control" style={{width:"211px"}}>
                             <option value="option1">completed/pending</option>
                             <option value="option2">completed</option>
                             <option value="option3">pending</option>
                           </select>
                            {/* <input type="text" id="label5" className="form-control" /> */}
                            
                          </div>
                          {/* <div className="form-group">
                            <label htmlFor="label6"></label>
                            
                            <input type="text" id="label6" className="form-control" style={{marginLeft: "12px"}}  placeholder="Git ID"/>
                          </div> */}
                          <div className="form-group" style={{marginLeft:"33px", marginTop: "20px"}}>
                          <FontAwesomeIcon icon={faUpload} onClick={this.handleUploadClick} />
                          <input type="file" id="fileInput" style={{ display: "none" }} onChange={this.handleFileChange} />
                          </div>
                          <div className="form-group col-md-6">
                            <label htmlFor="label7"></label>
                            
                            <input type="text" id="label7" className="form-control" style={{marginLeft: "231px", width: "321px"}} placeholder="Enter task"/>
                          </div>
                          <div className="form-group">
                            <label htmlFor="label8"></label>
                            <select id="label2" className="form-control" style={{width:"211px"}}>
                             <option value="">completed/pending</option>
                             <option value="option2">completed</option>
                             <option value="option3">pending</option>
                           </select>
                            {/* <input type="text" id="label8" className="form-control" /> */}
                          </div>
                          {/* <div className="form-group">s
                            <label htmlFor="label9"></label>
                            <input type="text" id="label9" className="form-control" style={{marginLeft: "12px"}} placeholder="Git ID"/>
                            
                          </div> */}
                          <div className="form-group" style={{marginLeft:"33px", marginTop: "20px"}}>
                          <FontAwesomeIcon icon={faUpload} onClick={this.handleUploadClick} />
                          <input type="file" id="fileInput" style={{ display: "none" }} onChange={this.handleFileChange} />
                          </div>  
                          <div className="form-group col-md-6">
                            <label htmlFor="label1"></label>
                            <input type="text" id="label1" className="form-control" style={{marginLeft: "231px",marginTop:"60px"}} placeholder="Comments"/>
                          </div> 
                          </div>
                          <button
                             type="button"
                             className="btn btn-primary"
                              data-dismiss="modal"
                              // onClick={this.handleCloseButtonClick}
                            // onClick={this.disableModalHandler}
                            style={{ float: "right", marginBottom: "20px",backgroundColor: "cadetblue",borderColor: "cadetblue",marginTop:"-53px"}}
                            > Submit</button>
                           <button
                               type="button"
                               className="btn btn-primary"
                               data-dismiss="modal"
                              //  onClick={this.handleSubmitButtonClick}
                             //  onClick={this.handleSubmit}
                               // onClick={this.disableModalHandler}
                             style={{ float: "right", marginBottom: "20px",backgroundColor: "green",borderColor: "green", marginTop:"-53px" }}

                             > Save</button>
                          </div>
                          
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
      
         
    );
  }
}

export default ReportingOfficer;
