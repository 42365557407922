import React from "react";

class Hrscreen extends React.Component {
  render() {
    return (
      <div className="pcoded-main-container">
        <div className="pcoded-wrapper">
          <div className="pcoded-content">
            <div className="pcoded-inner-content">
              <div className="main-body">
                <div className="page-wrapper">
                  <div className="row pr-3 pl-3">
                    <div className="col-sm-12">
                      <div className="card card-wrapper">
                        <div className="card-header" style={{ padding: "45px 40px 30px 40px" }}>
                          <h4>Hi Hr..Welcome..!</h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>                        
    );
  }
}

export default Hrscreen;
